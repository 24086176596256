import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useLocation } from "@reach/router"
import { useCountries } from "context/countryContext"
import { navigate } from "gatsby"
import produce from "immer"
import React, { useEffect, useReducer } from "react"
import routes from "services/routes"
import resolveConfig from "tailwindcss/resolveConfig"
import tailwindConfig from "../../../tailwind.config"
import AudiobookIcon from "../icons/contentTypes/audiobookIcon"
import PodcastIcon from "../icons/contentTypes/podcastIcon"
import StationIcon from "../icons/contentTypes/stationIcon"
import TabBar from "./tabBar"
import TabBarButton from "./tabBarButton"

// Load Tailwind config
const { theme } = resolveConfig(tailwindConfig)

const LibraryTabBar = () => {
  // Store country
  const { storeURL } = useCountries()

  // Grab the location from Reach Router for Metadata
  const location = useLocation()

  // Colours from theme
  const greyDark = theme.colors.grey.dark
  const purple = theme.colors.purple["DEFAULT"]
  const blue = theme.colors.blue["DEFAULT"]
  const orange = theme.colors.orange["DEFAULT"]
  const red = theme.colors.red["DEFAULT"]

  // Initial state for active route
  const initialState = {
    isAllContentActive: false,
    isAudiobooksActive: false,
    isPodcastsActive: false,
    isStationsActive: false,
  }

  // Reducer for active rouote
  const ACTIVATE = "ACTIVATE"
  function reducer(state, action) {
    switch (action.type) {
      case ACTIVATE:
        return produce(state, (draftState) => {
          const activate = action.payload
          draftState.isAllContentActive = activate.isAllContentActive
            ? activate.isAllContentActive
            : false
          draftState.isAudiobooksActive = activate.isAudiobooksActive
            ? activate.isAudiobooksActive
            : false
          draftState.isPodcastsActive = activate.isPodcastsActive
            ? activate.isPodcastsActive
            : false
          draftState.isStationsActive = activate.isStationsActive
            ? activate.isStationsActive
            : false
        })
      default:
        throw new Error(`Unhandled action type: ${action.type}`)
    }
  }

  // Reducer state for active route
  const [state, dispatch] = useReducer(reducer, initialState)

  //
  // Match current route
  //
  useEffect(() => {
    switch (location.pathname) {
      case routes.LIBRARY:
        dispatch({
          type: ACTIVATE,
          payload: { isAllContentActive: true },
        })
        break
      case routes.LIBRARY_AUDIOBOOKS:
        dispatch({
          type: ACTIVATE,
          payload: { isAudiobooksActive: true },
        })
        break
      case routes.LIBRARY_PODCASTS:
        dispatch({
          type: ACTIVATE,
          payload: { isPodcastsActive: true },
        })
        break
      case routes.LIBRARY_STATIONS:
        dispatch({
          type: ACTIVATE,
          payload: { isStationsActive: true },
        })
        break

      default:
        break
    }
  }, [location.pathname, storeURL])

  return (
    <TabBar>
      <TabBarButton
        text="All Items"
        color={purple}
        className="w-20"
        icon={
          <FontAwesomeIcon
            icon={["fa", "grip-vertical"]}
            style={{
              width: "1.1rem",
              height: "1.1rem",
            }}
          />
        }
        isActive={state.isAllContentActive}
        onClick={() => {
          dispatch({
            type: ACTIVATE,
            payload: { isAllContentActive: true },
          })
          navigate(routes.LIBRARY)
        }}
      />

      <TabBarButton
        text="Audiobooks"
        color={blue}
        className="w-24"
        icon={
          <AudiobookIcon
            alignment="center"
            foregroundColor={state.isAudiobooksActive ? blue : greyDark}
            style={{
              width: "1rem",
              height: "1rem",
            }}
          />
        }
        isActive={state.isAudiobooksActive}
        onClick={() => {
          dispatch({
            type: ACTIVATE,
            payload: { isAudiobooksActive: true },
          })
          navigate(routes.LIBRARY_AUDIOBOOKS)
        }}
      />

      <TabBarButton
        text="Podcasts"
        color={orange}
        className="w-20"
        icon={
          <PodcastIcon
            alignment="center"
            foregroundColor={state.isPodcastsActive ? orange : greyDark}
            style={{
              width: "1.1rem",
              height: "1.1rem",
            }}
          />
        }
        isActive={state.isPodcastsActive}
        onClick={() => {
          dispatch({
            type: ACTIVATE,
            payload: { isPodcastsActive: true },
          })
          navigate(routes.LIBRARY_PODCASTS)
        }}
      />

      <TabBarButton
        text="Radio"
        color={red}
        className="w-16"
        icon={
          <StationIcon
            alignment="center"
            foregroundColor={state.isStationsActive ? red : greyDark}
            style={{
              width: "1.1rem",
              height: "1.1rem",
            }}
          />
        }
        isActive={state.isStationsActive}
        onClick={() => {
          dispatch({
            type: ACTIVATE,
            payload: { isStationsActive: true },
          })
          navigate(routes.LIBRARY_STATIONS)
        }}
      />
    </TabBar>
  )
}

export default LibraryTabBar
LibraryTabBar.whyDidYouRender = true
