import produce from "immer"
import * as React from "react"
import { createContext, useContext, useReducer } from "react"

//
// A context provider that stores logical screen flows to
// assist with naviagtion.
//
export const ScreenFlowContext = createContext()
export const ScreenFlowDispatchContext = createContext()

const initialState = {
  previousScreen: undefined,
  nextScreen: undefined,
}

export const PREVIOUS_SCREEN = "PREVIOUS_SCREEN"
export const NEXT_SCREEN = "NEXT_SCREEN"
export const PREVIOUS_AND_NEXT_SCREENS = "PREVIOUS_AND_NEXT_SCREENS"
export const CLEAR_SCREENS = "CLEAR_SCREENS"

const reducer = (state, action) => {
  switch (action.type) {
    case PREVIOUS_SCREEN:
      return produce(state, (draftState) => {
        draftState.previousScreen = action.payload
      })
    case NEXT_SCREEN:
      return produce(state, (draftState) => {
        draftState.previousScreen = action.payload
      })
    case PREVIOUS_AND_NEXT_SCREENS:
      return produce(state, (draftState) => {
        const screens = action.payload
        draftState.previousScreen = screens.previousScreen
        draftState.nextScreen = screens.nextScreen
      })
    case CLEAR_SCREENS:
      return produce(state, (draftState) => {
        draftState.previousScreen = undefined
        draftState.nextScreen = undefined
      })
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

export const ScreenFlowProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <ScreenFlowContext.Provider value={state}>
      <ScreenFlowDispatchContext.Provider value={dispatch}>
        {children}
      </ScreenFlowDispatchContext.Provider>
    </ScreenFlowContext.Provider>
  )
}

export const useScreenFlowContext = () => {
  const context = useContext(ScreenFlowContext)
  if (context === undefined) {
    throw new Error(
      "useScreenFlowContext must be used within a ScreenFlowProvider"
    )
  }
  return context
}

export const useScreenFlowDispatcher = () => {
  const context = useContext(ScreenFlowDispatchContext)
  if (context === undefined) {
    throw new Error(
      "useScreenFlowDispatcher must be used within a ScreenFlowProvider"
    )
  }
  return context
}
