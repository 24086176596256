import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const SubnavItem = (props) => {
  const {
    text,
    color = "grey",
    to,
    onClick,
    active = false,
    icon,
    className,
  } = props

  return (
    <Link
      to={to}
      tabIndex="0"
      role="button"
      onClick={onClick}
      onKeyDown={onClick}
      className={`
        mx-3 tablet:mx-5 
        flex 
        flex-col 
        items-center 
        cursor-pointer
        select-none
        focus:outline-none
        no-tap-overlay
        text-sm tablet:text-sm desktop:text-base
        tracking-wide
        ${className}
      `}
    >
      {/* Icon + Label */}
      <div
        style={{
          marginLeft: "0.3vw",
          marginRight: "0.3vw",
        }}
        className="flex items-center"
      >
        {/* Icon */}
        <div className={`flex ${active ? `text-${color}` : ""}`}>
          {icon ? icon : undefined}
        </div>

        {/* Label */}
        <span
          className={`
            ${active ? "font-bold" : ""}
            ${active ? `text-${color}` : ""}
        `}
          style={{
            fontSize: "1.2vw",
          }}
        >
          {text}
        </span>
      </div>

      {active && (
        <div
          style={{
            height: "0.4vw",
          }}
          className={`
            rounded-full
            bg-${color}
            w-full
          `}
        />
      )}
      {!active && (
        <div
          style={{
            height: "0.4vw",
          }}
          className={`
            rounded-full
            shadow-subnav-item
            w-full
          `}
        />
      )}
    </Link>
  )
}

SubnavItem.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  to: PropTypes.string.isRequired,
  active: PropTypes.bool,
  icon: PropTypes.object,
}

export default SubnavItem
