import React from "react"

const BlueFaceAvatarIcon = (props) => {
  const { style, className } = props

  return (
    <div style={style ? style : {}} className={className ? className : ""}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="15 15 140 140"
      >
        <defs>
          <linearGradient
            id="ch55vipc0c"
            x1="100%"
            x2="0%"
            y1="100%"
            y2="-9.901%"
          >
            <stop offset="0%" />
            <stop offset="100%" stopColor="#FFF" />
          </linearGradient>
          <rect id="fmgo290rmb" width="140" height="140" x="0" y="0" rx="70" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <g>
            <g>
              <g transform="translate(-226 -391) translate(241 406)">
                <use
                  fill="#000"
                  filter="url(#j6ncoqe69a)"
                  xlinkHref="#fmgo290rmb"
                />
                <use fill="#4130F9" xlinkHref="#fmgo290rmb" />
                <use
                  fill="url(#ch55vipc0c)"
                  fillOpacity=".4"
                  style={{ mixBlendMode: "soft-light" }}
                  xlinkHref="#fmgo290rmb"
                />
              </g>
              <g fill="#1A1363">
                <path
                  d="M62.271 49.027l-.322-.434c-1.192-1.618-3.401-1.931-5.079-.835-21.679 14.138-42.54 4.248-49.955-.221-1.638-.988-3.736-.582-4.877.964l-.294.397c-1.323 1.787-.83 4.337 1.073 5.457C8.238 57.535 19.537 63 33.232 63c8.68 0 18.325-2.192 28.034-8.549 1.816-1.188 2.297-3.67 1.005-5.424M11 2c6.075 0 11 4.925 11 11s-4.925 11-11 11S0 19.075 0 13 4.925 2 11 2M60.136 18.625l-8.818-5.624 8.822-5.624c1.89-1.203 2.43-3.69 1.2-5.548l-.004-.004C60.107-.032 57.58-.557 55.688.646L38.918 11.34c-1.224.783-1.224 2.54 0 3.322l16.774 10.69c1.891 1.208 4.42.678 5.648-1.183 1.226-1.857.687-4.336-1.204-5.544"
                  transform="translate(-226 -391) translate(241 406) translate(38 39)"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default BlueFaceAvatarIcon
