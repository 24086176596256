import { useProfileContext } from "context/profileContext"
import { useSetupContext } from "context/setupContext"
import { useUserContext } from "context/userContext"
import { navigate } from "gatsby"
import { useEffect } from "react"
import routes from "services/routes"
import { useIsSetupProcess } from "./useIsSetupProcess"

//
// Ensure that the user has chosen an active profile.
//
export const useProfileEnforcer = () => {
  const { hasLoaded: isUserLoaded, userId } = useUserContext()
  const { hasProfile, hasDevice } = useSetupContext()

  // Determine if we are in a setup process
  const isSetupProcess = useIsSetupProcess()

  // The active profile ID
  const { activeProfileId } = useProfileContext()

  useEffect(() => {
    if (!isUserLoaded || !userId) {
      // Can't enforce if there is no user yet!
      return
    }
    // Don't run enforcer during the setup process!
    if (isSetupProcess) {
      return
    }
    // Don't run the enforcer on profile-related screens!
    const currentRoute = window.location.pathname
    if (
      currentRoute === routes.CHOOSE_PROFILE ||
      currentRoute === routes.CHOOSE_AVATAR ||
      currentRoute === routes.EDIT_PROFILES ||
      currentRoute === routes.EDIT_PROFILE ||
      currentRoute === routes.CREATE_PROFILE ||
      currentRoute === routes.SETUP_10_PLAYER_REGISTER ||
      currentRoute === routes.SETUP_11_GET_CONTENT
    ) {
      return
    }
    if (isUserLoaded && hasProfile && hasDevice && !activeProfileId) {
      console.warn("Forcing user to choose profile!")
      // Force user to choose a profile
      navigate(routes.CHOOSE_PROFILE, { replace: true })
    }
  })
}
