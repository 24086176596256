import { getFirebase } from "services/firebase"

//
// Prepare a Cloud Function
//
const prepareCloudFunction = async (functionName) => {
  const location = process.env.GATSBY_FIREBASE_FUNCTIONS_LOCATION
  if (location === undefined) {
    throw Error(
      "The functions location key 'GATSBY_FIREBASE_FUNCTIONS_LOCATION' is not defined in configuration!"
    )
  }
  const firebase = await getFirebase()
  const cloudFunction = firebase.default
    .app()
    .functions(location)
    .httpsCallable(functionName)
  return cloudFunction
}

//
// Create a user account
//
export const createUserAccount = async (emailAddress, password) => {
  const cloudFunction = await prepareCloudFunction("createUserAccount")
  return await cloudFunction({
    emailAddress: emailAddress,
    password: password,
  })
}

//
// Update the 'reauthenticateBeforePayment' flag on a user account
//
export const updateReauthenticateBeforePayment = async (
  reauthenticateBeforePayment
) => {
  const cloudFunction = await prepareCloudFunction(
    "reauthenticateBeforePayment"
  )
  return await cloudFunction({
    reauthenticateBeforePayment: reauthenticateBeforePayment,
  })
}

//
// Add a profile to a user account
//
export const addProfile = async (name, avatar, birthMonth, birthYear) => {
  const cloudFunction = await prepareCloudFunction("addProfile")
  const params = {
    profileName: name,
    profileAvatar: avatar,
    ...(birthMonth && { profileBirthMonth: birthMonth }),
    ...(birthYear && { profileBirthYear: birthYear }),
  }
  return await cloudFunction(params)
}

//
// Edit a profile in a user account
//
export const editProfile = async (id, name, avatar, birthMonth, birthYear) => {
  const cloudFunction = await prepareCloudFunction("editProfile")
  const params = {
    profileId: id,
    profileName: name,
    profileAvatar: avatar,
    ...(birthMonth && { profileBirthMonth: birthMonth }),
    ...(birthYear && { profileBirthYear: birthYear }),
  }
  return await cloudFunction(params)
}

//
// Delete a profile in a user account
//
export const deleteProfile = async (id) => {
  const cloudFunction = await prepareCloudFunction("deleteProfile")
  const params = {
    profileId: id,
  }
  return await cloudFunction(params)
}

//
// Add content to a profile
//
export const addContentToProfile = async (profileId, contentRef) => {
  const cloudFunction = await prepareCloudFunction("addContentToProfile")
  return await cloudFunction({
    profileId: profileId,
    contentRef: contentRef,
  })
}

//
// Remove content from a profile
//
export const removeContentFromProfile = async (profileId, contentRef) => {
  const cloudFunction = await prepareCloudFunction("removeContentFromProfile")
  return await cloudFunction({
    profileId: profileId,
    contentRef: contentRef,
  })
}

//
// Check if an email address is on the Beta testers list
//
export const isBetaTester = async (emailAddress) => {
  const cloudFunction = await prepareCloudFunction("isBetaTester")
  return await cloudFunction({
    emailAddress: emailAddress,
  })
}

//
// Add a member to the mailchimp mailing list
//
export const addMemberToMailchimpList = async (emailAddress) => {
  const cloudFunction = await prepareCloudFunction("addMemberToMailchimpList")
  return await cloudFunction({
    emailAddress: emailAddress,
  })
}

//
// Create a Stripe Setup Intent for associating a payment method with a user
//
export const createSetupIntent = async () => {
  const cloudFunction = await prepareCloudFunction("createSetupIntent")
  return await cloudFunction()
}

//
// Update payment details for a user using a Stripe Payment Method ID
//
export const updatePaymentDetails = async (storeCountry, paymentMethodId) => {
  const cloudFunction = await prepareCloudFunction("updatePaymentDetails")
  return await cloudFunction({
    storeCountry: storeCountry,
    paymentMethodId: paymentMethodId,
  })
}

//
// Delete payment details for a user
//
export const deletePaymentDetails = async () => {
  const cloudFunction = await prepareCloudFunction("deletePaymentDetails")
  return await cloudFunction()
}

//
// Add a profile to a user account
//
export const validateDiscountCode = async (
  discountCode,
  storeCountry,
  contentRef
) => {
  const cloudFunction = await prepareCloudFunction("validateDiscountCode")
  const params = {
    discountCode: discountCode,
    storeCountry: storeCountry,
    contentRef: contentRef,
  }
  return await cloudFunction(params)
}

//
// Purchase an audiobook
//
export const purchaseAudiobook = async (
  origin,
  contentRef,
  storeCountry,
  discountCode,
  paymentIntentId
) => {
  const cloudFunction = await prepareCloudFunction("purchaseAudiobook")
  return await cloudFunction({
    origin: origin,
    contentRef: contentRef,
    storeCountry: storeCountry,
    discountCode: discountCode,
    paymentIntentId: paymentIntentId,
  })
}

//
// Cancel a Payment Intent
//
export const cancelPaymentIntent = async (paymentIntentId) => {
  const cloudFunction = await prepareCloudFunction("cancelPaymentIntent")
  return await cloudFunction({
    paymentIntentId: paymentIntentId,
  })
}
