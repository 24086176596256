import GetHelpMenuButton from "components/chat/getHelpMenuButton"
import Button from "components/controls/button"
import { useCountries } from "context/countryContext"
import { useUserContext } from "context/userContext"
import { navigate } from "gatsby"
import { useMediaQueries } from "hooks/useMediaQueries"
import SearchIcon from "images/search-icon.svg"
import PropTypes from "prop-types"
import React, { useCallback, useMemo } from "react"
import { signOut } from "services/auth"
import routes from "services/routes"
import { Titles } from "../navbar"
import MobileNavbarLink from "./mobileNavbarLink"
import MobileSidebar, { SidebarLocation } from "./mobileSidebar"

//
// Navigation Sidebar for mobile devices
//
const MobileNavigationSidebar = ({
  isSidebarDisplayed,
  setSidebarDisplayed,
  animationDuration,
}) => {
  // Auth state from context
  const { hasLoaded, userId } = useUserContext()

  // Media queries for React
  const { isMobile } = useMediaQueries()

  // Store country
  const { storeURL } = useCountries()

  //
  // Calculate size of search icons using breakpoint
  //
  const searchIconSize = useMemo(() => {
    if (isMobile) {
      return "5.5vw"
    } else {
      return "2.5vw"
    }
  }, [isMobile])

  //
  // Handle sign out click
  //
  const handleSignOut = useCallback(async () => {
    try {
      setSidebarDisplayed(false)
      await signOut()
      navigate(routes.HOME)
    } catch (error) {
      console.error(error)
    }
  }, [setSidebarDisplayed])

  //
  // Handle the Help button being clicked
  //
  const handleHelpButtonClicked = useCallback(() => {
    setSidebarDisplayed(false)
  }, [setSidebarDisplayed])

  return (
    <MobileSidebar
      location={SidebarLocation.LEFT}
      isSidebarDisplayed={isSidebarDisplayed}
      setSidebarDisplayed={setSidebarDisplayed}
      animationDuration={animationDuration}
    >
      <div
        style={{
          marginTop: "3.5rem",
          marginLeft: "2rem",
          marginRight: "2rem",
        }}
      >
        <hr className="text-grey opacity-30 my-1" />

        {/* Store link */}
        <MobileNavbarLink
          title={Titles.STORE}
          to={routes.STORE_FEATURED(storeURL)}
          setSidebarDisplayed={setSidebarDisplayed}
        />

        <hr className="text-grey opacity-30 my-1" />

        {/* Library link */}
        <MobileNavbarLink
          title={Titles.LIBRARY}
          to={routes.LIBRARY}
          setSidebarDisplayed={setSidebarDisplayed}
        />

        <hr className="text-grey opacity-30 my-1" />

        {/* Search link */}
        <MobileNavbarLink
          to={routes.STORE_SEARCH(storeURL)}
          setSidebarDisplayed={setSidebarDisplayed}
          icon={
            <SearchIcon
              style={{
                width: searchIconSize,
                height: searchIconSize,
              }}
              className="justify-self-center"
            />
          }
        />

        <hr className="text-grey opacity-30 my-1" />

        {/* Spacer */}
        <div className="my-12" />

        {/* --------------------------------- */}

        {/* Only visible if user is NOT authenticated */}
        {hasLoaded && !userId && (
          <>
            {/* Sign in */}
            <Button
              type="secondary"
              text="Sign in"
              size="md"
              shadowHeight={2}
              onClick={() => {
                navigate(routes.SIGN_IN)
              }}
              className="mt-4"
            />

            {/* Sign up */}
            <Button
              type="primary"
              text="Sign up"
              size="md"
              shadowHeight={2}
              onClick={() => {
                navigate(routes.SIGN_UP)
              }}
              className="mt-4"
            />

            {/* Spacer */}
            <div className="my-8 tablet:my-10" />

            {/* Get help */}
            <GetHelpMenuButton onClick={handleHelpButtonClicked} />
          </>
        )}

        {/* --------------------------------- */}

        {/* Only visible if user is authenticated */}
        {hasLoaded && userId && (
          <>
            {/* View account */}
            <Button
              size="sm"
              shadowHeight={2}
              text="View account"
              onClick={() => {
                navigate(routes.ACCOUNT_OVERVIEW)
              }}
              className="mt-4"
            />

            {/* Get help */}
            <GetHelpMenuButton
              onClick={handleHelpButtonClicked}
              className="mt-4"
            />

            {/* Spacer */}
            <div className="my-8 tablet:my-10" />

            {/* Sign out */}
            <Button
              type="secondary"
              size="sm"
              shadowHeight={2}
              text="Sign out"
              onClick={handleSignOut}
              className="mt-4"
            />
          </>
        )}
      </div>
    </MobileSidebar>
  )
}

MobileNavigationSidebar.propTypes = {
  isSidebarDisplayed: PropTypes.bool.isRequired,
  setSidebarDisplayed: PropTypes.func.isRequired,
  animationDuration: PropTypes.number.isRequired,
}

export default MobileNavigationSidebar
MobileNavigationSidebar.whyDidYouRender = true
