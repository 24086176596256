import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import BreakpointContext from "context/breakpointContext"
import PropTypes from "prop-types"
import React, { useContext } from "react"
// import "../../services/fontawesome"
import { size } from "helpers/responsiveSize"

//
// Checkbox control
//
// Add 'onValueChanged' delegate for updates to internal state.
//
const Checkbox = ({
  isChecked,
  setChecked,
  isDisabled = false,
  canUncheck = true, // One-way valve!
  children,
  className,
}) => {
  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  //
  // Handle checkbox click
  //
  const handleClick = () => {
    if (isDisabled) {
      return
    }
    if (isChecked === true && canUncheck === false) {
      return
    }
    setChecked(!isChecked)
  }

  return (
    <div
      role="checkbox"
      aria-checked={isChecked}
      tabIndex={0}
      onClick={handleClick}
      onKeyDown={() => {
        // Ignore this event
      }}
      className={`
        grid
        grid-cols-checkbox
        items-center
        gap-2
        cursor-pointer
        focus:outline-none
        ${isDisabled ? "opacity-40" : ""}
        ${className ? className : ""}
      `}
    >
      <div
        style={{
          width: size(bpt, "6vw"),
          height: size(bpt, "6vw"),
        }}
        className="
          flex
          place-items-center
          shadow-input
          rounded-full
          bg-white
          tablet:w-7 desktop:w-10
          tablet:h-7 desktop:h-10"
      >
        {isChecked && (
          <FontAwesomeIcon
            icon="check"
            transform="right-7 down-1"
            style={{
              width: size(bpt, "3.5vw", undefined, undefined, "1.4rem"),
              height: size(bpt, "3.5vw", undefined, undefined, "1.4rem"),
            }}
            className="text-red"
          />
        )}
      </div>
      <div className="self-center select-none">{children}</div>
    </div>
  )
}

Checkbox.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  setChecked: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  canUncheck: PropTypes.bool,
}

export default Checkbox
