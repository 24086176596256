// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-app-jsx": () => import("./../../../src/pages/app.jsx" /* webpackChunkName: "component---src-pages-app-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-store-choose-jsx": () => import("./../../../src/pages/store/choose.jsx" /* webpackChunkName: "component---src-pages-store-choose-jsx" */),
  "component---src-pages-store-index-jsx": () => import("./../../../src/pages/store/index.jsx" /* webpackChunkName: "component---src-pages-store-index-jsx" */),
  "component---src-pages-store-warning-jsx": () => import("./../../../src/pages/store/warning.jsx" /* webpackChunkName: "component---src-pages-store-warning-jsx" */),
  "component---src-pages-styles-buttons-jsx": () => import("./../../../src/pages/styles/buttons.jsx" /* webpackChunkName: "component---src-pages-styles-buttons-jsx" */),
  "component---src-pages-styles-palette-jsx": () => import("./../../../src/pages/styles/palette.jsx" /* webpackChunkName: "component---src-pages-styles-palette-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-uk-store-audiobooks-jsx": () => import("./../../../src/pages/uk/store/audiobooks.jsx" /* webpackChunkName: "component---src-pages-uk-store-audiobooks-jsx" */),
  "component---src-pages-uk-store-collection-jsx": () => import("./../../../src/pages/uk/store/collection.jsx" /* webpackChunkName: "component---src-pages-uk-store-collection-jsx" */),
  "component---src-pages-uk-store-featured-jsx": () => import("./../../../src/pages/uk/store/featured.jsx" /* webpackChunkName: "component---src-pages-uk-store-featured-jsx" */),
  "component---src-pages-uk-store-podcasts-jsx": () => import("./../../../src/pages/uk/store/podcasts.jsx" /* webpackChunkName: "component---src-pages-uk-store-podcasts-jsx" */),
  "component---src-pages-uk-store-search-jsx": () => import("./../../../src/pages/uk/store/search.jsx" /* webpackChunkName: "component---src-pages-uk-store-search-jsx" */),
  "component---src-pages-uk-store-stations-jsx": () => import("./../../../src/pages/uk/store/stations.jsx" /* webpackChunkName: "component---src-pages-uk-store-stations-jsx" */),
  "component---src-pages-us-store-index-jsx": () => import("./../../../src/pages/us/store/index.jsx" /* webpackChunkName: "component---src-pages-us-store-index-jsx" */),
  "component---src-pages-world-store-index-jsx": () => import("./../../../src/pages/world/store/index.jsx" /* webpackChunkName: "component---src-pages-world-store-index-jsx" */)
}

