import produce from "immer"
import React, { createContext, useContext, useEffect, useReducer } from "react"

/**
 * A context provider that indicates which app "screen"
 * is curently active.
 */
export const ScreenContext = createContext()
export const ScreenDispatchContext = createContext()

// Main app "screens"
export const Screen = {
  STORE: "Store",
  LIBRARY: "Library",
  SEARCH: "Search",
}

const initialState = {
  screen: undefined,
}

export const SCREEN = "screen"

const reducer = (state, action) => {
  switch (action.type) {
    case SCREEN:
      const screen = action.payload
      return produce(state, (draftState) => {
        draftState.screen = screen
      })
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

export const ScreenProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <ScreenContext.Provider value={state}>
      <ScreenDispatchContext.Provider value={dispatch}>
        {children}
      </ScreenDispatchContext.Provider>
    </ScreenContext.Provider>
  )
}

export const useScreenContext = () => {
  const context = useContext(ScreenContext)
  if (context === undefined) {
    throw new Error("useScreenContext must be used within a ScreenProvider")
  }
  return context
}

/**
 * Set the active screen.
 *
 * @param {Screens} screen the currently active screen
 */
export const useSetScreen = (screen) => {
  const context = useContext(ScreenDispatchContext)
  useEffect(() => {
    if (screen && context) {
      context({
        type: SCREEN,
        payload: screen,
      })
    }
  }, [screen, context])
}
