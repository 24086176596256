import { oneOf } from "prop-types"
import React, { useMemo } from "react"

const StationIcon = (props) => {
  const {
    foregroundColor = "#ff005c",
    alignment = "left",
    style,
    className,
  } = props

  //
  // Align the icon using a View Box
  //
  const calculateViewBox = useMemo(() => {
    switch (alignment) {
      case "left":
        return "0 0 24 24"
      case "center":
        return "-5 0 24 24"
      case "right":
        return "-9 0 24 24"
      default:
        return "0 0 24 24"
    }
  }, [alignment])

  return (
    <div style={style ? style : {}} className={className ? className : ""}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={calculateViewBox}>
        <g fill="none" fillRule="evenodd">
          <g fill={foregroundColor} fillRule="nonzero">
            <g>
              <path
                d="M7.5 7.85c1.09 0 2.028-.771 2.24-1.84.212-1.07-.36-2.14-1.368-2.557-1.008-.416-2.17-.063-2.774.844-.605.907-.485 2.115.287 2.886.428.428 1.01.668 1.615.667z"
                transform="translate(-1094 -357) translate(1094 357)"
              />
              <path
                d="M5.232 3.3c.241-.3.217-.732-.056-1.003-.273-.27-.706-.29-1.003-.047-1.836 1.839-1.836 4.816 0 6.655.295.275.756.267 1.041-.018.286-.286.294-.746.018-1.042-.603-.602-.942-1.42-.942-2.272 0-.853.339-1.67.942-2.273zM10.297 9.115c.2 0 .39-.08.53-.22 1.835-1.84 1.835-4.816 0-6.655-.295-.275-.756-.267-1.041.018-.286.286-.294.746-.018 1.042 1.25 1.253 1.25 3.282 0 4.535-.215.214-.279.537-.163.817.116.28.39.463.692.463z"
                transform="translate(-1094 -357) translate(1094 357)"
              />
              <path
                d="M2.75 11.075c.303 0 .576-.183.692-.463.116-.28.052-.603-.162-.817C.949 7.459.949 3.676 3.28 1.34c.275-.296.267-.756-.018-1.042C2.976.013 2.516.005 2.22.28c-2.91 2.926-2.91 7.652 0 10.578.14.14.332.217.53.217zM11.727.28c-.292.293-.292.767 0 1.06 2.332 2.336 2.332 6.119 0 8.455-.2.187-.283.469-.215.734.068.266.275.474.541.541.266.068.548-.014.734-.215 2.91-2.926 2.91-7.652 0-10.578-.293-.291-.767-.29-1.06.003zM14.02 23.082c0-.022 0-.044-.012-.07-.007-.026-.014-.052-.023-.077V22.9L8.195 9.075l-.015-.03c-.015-.034-.033-.066-.053-.098-.012-.02-.027-.034-.042-.052-.03-.043-.067-.08-.11-.11-.018-.015-.032-.03-.052-.043-.032-.02-.064-.037-.098-.052l-.03-.015c-.046-.019-.094-.033-.143-.043h-.035c-.036-.002-.073-.002-.11 0H7.36c-.048.01-.095.024-.14.043l-.042.022c-.03.014-.058.029-.085.046-.02.012-.038.03-.058.044-.042.03-.078.068-.107.11-.016.018-.03.033-.043.053-.02.031-.037.064-.053.098l-.014.03-5.79 13.825v.035c-.01.025-.017.05-.023.077 0 .023 0 .047-.015.07-.015.023 0 .047 0 .072V23.3c0 .023 0 .047.012.07.013.022.013.047.02.07.008.022.018.045.028.067.01.023.02.043.032.063.014.022.03.044.045.065l.02.03.02.02c.023.026.047.05.073.073l.048.04c.025.017.052.033.08.047l.06.033c.026.01.054.018.082.024l.052.015c.09.018.183.018.273 0h.038l.075-.02.044-.017.068-.03.04-.023.067-.047.023-.015c1.771-1.76 3.541-2.64 5.31-2.64 1.769 0 3.537.882 5.305 2.645l.023.015.07.047.04.023.067.03.045.017.077.02h.038c.09.018.183.018.273 0l.052-.015c.028-.006.056-.015.082-.024l.06-.033c.028-.014.055-.03.08-.048l.048-.04c.026-.021.05-.045.072-.07l.02-.02.02-.03c.016-.02.03-.042.043-.064l.033-.063c0-.023.02-.045.027-.067.008-.023.015-.048.02-.07.005-.023 0-.048.013-.07.012-.023 0-.048 0-.07v-.073c0-.023.017-.057.012-.082z"
                transform="translate(-1094 -357) translate(1094 357)"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

StationIcon.propTypes = {
  alignment: oneOf(["left", "center", "right"]),
}

export default StationIcon
