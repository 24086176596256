import Button from "components/controls/button"
import { useUserContext } from "context/userContext"
import { ACTIVE_PROFILE, useProfileDispatcher } from "context/profileContext"
import { navigate } from "gatsby"
import { constructAvatar } from "helpers/constructAvatar"
import { useMediaQueries } from "hooks/useMediaQueries"
import PropTypes from "prop-types"
import React from "react"
import { useProfilesQuery } from "reactQuery/queries/profilesQuery"
import routes from "services/routes"
import MobileSidebar, { SidebarLocation } from "./mobileSidebar"

//
// Profile Switcher Sidebar for mobile devices
//
const MobileProfilesSidebar = ({
  isSidebarDisplayed,
  setSidebarDisplayed,
  animationDuration,
}) => {
  // Media queries for React
  const { isMobile } = useMediaQueries()

  // Auth state from context
  const { userId } = useUserContext()

  // Query profiles for this user
  const { data: profiles } = useProfilesQuery(userId)

  // Profile dispatcher
  const profileDispatcher = useProfileDispatcher()

  // Handle a profile button click
  const handleAvatarClick = (profile, hasPause = true) => {
    setSidebarDisplayed(false)
    setTimeout(
      () => {
        profileDispatcher({
          type: ACTIVE_PROFILE,
          payload: profile,
        })
      },
      hasPause ? animationDuration : 0
    )
  }

  return (
    <MobileSidebar
      location={SidebarLocation.RIGHT}
      isSidebarDisplayed={isSidebarDisplayed}
      setSidebarDisplayed={setSidebarDisplayed}
      animationDuration={animationDuration}
    >
      {/* Sidebar contents */}
      <div className="flex flex-col pt-11 pl-10 pr-12">
        {/* Profiles */}
        <p
          style={{
            fontSize: isMobile ? "3.3vw" : "1.6vw",
            marginBottom: isMobile ? "4vw" : "2vw",
          }}
          className="uppercase"
        >
          Switch Profile
        </p>

        {profiles?.map((profile) => {
          return (
            <div
              key={profile.id}
              style={{
                marginBottom: isMobile ? "4vw" : "1.5vw",
              }}
              className="
                flex
                items-center
                justify-center
                select-none"
            >
              <Button
                shadowHeight={2}
                icon={constructAvatar(profile.avatar)}
                onClick={() => handleAvatarClick(profile)}
                style={{
                  width: isMobile ? "10vw" : "5vw",
                  height: isMobile ? "10vw" : "5vw",
                }}
              />
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
              <p
                onClick={() => handleAvatarClick(profile, false)}
                onKeyPress={() => {}}
                className="
                  flex-grow
                  ml-3
                  text-lg
                  line-clamp-1"
              >
                {profile.name}
              </p>
            </div>
          )
        })}

        <hr
          className="
            text-grey
            mt-4 tablet:mt-6
            mb-2 tablet:mb-3"
        />

        {/* Edit profiles */}
        <Button
          size="sm"
          shadowHeight={2}
          text="Edit Profiles"
          onClick={() => {
            setSidebarDisplayed(false)
            setTimeout(() => {
              navigate(routes.EDIT_PROFILES)
            }, animationDuration)
          }}
          className="mt-4"
        />
      </div>
    </MobileSidebar>
  )
}

MobileProfilesSidebar.propTypes = {
  isSidebarDisplayed: PropTypes.bool.isRequired,
  setSidebarDisplayed: PropTypes.func.isRequired,
  animationDuration: PropTypes.number.isRequired,
}

export default MobileProfilesSidebar
MobileProfilesSidebar.whyDidYouRender = true
