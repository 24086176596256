import { useLocation } from "@reach/router"
import React, { useEffect } from "react"

// Message stating that 3D Secure bank authentication is complete
export const BANK_AUTH_COMPLETE_CODE = "bank-auth-complete"

/**
 * A simple page that is called by Stripe when a 3D Secure bank authentication
 * process is complete, irrespective of whether it succeeds or fails.
 *
 * When this page loads, it is in the context of an embedded <iframe> in a
 * PaymentDialog, so it needs to send a message in order to communicate
 * completion.
 */
const BankAuthComplete = () => {
  // Grab the location from Reach Router for Metadata
  const location = useLocation()

  useEffect(() => {
    let urlParams = new URLSearchParams(location.search)
    let paymentIntentId = urlParams.get("payment_intent")
    if (!paymentIntentId) {
      throw Error(
        "Bank auth response is missing the associated PaymentIntent ID!"
      )
    }
    console.debug(
      `<-- Bank auth complete for Payment Intent '${paymentIntentId}'`
    )

    // Send a completion message to the parent window
    window.parent.postMessage({
      code: BANK_AUTH_COMPLETE_CODE,
      paymentIntentId: paymentIntentId,
    })
  }, [location.search])

  return <></>
}

export default BankAuthComplete
