import Backdrop from "components/backdrop"
import { useChatlioContext } from "context/chatlioContext"
import React from "react"

/**
 * A backdrop that activates when the Chatlio widget is visible.
 */
const ChatBackdrop = () => {
  // Chatlio API context
  const { isReady, isExpanded } = useChatlioContext()

  return <Backdrop visible={isReady && isExpanded} duration={100} />
}

export default ChatBackdrop
