const plugin = require("tailwindcss/plugin")

module.exports = {
  purge: ["./src/**/*.html", "./src/**/*.js", "./src/**/*.jsx"],
  theme: {
    screens: {
      tablet: "768px",
      laptop: "1024px",
      desktop: "1440px",
      touch: { raw: "(hover: none)" },
      notouch: { raw: "(hover: hover)" },
      "landscape-mobile": {
        raw: "(orientation: landscape) and (max-height: 320px)",
      },
      "landscape-tablet": {
        raw: "(orientation: landscape) and (min-height: 321px) and (max-height: 768px)",
      },
    },
    minHeight: {
      "screen-touch": "-webkit-fill-available", // Cope with mobile Safari (see: see: https://dev.to/maciejtrzcinski/100vh-problem-with-ios-safari-3ge9)
      "screen-no-touch": "100vh",
    },
    colors: {
      // Transparent
      transparent: "rgba(0,0,0,0)",
      // Black
      black: "#000000",
      // White
      white: "#ffffff",
      // Greys
      grey: {
        scrollbar: "#dadce0",
        transparent: "rgb(230,231,236, 0)", // Safari bug: https://css-tricks.com/thing-know-gradients-transparent-black/
        light: "#e6e7ec",
        DEFAULT: "#969caa",
        dark: "#6d7587",
        darker: "#4b505d",
      },
      // Grey Shadows
      "grey-shadow": {
        light: "#fafbff",
        dark: "#a6abbd",
      },
      // Reds
      red: {
        lightest: "#ff99bd",
        lighter: "#ff669d",
        light: "#ff337c",
        DEFAULT: "#ff005c",
        dark: "#cc0049",
        darker: "#990037",
        darkest: "#660024",
      },
      // Greens
      green: {
        lightest: "#99edd5",
        lighter: "#66e5c0",
        light: "#33dcab",
        DEFAULT: "#00d496",
        dark: "#00a978",
        darker: "#007f5a",
        darkest: "#00543c",
      },
      // Yellows
      yellow: {
        lightest: "#fef29c",
        lighter: "#fdeb6a",
        light: "#fde539",
        DEFAULT: "#fddd08",
        dark: "#cab206",
        darker: "#978504",
        darkest: "#655903",
      },
      // Oranges
      orange: {
        DEFAULT: "#ff6d01",
      },
      // Blues
      blue: {
        lightest: "#b3acfc",
        lighter: "#8d82fb",
        light: "#6759fa",
        DEFAULT: "#4130f9",
        dark: "#3426c7",
        darker: "#271c95",
        darkest: "#1a1363",
      },
      // Purples
      purple: {
        DEFAULT: "#AB05F2",
      },
    },
    extend: {
      fontFamily: {
        UlmGrotesk: ['"Ulm Grotesk"'],
      },
      fontSize: {
        xxxxs: "0.5625rem", // 9px
        xxxs: "0.625rem", // 10px
        xxs: "0.6875rem", // 11px
        "2.5xl": "1.6875rem", // 27px
        "3.5xl": "2rem", // 32px
        "4.5xl": "2.5rem", // 40px

        // Equivalent XS fluid text sizes
        "xxs-fluid": "2.82vw", // (390x844 DPR 3)
        "xxs-fluid-t": "1.35vw", // (820x1180 DPR 2)
        "xxs-fluid-l": "1.075vw", // (1024x1024 DPR 2)
        "xxs-fluid-d": "0.765vw", // (1440x1440 DPR 2)

        // Equivalent XS fluid text sizes
        "xs-fluid": "3.1vw", // (390x844 DPR 3)
        "xs-fluid-t": "1.47vw", // (820x1180 DPR 2)
        "xs-fluid-l": "1.18vw", // (1024x1024 DPR 2)
        "xs-fluid-d": "0.835vw", // (1440x1440 DPR 2)

        // Equivalent SM fluid text sizes
        "sm-fluid": "3.6vw",
        "sm-fluid-t": "1.71vw",
        "sm-fluid-l": "1.37vw",
        "sm-fluid-d": "0.97vw",

        // Equivalent Base fluid text sizes
        "base-fluid": "4.1vw",
        "base-fluid-t": "1.95vw",
        "base-fluid-l": "1.56vw",
        "base-fluid-d": "1.11vw",

        // Equivalent LG fluid text sizes
        "lg-fluid": "4.64vw",
        "lg-fluid-t": "2.2vw",
        "lg-fluid-l": "1.76vw",
        "lg-fluid-d": "1.25vw",

        // Equivalent XL fluid text sizes
        "xl-fluid": "5.13vw",
        "xl-fluid-t": "2.45vw",
        "xl-fluid-l": "1.95vw",
        "xl-fluid-d": "1.39vw",

        // Equivalent 2XL fluid text sizes
        "2xl-fluid": "6.15vw",
        "2xl-fluid-t": "2.93vw",
        "2xl-fluid-l": "2.34vw",
        "2xl-fluid-d": "1.67vw",

        // Equivalent 3XL fluid text sizes
        "3xl-fluid": "7.7vw",
        "3xl-fluid-t": "3.66vw",
        "3xl-fluid-l": "2.93vw",
        "3xl-fluid-d": "2.09vw",

        // Equivalent 4XL fluid text sizes
        "4xl-fluid": "9.2vw",
        "4xl-fluid-t": "4.38vw",
        "4xl-fluid-l": "3.51vw",
        "4xl-fluid-d": "2.5vw",

        // Equivalent 5XL fluid text sizes
        "5xl-fluid": "12.4vw",
        "5xl-fluid-t": "5.85vw",
        "5xl-fluid-l": "4.7vw",
        "5xl-fluid-d": "3.34vw",
      },
      spacing: {
        0.75: "0.1875rem", // 3px
        1.25: "0.3125rem", // 5px
        1.75: "0.4375rem", // 7px
        2.25: "0.5625rem", // 9px
        2.75: "0.6875rem", // 11px
        15: "3.75rem", // 60px
        22: "5.5rem", // 88px
        26: "6.5rem", // 104px
        30: "7.5rem", // 120px
        68: "17rem", // 272px

        // Percentages: TODO: see which are actually in use!
        "0.75pc": "0.75%", // 0.75%
        "6pc": "6%", // 6%
        "8pc": "8%", // 8%
        "11pc": "11%", // 11%
        "15pc": "15%", // 15%
        "17pc": "17%", // 17%
        "40pc": "40%", // 40%

        // View Width
        "2vw": "2vw", // 2vw
        "4vw": "4vw", // 4vw
        "7vw": "7vw", // 7vw
        "10vw": "10vw", // 10vw

        //
        // Grid column offsets (page margins)
        // ------------------------------------------
        //
        "m-offsets-fixed": "29px", // Mobile offsets (fixed)
        "t-offsets-fixed": "48px", // Tablet offsets (fixed)
        "l-offsets-fixed": "66px", // Laptop offsets (fixed)
        "d-offsets-fixed": "84px", // Desktop offsets (fixed)

        "m-offsets-fluid": "9.0625%", // Mobile offsets (fluid)
        "t-offsets-fluid": "6.25%", // Tablet offsets (fluid)
        "l-offsets-fluid": "6.4453125%", // Laptop offsets (fluid)
        "d-offsets-fluid": "5.8333333333%", // Desktop offsets (fluid)

        //
        // Grid column gutters (space between columns)
        // ------------------------------------------
        //
        "m-gutter-fixed": "19px", // Mobile gutters (fixed)
        "t-gutter-fixed": "24px", // Tablet gutters (fixed)
        "l-gutter-fixed": "34px", // Laptop gutters (fixed)
        "d-gutter-fixed": "48px", // Desktop gutters (fixed)

        "m-gutter-fluid": "5.9375%", // Mobile gutters (fluid)
        "t-gutter-fluid": "3.125%", // Tablet gutters (fluid)
        "l-gutter-fluid": "3.3203125%", // Laptop gutters (fluid)
        "d-gutter-fluid": "3.3333333333%", // Desktop gutters (fluid)

        //
        // Block margins for home page
        // ---------------------------
        //
        "m-block-fluid": "8vw",
        "t-block-fluid": "7vw",
        "l-block-fluid": "6vw",
        "d-block-fluid": "6vw",
      },
      width: {
        "80pc": "80%", // 80%
        18: "4.5rem",
        "dialog-xs": "20rem",
        "dialog-sm": "24rem",
        "dialog-md": "28rem",
        "dialog-lg": "32rem",
        "dialog-xl": "36rem",
      },
      maxWidth: {
        // These are set to accommodate the standard 3D Secure page sizes
        // with enough chrome on the payment dialog.
        // See: https://stripe.com/docs/payments/3d-secure#render-iframe
        "bank-auth-sm": "310px",
        "bank-auth-lg": "660px",
      },
      inset: {
        "8pc": "8%", // 8%
      },
      scale: {
        65: ".65",
        74: ".74",
        99: ".99",
      },
      borderRadius: {
        "2.5xl": "1.25rem",
        // "3xl" : "1.5rem"  (in Tailwind already)
        "3.5xl": "1.75rem",
        "4xl": "2rem",
        "4.5xl": "2.25rem",
        "5xl": "2.5rem",
      },
      boxShadow: {
        dialog: "0px 0px 30px 5px rgba(0,0,0,0.75)",
        input: "inset 4px 4px 8px #c2c8ce, inset -4px -4px 8px #ffffff",
        footer: "inset 0 10px 10px -10px rgba(0,0,0,0.8)",
        "tab-bar": "0px -4px 6px -1px rgba(0, 0, 0, 0.1)",

        "navbar-link":
          "inset 5px 5px 10px rgba(0, 0, 0, 0.2), inset -5px -5px 10px rgba(255, 255, 255, 1)",

        "subnav-item":
          "inset 2px 2px 2px rgba(0, 0, 0, 0.1), inset -2px -2px 2px rgba(255, 255, 255, 0.6)",

        "content-xl": "0 0 6px 2px rgba(0, 0, 0, 0.5) inset",
        "content-md": "0 0 5px 2px rgba(0, 0, 0, 0.5) inset",
        "content-sm": "0 0 4px 1px rgba(0, 0, 0, 0.5) inset",

        "inner-white": "0 0 1px 0 #ffffff inset, 0 0 1px 0 #ffffff",
        "inner-red": "0 0 1px 0 #ff005c inset, 0 0 1px 0 #ff005c",
        "inner-red-dark": "0 0 1px 0 #cc0049 inset, 0 0 1px 0 #cc0049",
        "inner-grey": "0 0 1px 0 #969caa inset, 0 0 1px 0 #969caa",
        "inner-grey-light": "0 0 1px 0 #e6e7ec inset, 0 0 1px 0 #e6e7ec",
        "inner-grey-dark": "0 0 1px 0 #6d7587 inset, 0 0 1px 0 #6d7587",
      },
      gridTemplateColumns: {
        //
        // Grid offsets
        // --------------------------------------
        //
        "no-offsets": "0 auto 0", // No offsets

        "m-offsets-fluid": "9.0625% auto 9.0625%", // Mobile offsets (fluid)
        "t-offsets-fluid": "6.25% auto 6.25%", // Tablet offsets (fluid)
        "l-offsets-fluid": "6.4453125% auto 6.4453125%", // Laptop offsets (fluid)
        "d-offsets-fluid": "5.8333333333% auto 5.8333333333%", // Desktop offsets (fluid)

        "m-offsets-fixed": "29px auto 29px", // Mobile offsets (fixed)
        "t-offsets-fixed": "48px auto 48px", // Tablet offsets (fixed)
        "l-offsets-fixed": "66px auto 66px", // Laptop offsets (fixed)
        "d-offsets-fixed": "84px auto 84px", // Desktop offsets (fixed)

        navbar: "auto auto auto 1fr auto auto",
        "fixed-layout": "auto 1fr auto",
        "fluid-layout": "9.0625vw 1fr 9.0625vw",
        checkbox: "auto 1fr",
      },
      gridTemplateRows: {
        "choose-avatar": "1fr 1fr auto 10fr",
      },
      animation: {
        "ping-slow": "ping 2s cubic-bezier(0, 0, 0.2, 1) infinite",
      },
    },
  },
  variants: {
    extend: {
      borderWidth: ["hover"],
      padding: ["hover"],
      boxShadow: ["hover"],
    },
    scrollSnapType: ["responsive"], // See: https://github.com/innocenzi/tailwindcss-scroll-snap
    scrollMargin: ["responsive"],
    scrollPadding: ["responsive"],
  },
  plugins: [
    require("tailwindcss-scroll-snap"),

    //
    // Neumorphic utilities
    //
    plugin(function ({ addUtilities }) {
      const heights = [1, 2, 3, 4, 5, 6, 7, 8]
      let neumorphicUtilities = {}
      for (const height of heights) {
        //
        // Outer shadow
        // e.g.: "5px 5px #a6abbd, -5px -5px 10px #fafbff"
        //
        neumorphicUtilities[`.neumorphic-${height}-outer`] = {
          boxShadow: `${height}px ${height}px ${
            height * 2
          }px #a6abbd, -${height}px -${height}px ${height * 2}px #fafbff`,
          backgroundBlendMode: "soft-light, normal",
        }

        //
        // Inner shadow
        // e.g.: "inset 5px 5px 10px rgba(0, 0, 0, 0.4), inset -5px -5px 10px rgba(255, 255, 255, 0.6)"
        //
        neumorphicUtilities[`.neumorphic-${height}-inner`] = {
          boxShadow: `inset ${height}px ${height}px ${
            height * 2
          }px rgba(126,131,149, 0.6), inset -${height}px -${height}px ${
            height * 2
          }px rgba(255,255,255, 0.6)`,
          backgroundBlendMode: "soft-light, normal",
        }

        //
        // Shadow on colour background
        // e.g.: "5px 5px 10px rgba(0, 0, 0, 0.4), -5px -5px 8px rgba(255, 255, 255, 0.3)",
        //
        neumorphicUtilities[`.neumorphic-${height}-color`] = {
          boxShadow: `${height}px ${height}px ${
            height * 2
          }px rgba(0, 0, 0, 0.3), -${height}px -${height}px ${
            height * 2
          }px rgba(255, 255, 255, 0.3)`,
          backgroundBlendMode: "soft-light, normal",
        }

        //
        // Ridge shadow
        // e.g.: "-5px -5px 10px 0 rgba(0, 0, 0, 0.4), 5px 5px 10px 0 rgba(255, 255, 255, 1),
        //         inset 2px 2px 5px 0 rgba(255, 255, 255, 1), inset -2px -2px 5px 0 rgba(0, 0, 0, 0.4)",
        //
        neumorphicUtilities[`.neumorphic-${height}-ridge`] = {
          boxShadow: `${height}px ${height}px ${
            height * 2 + 1
          }px #a6abbd, -${height}px -${height}px ${
            height * 2 + 1
          }px #fafbff,inset ${height / 2}px ${
            height / 2
          }px ${height}px #a6abbd, inset -${height / 2}px -${
            height / 2
          }px ${height}px #fafbff`,
          backgroundBlendMode: "soft-light, normal",
        }
      }

      addUtilities(neumorphicUtilities, {
        variants: ["responsive"],
      })
    }),

    //
    // Line-clamp utilities
    //
    plugin(function ({ addUtilities }) {
      const lines = [1, 2, 3, 4, 5]
      let lineClampUtilities = {}
      for (const line of lines) {
        lineClampUtilities[`.line-clamp-${line}`] = {
          WebkitLineClamp: `${line}`,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          display: "-webkit-box",
        }
      }
      addUtilities(lineClampUtilities, {
        variants: ["responsive"],
      })
    }),
  ],
}
