import produce from "immer"
import * as React from "react"
import { createContext, useContext, useEffect, useReducer } from "react"

//
// A context provider that stores state about the currently active profile.
//
export const ProfileStateContext = createContext()
export const ProfileDispatchContext = createContext()

const initialState = {
  activeProfileId: undefined,
  activeProfileName: undefined,
  activeProfileAvatar: undefined,
}

export const ACTIVE_PROFILE_KEY = "activeProfile"
export const ACTIVE_PROFILE = "ACTIVE_PROFILE"

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIVE_PROFILE:
      const profile = action.payload
      if (localStorage) {
        // Store profile in local storage
        localStorage.setItem("activeProfile", JSON.stringify(profile))
      }
      return produce(state, (draftState) => {
        const { id, name, avatar } = profile
        draftState.activeProfileId = id
        draftState.activeProfileName = name
        draftState.activeProfileAvatar = avatar
      })
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

export const ProfileProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  // Load active profile from local storage
  useEffect(() => {
    if (localStorage) {
      const activeProfile = JSON.parse(localStorage.getItem(ACTIVE_PROFILE_KEY))
      if (activeProfile) {
        dispatch({
          type: ACTIVE_PROFILE,
          payload: activeProfile,
        })
      }
    }
  }, [])

  return (
    <ProfileStateContext.Provider value={state}>
      <ProfileDispatchContext.Provider value={dispatch}>
        {children}
      </ProfileDispatchContext.Provider>
    </ProfileStateContext.Provider>
  )
}

export const useProfileContext = () => {
  const context = useContext(ProfileStateContext)
  if (context === undefined) {
    throw new Error("useProfileContext must be used within a ProfileProvider")
  }
  return context
}

export const useProfileDispatcher = () => {
  const context = useContext(ProfileDispatchContext)
  if (context === undefined) {
    throw new Error(
      "useProfileDispatcher must be used within a ProfileProvider"
    )
  }
  return context
}
