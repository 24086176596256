//
// Firebase config
//
const config = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
}

//
// Load and initialize Firebase using a Promise
//
let getFirebasePromise = undefined
let firebaseInstance = undefined
export const getFirebase = () => {
  if (firebaseInstance !== undefined) {
    console.debug("Reusing existing Firebase instance.")
    return Promise.resolve(firebaseInstance)
  }
  if (getFirebasePromise !== undefined) {
    console.debug("Firebase already loading; reusing Promise...")
    return getFirebasePromise
  }
  getFirebasePromise = new Promise((resolve, reject) => {
    console.debug("Created new Promise to load Firebase...")
    import("firebase/app")
      .then((firebase) => {
        firebase.default.initializeApp(config)
        firebaseInstance = firebase
        console.debug("Firebase loaded.")
        import("firebase/functions").then(() => {
          console.debug("Firebase functions loaded.")
          resolve(firebase)
        })
      })
      .catch((error) => {
        reject(error)
      })
  })
  return getFirebasePromise
}

//
// Load Firestore module of Firebase
//
let firestoreLoaded = false
export const getFirestore = () => {
  return new Promise((resolve, reject) => {
    if (firebaseInstance && firestoreLoaded) {
      console.debug("Firebase & Firestore already loaded")
      resolve(firebaseInstance.firestore())
    } else if (firebaseInstance && !firestoreLoaded) {
      console.debug("Firebase already loaded; loading Firestore...")
      import("firebase/firestore")
        .then(() => {
          console.debug("Firestore loaded.")
          resolve(firebaseInstance.default.firestore())
        })
        .catch((error) => {
          reject(error)
        })
    } else if (!firebaseInstance && !firestoreLoaded) {
      console.debug("Loading Firebase & Firestore...")
      Promise.all([getFirebase(), import("firebase/firestore")])
        .then(() => {
          console.debug("Firebase & Firestore loaded.")
          resolve(firebaseInstance.default.firestore())
        })
        .catch((error) => {
          reject(error)
        })
    }
  })
}
