import { useLocation } from "@reach/router"
import { SubtitleColours, Subtitles } from "components/navbar/navbar"
import { useCountries } from "context/countryContext"
import { SUBTITLE, useLayoutDispatcher } from "context/layoutContext"
import { useEffect } from "react"
import routes from "services/routes"

//
// A hook to set subtitles based on the current location
//
export const useLocationSubtitles = () => {
  // Layout dispatcher for subtitle updates
  const layoutDispatcher = useLayoutDispatcher()

  // Grab the location from Reach Router for Metadata
  const location = useLocation()

  // Store country
  const { storeURL } = useCountries()

  useEffect(() => {
    // Don't override collection subtitles!
    if (location.pathname.includes("/store/collection")) {
      return
    }

    switch (location.pathname) {
      // Audiobooks
      case routes.STORE_AUDIOBOOKS(storeURL):
      case routes.LIBRARY_AUDIOBOOKS:
        layoutDispatcher({
          type: SUBTITLE,
          payload: {
            navbarSubtitle: Subtitles.AUDIOBOOKS,
            navbarSubtitleColor: SubtitleColours.AUDIOBOOKS,
          },
        })
        break

      // Podcasts
      case routes.STORE_PODCASTS(storeURL):
      case routes.LIBRARY_PODCASTS:
        layoutDispatcher({
          type: SUBTITLE,
          payload: {
            navbarSubtitle: Subtitles.PODCASTS,
            navbarSubtitleColor: SubtitleColours.PODCASTS,
          },
        })
        break

      // Radio Stations
      case routes.STORE_STATIONS(storeURL):
      case routes.LIBRARY_STATIONS:
        layoutDispatcher({
          type: SUBTITLE,
          payload: {
            navbarSubtitle: Subtitles.STATIONS,
            navbarSubtitleColor: SubtitleColours.STATIONS,
          },
        })
        break

      default:
        layoutDispatcher({
          type: SUBTITLE,
          payload: {
            navbarSubtitle: undefined,
            navbarSubtitleColor: undefined,
          },
        })
        break
    }
  }, [location, layoutDispatcher, storeURL])
}
