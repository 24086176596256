import Backdrop from "components/backdrop"
import Button from "components/controls/button"
import { useUserContext } from "context/userContext"
import { useLayoutContext } from "context/layoutContext"
import {
  ACTIVE_PROFILE,
  useProfileContext,
  useProfileDispatcher,
} from "context/profileContext"
import {
  MODE,
  MODES,
  useProfileHandlingDispatcher,
} from "context/profileHandlingContext"
import { navigate } from "gatsby"
import { constructAvatar } from "helpers/constructAvatar"
import PlusIcon from "images/plusIcon.svg"
import React, { memo, useCallback, useState } from "react"
import { useProfilesQuery } from "reactQuery/queries/profilesQuery"
import { signOut } from "services/auth"
import routes from "services/routes"
import MenuCallout from "./menuCallout"

//
// The profile menu + button allows users to switch between profiles
// on both desktop and mobile devices.
//
const ProfileMenu = memo(({ className }) => {
  // User ID from context
  const { userId } = useUserContext()

  // Active profile from context
  const { activeProfileAvatar } = useProfileContext()

  // State of menu visibility
  const [menuVisible, setMenuVisible] = useState(false)

  // Layout type for the navbar
  const { layoutType } = useLayoutContext()

  // Profile dispatcher
  const dispatchProfile = useProfileDispatcher()

  // Dispatcher profile handling
  const profileHandlingDispatcher = useProfileHandlingDispatcher()

  // Query profiles for this user
  const { data: profiles } = useProfilesQuery(userId)

  // Handle a profile button click
  const handleAvatarClick = (profile) => {
    dispatchProfile({
      type: ACTIVE_PROFILE,
      payload: profile,
    })
    setMenuVisible(false)
  }

  // Callback for when backdrop is dismissed
  const handleBackdropDismissed = useCallback(() => {
    setMenuVisible(false)
  }, [])

  //
  // Handle sign out click
  //
  const handleSignOut = async () => {
    try {
      await signOut()
      navigate(routes.HOME)
    } catch (error) {
      console.error(error)
    }
  }

  //
  // Handle a click on the "Add Profile" button
  //
  const handleAddProfileClicked = () => {
    // Set handling context mode
    profileHandlingDispatcher({
      type: MODE,
      payload: MODES.CREATING,
    })

    // Navigate to choose avatar screen
    navigate(routes.CHOOSE_AVATAR)
  }

  return (
    <div className={`${className ? className : ""}`}>
      {/* Profile avatar */}
      {activeProfileAvatar && (
        <Button
          icon={constructAvatar(activeProfileAvatar)}
          onClick={() => {
            setMenuVisible(!menuVisible)
          }}
          className="
            ml-3
            laptop:w-10 desktop:w-12 
            laptop:h-10 desktop:h-12"
        />
      )}

      <Backdrop visible={menuVisible} onDismiss={handleBackdropDismissed} />

      {/* Menu */}
      {menuVisible && (
        <div
          className={`
            absolute
            w-68
            z-50
            flex flex-col
            items-end
            laptop:-mr-7 desktop:-mr-6
            desktop:-mt-1
            ${
              layoutType === "fixed"
                ? `
                  laptop:right-l-offsets-fixed
                  desktop:right-d-offsets-fixed
                  `
                : ""
            }      
            ${
              layoutType === "fluid"
                ? `
                  laptop:right-l-offsets-fluid
                  desktop:right-d-offsets-fluid
                  `
                : ""
            }
          `}
        >
          <MenuCallout className="ml-10" />
          <div
            className="
              h-full
              w-full
              rounded-xl
              border border-grey-dark
              shadow-lg
              p-4
              bg-grey-light
              grid
              grid-cols-1
              auto-rows-min
              gap-3"
          >
            {/* Profiles */}
            <p className="text-xxxs text-grey uppercase">Switch Profile</p>
            {profiles?.map((profile) => {
              return (
                <div
                  key={profile.id}
                  className="
                    flex
                    items-center
                    select-none"
                >
                  <Button
                    size="sm"
                    shadowHeight={2}
                    icon={constructAvatar(profile.avatar)}
                    onClick={() => handleAvatarClick(profile)}
                    className="w-10 h-10"
                  />
                  <p
                    className="
                      flex-grow
                      ml-3
                      text-lg
                      line-clamp-1"
                  >
                    {profile.name}
                  </p>
                </div>
              )
            })}

            {/* Add profile button */}
            {profiles?.length < 6 && (
              <div
                className="
                flex
                items-center
                select-none"
              >
                <Button
                  type="secondary"
                  size="sm"
                  shadowHeight={2}
                  icon={<PlusIcon width="1rem" />}
                  keyline={true}
                  onClick={handleAddProfileClicked}
                  className="w-10 h-10"
                />
                <p
                  className="
                  flex-grow
                  ml-3
                  text-lg
                  line-clamp-1"
                >
                  Add Profile
                </p>
              </div>
            )}

            {/* Actions */}

            {/* Edit profiles */}
            <Button
              size="xs"
              shadowHeight={1}
              text="Edit Profiles"
              onClick={() => {
                navigate(routes.EDIT_PROFILES)
              }}
              className="mt-4"
            />

            {/* View account */}
            <Button
              size="xs"
              shadowHeight={1}
              text="View Account"
              onClick={() => {
                setMenuVisible(false)
                navigate(routes.ACCOUNT_OVERVIEW)
              }}
            />

            {/* Get help */}
            {/* TODO: Reinstate? */}
            {/* <Button
              size="xs"
              shadowHeight={1}
              text="Get Help"
              disabled={true}
              onClick={() => {}}
            /> */}

            {/* Sign out */}
            <Button
              type="secondary"
              size="xs"
              shadowHeight={1}
              text="Sign out"
              onClick={handleSignOut}
              className="mt-2"
            />
          </div>
        </div>
      )}
    </div>
  )
})

export default ProfileMenu
ProfileMenu.whyDidYouRender = true
