import { captureContentSelected } from "helpers/analyticsHelper"
import { isSSR } from "helpers/ssrCheck"
import produce from "immer"
import * as React from "react"
import { createContext, useContext, useReducer } from "react"

//
// A context provider that stores state about the currently selected item.
//
export const SelectedItemContext = createContext()
export const SelectedItemDispatchContext = createContext()

const initialState = {
  selectedItem: undefined,
}

export const SELECTED_ITEM = "SELECTED_ITEM"

const reducer = (state, action) => {
  switch (action.type) {
    case SELECTED_ITEM:
      let selectedItem = action.payload
      if (selectedItem) {
        // Log event in Google Analytics
        captureContentSelected(
          selectedItem.type,
          selectedItem.id,
          selectedItem.title,
          selectedItem.saleCurrency,
          selectedItem.salePriceIncTax,
          selectedItem.supplier
        )
      }
      return produce(state, (draftState) => {
        draftState.selectedItem = selectedItem
      })
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

export const SelectedItemProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <SelectedItemContext.Provider value={state}>
      <SelectedItemDispatchContext.Provider value={dispatch}>
        {children}
      </SelectedItemDispatchContext.Provider>
    </SelectedItemContext.Provider>
  )
}

export const useSelectedItemContext = () => {
  const context = useContext(SelectedItemContext)
  if (context === undefined) {
    throw new Error(
      "useSelectedItemContext must be used within a SelectedItemProvider"
    )
  }
  return context
}

export const useSelectedItemDispatchContext = () => {
  const context = useContext(SelectedItemDispatchContext)
  if (context === undefined) {
    if (isSSR) {
      // There is no Context available during SSR
      return
    } else {
      throw new Error(
        "useSelectedItemDispatchContext must be used within a SelectedItemProvider"
      )
    }
  }
  return context
}
