import BreakpointContext from "context/breakpointContext"
import { GRID_DIMENSIONS } from "context/gridDimensionsContext"
import { debounce } from "lodash"
import PropTypes, { oneOf } from "prop-types"
import React, { memo, useContext, useEffect, useRef } from "react"

//
// 12/6 Column Grid Layout
//
const GridLayout = memo(
  ({
    type, // Fixed/Fluid layout type
    container = true, // Contained in normal page boundaries by default
    offsets = true, // Default left and right offsets
    fullHeight = false, // Viewport is locked to screen height
    templateRowsMin = true, // Make minimum rows by default
    children,
    style, // Extra styles for grid container
    styleInner, // Extra styles for inner grid
    className, // Extra classes for grid container
    classNameInner, // Extra classes for the inner grid
    id,
    spyDispatch, // Dispatcher for spy values
  }) => {
    // Grid spy references
    const gridSpyOffset = useRef(null) // Spy on the page offsets
    const gridSpySpan1 = useRef(null) // Spy on a span of 1 columns
    const gridSpySpan2 = useRef(null) // Spy on a span of 2 columns
    const gridSpySpan3 = useRef(null) // Spy on a span of 3 columns

    // The current breakpoint
    const bpt = useContext(BreakpointContext)

    //
    // Update grid dimensions, using a debounced function
    //
    const updateGridDimensions = debounce(
      (offsetWidth, gutterWidth, span3Width) => {
        if (offsetWidth && gutterWidth && span3Width) {
          spyDispatch({
            type: GRID_DIMENSIONS,
            payload: {
              offsetWidth: offsetWidth,
              gutterWidth: gutterWidth,
              span3Width: span3Width,
            },
          })
        } else {
          //
          // No dimensions available yet, so fall back to sensible defaults
          // to prevent page from jumping around on load!
          //
          switch (bpt) {
            case "mobile":
              spyDispatch({
                type: GRID_DIMENSIONS,
                payload: {
                  offsetWidth: 33.984375,
                  gutterWidth: 18.21875,
                  span3Width: 144.40625,
                },
              })
              break
            case "tablet":
              spyDispatch({
                type: GRID_DIMENSIONS,
                payload: {
                  offsetWidth: 48,
                  gutterWidth: 21,
                  span3Width: 152.25,
                },
              })
              break
            case "laptop":
              spyDispatch({
                type: GRID_DIMENSIONS,
                payload: {
                  offsetWidth: 66,
                  gutterWidth: 29.609375,
                  span3Width: 200.78125,
                },
              })
              break
            case "desktop":
              spyDispatch({
                type: GRID_DIMENSIONS,
                payload: {
                  offsetWidth: 84,
                  gutterWidth: 42.390625,
                  span3Width: 286.203125,
                },
              })
              break

            default:
              break
          }
        }
      },
      0
    )

    //
    // Create a Resize Observer to spy on grid constructs
    //
    useEffect(() => {
      if (!spyDispatch) {
        return
      }
      // Resize Observer
      const observer = new ResizeObserver((entries) => {
        let offsetWidth
        let gutterWidth
        let span1Width
        let span2Width
        let span3Width
        for (let entry of entries) {
          const span = entry.target
          const width = entry.contentRect.width
          if (span === gridSpyOffset.current) {
            offsetWidth = width
          } else if (span === gridSpySpan1.current) {
            span1Width = width
          } else if (span === gridSpySpan2.current) {
            span2Width = width
          } else if (span === gridSpySpan3.current) {
            span3Width = width
          }
        }
        if (span1Width && span2Width) {
          const _gutterWidth = span2Width - 2 * span1Width
          if (_gutterWidth && _gutterWidth > 0) {
            gutterWidth = _gutterWidth
          }
        }
        updateGridDimensions(offsetWidth, gutterWidth, span3Width)
      })
      observer.observe(gridSpyOffset.current)
      observer.observe(gridSpySpan1.current)
      observer.observe(gridSpySpan2.current)
      observer.observe(gridSpySpan3.current)

      // Cleanup function to stop observation
      return function cleanup() {
        updateGridDimensions.cancel()
        observer.disconnect()
      }
    }, [spyDispatch, updateGridDimensions])

    return (
      // Outer grid with offsets
      <div
        id={id}
        style={style ? style : {}}
        className={`
          grid
          ${
            offsets
              ? type === "fixed"
                ? `grid-cols-m-offsets-fixed
                  tablet:grid-cols-t-offsets-fixed
                  laptop:grid-cols-l-offsets-fixed
                  desktop:grid-cols-d-offsets-fixed`
                : type === "fluid"
                ? `grid-cols-m-offsets-fluid
                  tablet:grid-cols-t-offsets-fluid
                  laptop:grid-cols-l-offsets-fluid
                  desktop:grid-cols-d-offsets-fluid`
                : ""
              : "grid-cols-no-offsets"
          }
          ${container ? "container mx-auto" : ""}
          ${fullHeight ? "h-screen" : ""}
          ${className ? className : ""}
        `}
      >
        {/* Spy on grid offsets */}
        {spyDispatch && (
          <div
            id="gridSpyOffset"
            ref={gridSpyOffset}
            className="col-start-1 col-span-1 h-px"
          />
        )}

        {/* Inner grid with columns & gutters */}
        <div
          id={id ? `${id}-grid-columns` : ""}
          style={styleInner ? styleInner : {}}
          className={`
          col-start-2
          grid
          grid-cols-6 tablet:grid-cols-12
          ${
            type === "fixed"
              ? `gap-x-m-gutter-fixed 
                 tablet:gap-x-t-gutter-fixed 
                 laptop:gap-x-l-gutter-fixed 
                 desktop:gap-x-d-gutter-fixed`
              : type === "fluid"
              ? `gap-x-m-gutter-fluid 
                 tablet:gap-x-t-gutter-fluid 
                 laptop:gap-x-l-gutter-fluid 
                 desktop:gap-x-d-gutter-fluid`
              : ""
          }
          relative
          ${templateRowsMin ? "auto-rows-min" : "auto-rows-auto"}
          ${classNameInner ? classNameInner : ""}
        `}
        >
          {/* Spy on grid gutters & columns */}
          {spyDispatch && (
            <>
              <div
                ref={gridSpySpan1}
                className="invisible col-start-1 col-span-1"
              />
              <div
                ref={gridSpySpan2}
                className="invisible col-start-2 col-span-2"
              />
              <div
                ref={gridSpySpan3}
                className="invisible col-start-4 col-span-3"
              />
            </>
          )}

          {/* Grid contents */}
          {children}
        </div>
      </div>
    )
  }
)

GridLayout.propTypes = {
  type: oneOf(["fixed", "fluid"]).isRequired,
  children: PropTypes.node,
}

export default GridLayout
GridLayout.whyDidYouRender = false
