//
// Common Podcast attributes
//
const PodcastAttributes = (type = "Podcast") => `
  fragment PodcastAttributes on ${type} {
    type
    id
    ref
    title
    description
    style
    artwork
    website
  }
`

//
// Use CommonJS syntax so that we can require this in gatsby-node.js
// See: https://www.samdawson.dev/article/gatsby-node-sharing-code
//
module.exports = {
  PodcastAttributes: PodcastAttributes,
}
