import React from "react"

const OrangeFaceAvatarIcon = (props) => {
  const { style, className } = props

  return (
    <div style={style ? style : {}} className={className ? className : ""}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="15 15 140 140"
      >
        <defs>
          <linearGradient
            id="vcf55ylkdc"
            x1="100%"
            x2="0%"
            y1="100%"
            y2="-9.901%"
          >
            <stop offset="0%" />
            <stop offset="100%" stopColor="#FFF" />
          </linearGradient>
          <rect id="7nx4taw0zb" width="140" height="140" x="0" y="0" rx="70" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <g>
            <g>
              <g transform="translate(-882 -391) translate(897 406)">
                <use
                  fill="#000"
                  filter="url(#g49rr1e8ea)"
                  xlinkHref="#7nx4taw0zb"
                />
                <use fill="#FF6D00" xlinkHref="#7nx4taw0zb" />
                <use
                  fill="url(#vcf55ylkdc)"
                  fillOpacity=".4"
                  style={{ mixBlendMode: "soft-light" }}
                  xlinkHref="#7nx4taw0zb"
                />
              </g>
              <g fill="#712C00">
                <path
                  d="M43.998 0C50.074 0 55 4.925 55 11s-4.926 11-11.002 11C37.922 22 33 17.075 33 11S37.922 0 43.998 0M11.002 0C17.078 0 22 4.925 22 11s-4.922 11-10.998 11S0 17.075 0 11 4.926 0 11.002 0M3.085 42.237c.453-2.201 2.56-3.616 4.712-3.147l41.046 8.843c2.147.465 3.527 2.625 3.07 4.827-.45 2.206-2.557 3.616-4.704 3.151l-41.05-8.843c-2.151-.468-3.523-2.63-3.074-4.83"
                  transform="translate(-882 -391) translate(897 406) translate(43 42)"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default OrangeFaceAvatarIcon
