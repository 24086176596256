import React from "react"

const GreenFaceAvatarIcon = (props) => {
  const { style, className } = props

  return (
    <div style={style ? style : {}} className={className ? className : ""}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="15 15 140 140"
      >
        <defs>
          <linearGradient
            id="lam7bxoxac"
            x1="100%"
            x2="0%"
            y1="100%"
            y2="-9.901%"
          >
            <stop offset="0%" />
            <stop offset="100%" stopColor="#FFF" />
          </linearGradient>
          <rect id="ynicclzhfb" width="140" height="140" x="0" y="0" rx="70" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <g>
            <g>
              <g transform="translate(-554 -391) translate(569 406)">
                <use
                  fill="#000"
                  filter="url(#uxklbp5fna)"
                  xlinkHref="#ynicclzhfb"
                />
                <use fill="#00D496" xlinkHref="#ynicclzhfb" />
                <use
                  fill="url(#lam7bxoxac)"
                  fillOpacity=".4"
                  style={{ mixBlendMode: "soft-light" }}
                  xlinkHref="#ynicclzhfb"
                />
              </g>
              <g fill="#00543C">
                <path
                  d="M32.498 62.961c-12.513 0-22.89-9.39-24.55-21.536C7.675 39.45 5.995 38 4.023 38 1.629 38-.291 40.122.036 42.518 2.232 58.584 15.95 71 32.498 71c16.548 0 30.27-12.416 32.466-28.482C65.29 40.122 63.37 38 60.976 38c-1.972 0-3.652 1.451-3.923 3.425C55.388 53.57 45.015 62.96 32.498 62.96M14.002 0C20.078 0 25 4.922 25 10.998S20.078 22 14.002 22 3 17.074 3 10.998C3 4.922 7.926 0 14.002 0M51 0c6.075 0 11 4.922 11 10.998S57.075 22 51 22s-11-4.926-11-11.002C40 4.922 44.925 0 51 0"
                  transform="translate(-554 -391) translate(569 406) translate(38 38)"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default GreenFaceAvatarIcon
