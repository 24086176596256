//
// Common Audiobook attributes
//
const AudiobookAttributes = (type = "Audiobook") => `
  fragment AudiobookAttributes on ${type} {
    type
    id
    ref
    slug
    isbn
    title
    description
    author
    author2
    narrator
    publisher
    artwork
    audience
    categories {
      category
      subcategory
    }
    ageFrom
    ageTo
    duration
    publisher
    publicationDate
    saleCurrency
    salePriceIncTax
    salePriceExTax
    supplier
  }
`

//
// Use CommonJS syntax so that we can require this in gatsby-node.js
// See: https://www.samdawson.dev/article/gatsby-node-sharing-code
//
module.exports = {
  AudiobookAttributes: AudiobookAttributes,
}
