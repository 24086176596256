import produce from "immer"
import * as React from "react"
import { createContext, useContext, useReducer } from "react"

//
// A context provider that stores the state of the UI layout
//
export const LayoutStateContext = createContext()
export const LayoutDispatchContext = createContext()

const initialState = {
  layoutType: "fixed",
  hasNavBar: false,
  hasTabBar: false,
  footerVisible: false,
  navbarTitle: undefined,
  navbarSubtitle: undefined,
  navbarSubtitleColor: undefined,
}

export const LAYOUT = "LAYOUT"
export const HAS_NAVBAR = "HAS_NAVBAR"
export const HAS_TABBAR = "HAS_TABBAR"
export const SUBTITLE = "SUBTITLE"
export const FOOTER_VISIBLE = "FOOTER_VISIBLE"
export const LAYOUT_TYPE = "LAYOUT_TYPE"

const reducer = (state, action) => {
  switch (action.type) {
    case LAYOUT:
      return produce(state, (draftState) => {
        const layout = action.payload
        draftState.layoutType = layout.layoutType
        draftState.hasNavBar = layout.hasNavBar
        draftState.navbarTitle = layout.navbarTitle
        draftState.navbarSubtitle = layout.navbarSubtitle
        draftState.navbarSubtitleColor = layout.navbarSubtitleColor
      })
    case HAS_NAVBAR:
      return produce(state, (draftState) => {
        draftState.hasNavBar = action.payload
      })
    case HAS_TABBAR:
      return produce(state, (draftState) => {
        draftState.hasTabBar = action.payload.hasTabBar
      })
    case SUBTITLE:
      return produce(state, (draftState) => {
        draftState.navbarSubtitle = action.payload.navbarSubtitle
        draftState.navbarSubtitleColor = action.payload.navbarSubtitleColor
      })
    case FOOTER_VISIBLE:
      return produce(state, (draftState) => {
        const footerVisible = action.payload.footerVisible
        draftState.footerVisible = footerVisible
      })
    case LAYOUT_TYPE:
      const type = action.payload
      if (type !== "fixed" && type !== "fluid") {
        throw new Error("Layout type must be either 'fixed' or 'fluid'")
      }
      return produce(state, (draftState) => {
        draftState.layoutType = type
      })
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

export const LayoutProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <LayoutStateContext.Provider value={state}>
      <LayoutDispatchContext.Provider value={dispatch}>
        {children}
      </LayoutDispatchContext.Provider>
    </LayoutStateContext.Provider>
  )
}

export const useLayoutContext = () => {
  const context = useContext(LayoutStateContext)
  if (context === undefined) {
    throw new Error("useLayoutContext must be used within a LayoutProvider")
  }
  return context
}

export const useLayoutDispatcher = () => {
  const context = useContext(LayoutDispatchContext)
  if (context === undefined) {
    throw new Error("useLayoutDispatcher must be used within a LayoutProvider")
  }
  return context
}
