import React from "react"

const TabBar = ({ children }) => {
  return (
    <div
      style={{
        gridTemplateColumns: "repeat(4, auto)",
        zIndex: 9, // 1 less than navbar, which hosts the sidebar, otherwise it pops in front of the sidebar!
        height: "calc(env(safe-area-inset-bottom, 0) + 3.75rem)", // Use safe area inset on mobile Safari
      }}
      className="
        fixed
        bottom-0 inset-x-0
      bg-grey-light
        shadow-tab-bar
        grid
        gap-3 tablet:gap-6
        justify-center
        pt-2.5
        transition-all duration-150 ease-in-out"
    >
      {children}
    </div>
  )
}

export default TabBar
TabBar.whyDidYouRender = true
