import { oneOf } from "prop-types"
import React, { useMemo } from "react"

const AudiobookIcon = (props) => {
  const {
    foregroundColor = "#4130f9",
    alignment = "left",
    style,
    className,
  } = props

  //
  // Align the icon using a View Box
  //
  const calculateViewBox = useMemo(() => {
    switch (alignment) {
      case "left":
        return "0 0 24 24"
      case "center":
        return "-2 0 24 24"
      case "right":
        return "-4 0 24 24"
      default:
        return "0 0 24 24"
    }
  }, [alignment])

  return (
    <div style={style ? style : {}} className={className ? className : ""}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={calculateViewBox}>
        <g fill="none" fillRule="evenodd">
          <g fill={foregroundColor} fillRule="nonzero">
            <g>
              <path
                d="M20.135.157c-.282-.183-.632-.208-.935-.065L10.286 4.86 1.37.092C1.07-.051.718-.026.436.157.164.33-.002.64 0 .974v17.408c0 .376.206.72.53.882l9.35 4.67h.032l.124.066h.5l.124-.065h.03l9.352-4.67c.323-.164.529-.507.53-.883V.974c.001-.334-.164-.644-.437-.817zm-1.278 17.501l-7.714 3.675V6.6l7.714-3.933v14.991z"
                transform="translate(-104 -358) translate(104 358)"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

AudiobookIcon.propTypes = {
  alignment: oneOf(["left", "center", "right"]),
}

export default AudiobookIcon
