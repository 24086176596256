import { getCurrencySymbol } from "helpers/currencySymbols"
import { useMemo } from "react"

/**
 * Get the sale price with currency symbol
 */
export const useSalePrice = (contentItem) => {
  const salePrice = useMemo(() => {
    const currency = contentItem?.saleCurrency
    const salePriceIncTax = contentItem?.salePriceIncTax
    if (currency && salePriceIncTax) {
      const currencySymbol = getCurrencySymbol(currency)
      return `${currencySymbol}${salePriceIncTax}`
    }
  }, [contentItem])
  return salePrice
}
