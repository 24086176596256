import { ChatlioProvider } from "context/chatlioContext"
import { CountryProvider } from "context/countryContext"
import { LayoutProvider } from "context/layoutContext"
import { ProfileProvider } from "context/profileContext"
import { ProfileHandlingProvider } from "context/profileHandlingContext"
import { ScreenProvider } from "context/screenContext"
import { ScreenFlowProvider } from "context/screenFlowContext"
import { SelectedItemProvider } from "context/selectedItemContext"
import { SetupProvider } from "context/setupContext"
import { UserProvider } from "context/userContext"
import React from "react"
import { QueryClient, QueryClientProvider } from "react-query"
// Enable React Query Dev Tools:
import { ReactQueryDevtools } from "react-query/devtools"

// Create a React Query client singleton
const queryClient = new QueryClient()

//
// A wrapper that provides context for the app
//
const ContextProviders = ({ element }) => {
  // useRerenderWarning("Context Wrapper")

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <CountryProvider>
          <ScreenProvider>
            <UserProvider>
              <SetupProvider>
                <ProfileProvider>
                  <LayoutProvider>
                    <ScreenFlowProvider>
                      <ProfileHandlingProvider>
                        <SelectedItemProvider>
                          <ChatlioProvider>{element}</ChatlioProvider>
                        </SelectedItemProvider>
                      </ProfileHandlingProvider>
                    </ScreenFlowProvider>
                  </LayoutProvider>
                </ProfileProvider>
              </SetupProvider>
            </UserProvider>
          </ScreenProvider>
        </CountryProvider>
        {/* Enable React Query Dev Tools: */}
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </>
  )
}

//
// Create a wrapper so that we can use a normal React
// component as the main app.
//
// See: gatsby-browser.js & gatsby-ssr.js
// See: https://github.com/gatsbyjs/gatsby/issues/22833#issuecomment-609370401
//
export const ContextWrapper = ({ element }) => {
  return <ContextProviders element={element} />
}
