//
// Keys for local storage
//
export const LocalStorageKey = {
  // Indicates that a profile has been set up
  HAS_PROFILE: "hasProfile",

  // A flag to indicate that a device was registered during setup
  HAS_DEVICE: "hasDevice",

  // The currently active profile
  ACTIVE_PROFILE: "activeProfile",

  // A flag to indicate that cookies have been acepted
  COOKIES_ACCEPTED: "cookiesAccepted",
}

//
// Write an object to local storage
//
export const writeToLocalStorage = (key, object) => {
  if (localStorage) {
    localStorage.setItem(key, JSON.stringify(object))
  }
}

//
// Read an object from local storage
//
export const readFromLocalStorage = (key) => {
  if (localStorage) {
    return JSON.parse(localStorage.getItem(key))
  }
}
