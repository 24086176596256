import { useSetupContext } from "context/setupContext"
import { useUserContext } from "context/userContext"
import { navigate } from "gatsby"
import { useEffect } from "react"
import routes from "services/routes"

//
// Ensure that the user has created at least one profile
// and has registered at least one player device.
//
export const useSetupEnforcer = () => {
  const { hasLoaded: isUserLoaded, userId } = useUserContext()
  const { hasProfile, hasDevice } = useSetupContext()

  useEffect(() => {
    if (!isUserLoaded || !userId) {
      // Can't enforce if there is no user yet!
      return
    }
    // Don't run the enforcer on setup-related screens!
    const currentRoute = window.location.pathname
    if (
      currentRoute === routes.RESET_PASSWORD_STEP_1 ||
      currentRoute === routes.RESET_PASSWORD_STEP_2 ||
      currentRoute === routes.SETUP_1_CONFIRMATION ||
      currentRoute === routes.SETUP_2_FOR_GROWN_UPS ||
      currentRoute === routes.SETUP_3_FOR_CHILDREN ||
      currentRoute === routes.SETUP_4_PROFILE ||
      currentRoute === routes.SETUP_5_PROFILE_AVATAR ||
      currentRoute === routes.SETUP_6_PROFILE_CREATE ||
      currentRoute === routes.SETUP_7_PROFILE_ADD ||
      currentRoute === routes.SETUP_8_DEVICE ||
      currentRoute === routes.SETUP_9_PLAYER_GET ||
      currentRoute === routes.SETUP_10_PLAYER_REGISTER ||
      currentRoute === routes.SETUP_11_GET_CONTENT ||
      currentRoute === routes.CHOOSE_AVATAR ||
      currentRoute === routes.CREATE_PROFILE ||
      currentRoute === routes.CHOOSE_PROFILE
    ) {
      return
    }
    if (hasProfile === false) {
      // Force user to create a profile
      console.warn("Forcing user to set up a profile...")
      navigate(routes.SETUP_4_PROFILE, { replace: true })
    }
    if (hasProfile === true && hasDevice === false) {
      // Force user to setup a player device
      console.warn("Forcing user to set up a device...")
      navigate(routes.SETUP_8_DEVICE, { replace: true })
    }
  })
}
