import BlueFaceAvatarIcon from "components/icons/avatars/blueFaceAvatarIcon"
import GreenFaceAvatarIcon from "components/icons/avatars/greenFaceAvatarIcon"
import OrangeFaceAvatarIcon from "components/icons/avatars/orangeFaceAvatarIcon"
import PurpleFaceAvatarIcon from "components/icons/avatars/purpleFaceAvatarIcon"
import RedFaceAvatarIcon from "components/icons/avatars/redFaceAvatarIcon"
import YellowFaceAvatarIcon from "components/icons/avatars/yellowFaceAvatarIcon"
import React from "react"

//
// Construct the avatar from the profile
//
export const constructAvatar = (avatar) => {
  switch (avatar) {
    case "blue-face-avatar":
      return <BlueFaceAvatarIcon className="w-full h-full" />
    case "green-face-avatar":
      return <GreenFaceAvatarIcon className="w-full h-full" />
    case "orange-face-avatar":
      return <OrangeFaceAvatarIcon className="w-full h-full" />
    case "purple-face-avatar":
      return <PurpleFaceAvatarIcon className="w-full h-full" />
    case "red-face-avatar":
      return <RedFaceAvatarIcon className="w-full h-full" />
    case "yellow-face-avatar":
      return <YellowFaceAvatarIcon className="w-full h-full" />

    default:
      throw new Error(`Unknown avatar type: '${avatar}'`)
  }
}
