import { useEffect, useState } from "react"

//
// Custom hook to create a "mounted" flag
// once a component has been loaded.
//
export const useMountOnLoad = () => {
  // Mount state
  const [mounted, setMounted] = useState(false)

  // Mount on load
  useEffect(() => {
    window.requestAnimationFrame(() => {
      setMounted(true)
    })

    // Cleanup function to unmount
    return function cleanup() {
      setMounted(false)
    }
  }, [])

  return mounted
}
