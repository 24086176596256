import { oneOf } from "prop-types"
import React, { useMemo } from "react"

const PodcastIcon = (props) => {
  const {
    foregroundColor = "#ff6d01",
    alignment = "left",
    style,
    className,
  } = props

  //
  // Align the icon using a View Box
  //
  const calculateViewBox = useMemo(() => {
    switch (alignment) {
      case "left":
        return "0 0 24 24"
      case "center":
        return "-5 0 24 24"
      case "right":
        return "-10 0 24 24"
      default:
        return "0 0 24 24"
    }
  }, [alignment])

  return (
    <div style={style ? style : {}} className={className ? className : ""}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={calculateViewBox}
        preserveAspectRatio="xMinYMin meet"
      >
        <g fill="none" fillRule="evenodd">
          <g fill={foregroundColor} fillRule="nonzero">
            <g>
              <path
                d="M6.999 0c-2.453 0-4.45 2.04-4.45 4.545v6.82c0 2.507 1.997 4.552 4.45 4.552 2.453 0 4.455-2.045 4.455-4.551v-6.82C11.454 2.038 9.452 0 6.999 0zm6.247 11.103c-.381.01-.692.317-.714.706C12.306 14.702 9.885 17.04 7 17.04c-2.886 0-5.302-2.338-5.527-5.23-.013-.203-.106-.39-.257-.522-.151-.132-.348-.195-.545-.176-.195.018-.375.114-.5.268-.125.153-.185.352-.167.551.267 3.424 2.939 6.21 6.265 6.57V22.5H3.727c-.025-.002-.05-.002-.076 0-.399.03-.7.38-.68.788.02.408.356.724.756.712h6.551c.266.006.515-.136.65-.37.134-.235.134-.526 0-.76-.135-.235-.384-.376-.65-.37h-2.54v-4c3.322-.364 5.99-3.146 6.258-6.569.022-.215-.048-.429-.191-.587-.144-.159-.348-.246-.559-.24z"
                transform="translate(-764 -358) translate(764 358)"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

PodcastIcon.propTypes = {
  alignment: oneOf(["left", "center", "right"]),
}

export default PodcastIcon
