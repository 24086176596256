import { useQuery } from "react-query"
import { getFirestore } from "services/firebase"

export const userAccountQueryName = "userAccountQuery"

/**
 * Query for user account details.
 */
export const userAccountQuery = async ({ queryKey }) => {
  const [, { userId }] = queryKey
  try {
    console.log("Fetching user account details...")
    const firestore = await getFirestore()
    const userSnapshot = await firestore.collection("users").doc(userId).get()
    const userData = userSnapshot.data()
    const accountDetails = {
      demoAccount: userData.demoAccount,
      reauthenticateBeforePayment:
        userData.reauthenticateBeforePayment !== undefined
          ? userData.reauthenticateBeforePayment
          : true,
    }
    return accountDetails
  } catch (error) {
    throw new Error(error)
  }
}

//
// A hook to fetch user account details
//
export const useUserAccountQuery = (userId) => {
  return useQuery([userAccountQueryName, { userId }], userAccountQuery, {
    staleTime: Infinity,
    enabled: userId !== undefined,
  })
}
