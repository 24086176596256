import { useLocation } from "@reach/router"
import Button from "components/controls/button"
import Logo from "components/icons/logo"
import NavbarLink from "components/navbar/desktop/navbarLink"
import ProfileMenu from "components/navbar/desktop/profileMenu"
import PageTitle from "components/pageTitle"
import { useCountries } from "context/countryContext"
import { useLayoutContext } from "context/layoutContext"
import { useProfileContext } from "context/profileContext"
import { useUserContext } from "context/userContext"
import { Link, navigate } from "gatsby"
import SearchIcon from "images/search-icon.svg"
import produce from "immer"
import React, { memo, useEffect, useReducer } from "react"
import routes from "services/routes"

const NavbarDesktop = memo(({ isScrolled }) => {
  // Grab the location from Reach Router for Metadata
  const location = useLocation()

  // Layout context items
  const { layoutType, navbarTitle } = useLayoutContext()

  // User state
  const { hasLoaded, userId } = useUserContext()

  // Active profile id
  const { activeProfileId } = useProfileContext()

  // Store country
  const { storeURL } = useCountries()

  // Initial state for active route
  const initialState = {
    isStoreActive: false,
    isLibraryActive: false,
    isSearchActive: false,
  }

  // Reducer for active rouote
  const ACTIVATE = "ACTIVATE"
  const DEACTIVATE = "DEACTIVATE"
  function reducer(state, action) {
    switch (action.type) {
      case ACTIVATE:
        return produce(state, (draftState) => {
          const activate = action.payload
          draftState.isStoreActive = activate.isStoreActive
            ? activate.isStoreActive
            : false
          draftState.isLibraryActive = activate.isLibraryActive
            ? activate.isLibraryActive
            : false
          draftState.isSearchActive = activate.isSearchActive
            ? activate.isSearchActive
            : false
        })
      case DEACTIVATE:
        return produce(state, (draftState) => {
          draftState.isStoreActive = false
          draftState.isLibraryActive = false
          draftState.isSearchActive = false
        })
      default:
        throw new Error(`Unhandled action type: ${action.type}`)
    }
  }

  // Reducer state for active route
  const [state, dispatch] = useReducer(reducer, initialState)

  //
  // Match current route
  //
  useEffect(() => {
    switch (location.pathname) {
      case routes.STORE(storeURL):
      case routes.STORE_FEATURED(storeURL):
      case routes.STORE_AUDIOBOOKS(storeURL):
      case routes.STORE_PODCASTS(storeURL):
      case routes.STORE_STATIONS(storeURL):
        dispatch({
          type: ACTIVATE,
          payload: { isStoreActive: true },
        })
        break
      case routes.LIBRARY:
      case routes.LIBRARY_AUDIOBOOKS:
      case routes.LIBRARY_PODCASTS:
      case routes.LIBRARY_STATIONS:
        dispatch({
          type: ACTIVATE,
          payload: { isLibraryActive: true },
        })
        break
      case routes.STORE_SEARCH(storeURL):
        dispatch({
          type: ACTIVATE,
          payload: { isSearchActive: true },
        })
        break
      default:
        dispatch({
          type: DEACTIVATE,
        })
        break
    }
  }, [location.pathname, storeURL])

  return (
    <div
      className={`
        ${
          layoutType === "fixed"
            ? `
              laptop:mx-l-offsets-fixed
              desktop:mx-d-offsets-fixed
              `
            : ""
        }      
        ${
          layoutType === "fluid"
            ? `
              laptop:mx-l-offsets-fluid
              desktop:mx-d-offsets-fluid
              `
            : ""
        }
        grid grid-cols-3
        items-center
        h-full
      `}
    >
      {/* Left-aligned items */}
      <div className="flex items-center">
        {navbarTitle && (
          <PageTitle
            title={navbarTitle}
            isNavbar={true}
            style={{
              visibility: navbarTitle && isScrolled ? "visible" : "hidden",
            }}
            className="absolute"
          />
        )}

        {/* Logo link */}
        <Link
          to="/"
          style={{
            visibility: navbarTitle && isScrolled ? "hidden" : "visible",
          }}
          className="absolute"
        >
          <Logo
            className="
            laptop:w-22 desktop:w-22
            laptop:h-11"
          />
        </Link>
      </div>

      {/* Center-aligned items */}
      <div
        style={{
          opacity: navbarTitle && isScrolled ? 0 : 100,
        }}
        className="
          flex flex-row items-center justify-center
          transition-opacity duration-100 ease-in-out"
      >
        {/* Store link */}
        <NavbarLink
          text="Store"
          to={routes.STORE(storeURL)}
          isActive={state.isStoreActive}
          onClick={() => {
            dispatch({
              type: ACTIVATE,
              payload: { isStoreActive: true },
            })
          }}
        />

        {/* Library link */}
        <NavbarLink
          text="Library"
          to={routes.LIBRARY}
          isActive={state.isLibraryActive}
          onClick={() => {
            dispatch({
              type: ACTIVATE,
              payload: { isLibraryActive: true },
            })
          }}
        />

        {/* Search link */}
        <NavbarLink
          to={routes.STORE_SEARCH(storeURL)}
          isActive={state.isSearchActive}
          onClick={() => {
            dispatch({
              type: ACTIVATE,
              payload: { isSearchActive: true },
            })
          }}
        >
          <SearchIcon className="h-4 w-4" />
        </NavbarLink>
      </div>

      {/* Right-aligned items */}
      <div className="flex flex-row items-center justify-end">
        {/* Auth buttons */}
        {hasLoaded && !userId && (
          <div className="flex pt-1">
            <Button
              text="Sign in"
              flat={true}
              size="sm"
              shadowHeight={3}
              onClick={() => {
                navigate(routes.SIGN_IN)
              }}
              className="w-22"
            />
            <Button
              type="primary"
              text="Sign up"
              size="sm"
              shadowHeight={3}
              onClick={() => {
                navigate(routes.SIGN_UP)
              }}
              className="w-22 ml-2"
            />
          </div>
        )}

        {/* Profile menu */}
        {userId && activeProfileId && <ProfileMenu className="-mt-0.5" />}
      </div>
    </div>
  )
})

export default NavbarDesktop
NavbarDesktop.whyDidYouRender = true
