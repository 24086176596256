import {
  LocalStorageKey,
  readFromLocalStorage,
  writeToLocalStorage,
} from "helpers/localStorageHelper"
import produce from "immer"
import * as React from "react"
import { createContext, useContext, useEffect, useReducer } from "react"
import { getAuth } from "services/auth"
import { getFirestore } from "services/firebase"

//
// A context provider that stores state about setup process.
//
export const SetupStateContext = createContext()
export const SetupDispatchContext = createContext()

const initialState = {
  hasProfile: undefined,
  hasDevice: undefined,
  setupDevice: undefined,
  playerDevice: undefined,
  isSameDevice: undefined, // Is the setup and player device one and the same?
}

// const NO_USER = "NO_USER"
const HAS_PROFILE = "HAS_PROFILE"
const HAS_DEVICE = "HAS_DEVICE"
const SETUP_DEVICE = "SETUP_DEVICE"
export const PLAYER_DEVICE = "PLAYER_DEVICE"

//
// Types of setup device
//
export const DeviceType = {
  IPHONE: "iPhone",
  IPAD: "iPad",
  OTHER: "other",
}

const reducer = (state, action) => {
  switch (action.type) {
    case SETUP_DEVICE:
      return produce(state, (draftState) => {
        draftState.setupDevice = action.payload.setupDevice
      })
    case PLAYER_DEVICE:
      return produce(state, (draftState) => {
        draftState.playerDevice = action.payload.playerDevice
        draftState.isSameDevice = action.payload.isSameDevice
      })
    case HAS_PROFILE:
      return produce(state, (draftState) => {
        draftState.hasProfile = action.payload.hasProfile
      })
    case HAS_DEVICE:
      return produce(state, (draftState) => {
        draftState.hasDevice = action.payload.hasDevice
      })
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

export const SetupProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  // Listen for changes to auth state in Firebase...
  useEffect(() => {
    getAuth().then((auth) => {
      // Listen to auth changes
      auth.onAuthStateChanged((user) => {
        // Handle user change
        handleUserChanged(user, dispatch)
      })
    })
  }, [])

  //
  // Determine the type of the setup device
  //
  useEffect(() => {
    // Test for iPhone
    // See: https://racase.com.np/javascript-how-to-detect-if-device-is-ios/
    const is_iPhone =
      (/iPhone/.test(navigator.userAgent) && !window.MSStream) === true

    // See: https://stackoverflow.com/a/62980873/2359400
    const is_iPad =
      ((/iPad/.test(navigator.userAgent) && !window.MSStream) ===
        true /* iOS pre 13 */ ||
        (navigator.platform === "MacIntel" &&
          navigator.maxTouchPoints > 1)) /* iPad OS 13 */ === true

    let setupDevice
    if (is_iPhone) {
      setupDevice = DeviceType.IPHONE
    } else if (is_iPad) {
      setupDevice = DeviceType.IPAD
    } else {
      setupDevice = DeviceType.OTHER
    }

    // Set setup device type
    dispatch({
      type: SETUP_DEVICE,
      payload: {
        setupDevice: setupDevice,
      },
    })
  }, [])

  return (
    <SetupStateContext.Provider value={state}>
      <SetupDispatchContext.Provider value={dispatch}>
        {children}
      </SetupDispatchContext.Provider>
    </SetupStateContext.Provider>
  )
}

//
// Handle a new Firebase user
//
const handleUserChanged = (user, dispatch) => {
  if (user) {
    // Keep listening until a device has been registered
    listenForDeviceRegistration(user, dispatch)

    // Keep listening until a profile has been created
    listenForProfileCreation(user, dispatch)
  }
}

//
// Keep listening until a device has been registered
//
const listenForDeviceRegistration = async (user, dispatch) => {
  // Try looking in local storage first, to prevent a remote lookup
  const deviceRegistered = readFromLocalStorage(LocalStorageKey.HAS_DEVICE)
  if (deviceRegistered === true) {
    // Found a device
    dispatch({
      type: HAS_DEVICE,
      payload: {
        // isLoaded: true,
        hasDevice: true,
      },
    })
  } else {
    // Do a remote lookup and listen...
    const firestore = await getFirestore()
    const unsubscribeDeviceListener = firestore
      .collection("users")
      .doc(user.uid)
      .collection("devices")
      .onSnapshot((snapshot) => {
        if (snapshot.empty) {
          // No device found
          dispatch({
            type: HAS_DEVICE,
            payload: {
              hasDevice: false,
            },
          })
          console.warn("No devices have been registered yet!")
        } else {
          // Found a device
          dispatch({
            type: HAS_DEVICE,
            payload: {
              hasDevice: true,
            },
          })
          // Update local storage to avoid this check in the future
          writeToLocalStorage(LocalStorageKey.HAS_DEVICE, true)
          // Finished listening
          unsubscribeDeviceListener()
        }
      })
  }
}

//
// Keep listening until a profile has been created
//
const listenForProfileCreation = async (user, dispatch) => {
  // Try looking in local storage first, to prevent a remote lookup
  const hasProfile = readFromLocalStorage(LocalStorageKey.HAS_PROFILE)
  if (hasProfile) {
    // Mark profile as found
    dispatch({
      type: HAS_PROFILE,
      payload: {
        hasProfile: true,
      },
    })
  } else {
    // Look up profiles remotely
    const firestore = await getFirestore()
    const unsubscribeProfileListener = firestore
      .collection("users")
      .doc(user.uid)
      .collection("profiles")
      .onSnapshot((snapshot) => {
        if (snapshot.empty) {
          // Mark profile as missing
          dispatch({
            type: HAS_PROFILE,
            payload: {
              hasProfile: false,
            },
          })
          console.warn("No profiles have been set up yet!")
        } else {
          // Mark profile as present
          dispatch({
            type: HAS_PROFILE,
            payload: {
              hasProfile: true,
            },
          })
          // Update local storage to avoid this check in the future
          writeToLocalStorage(LocalStorageKey.HAS_PROFILE, true)
          // We can stop listening to profiles now
          unsubscribeProfileListener()
        }
      })
  }
}

export const useSetupContext = () => {
  const context = useContext(SetupStateContext)
  if (context === undefined) {
    throw new Error("useSetupContext must be used within a SetupProvider")
  }
  return context
}

export const useSetupDispatcher = () => {
  const context = useContext(SetupDispatchContext)
  if (context === undefined) {
    throw new Error("useSetupDispatcher must be used within a SetupProvider")
  }
  return context
}
