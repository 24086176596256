//
// Content types
//
export const ContentType = {
  STATION: "station",
  PODCAST: "podcast",
  AUDIOBOOK: "audiobook",
}

//
// Content type colours
//
export const ContentTypeColours = {
  STATION: "red",
  PODCAST: "orange",
  AUDIOBOOK: "blue",
}

//
// Get the colour for a content type
//
export const getColourForContentItem = (contentItem) => {
  switch (contentItem?.type) {
    case ContentType.AUDIOBOOK:
      return ContentTypeColours.AUDIOBOOK
    case ContentType.PODCAST:
      return ContentTypeColours.PODCAST
    case ContentType.STATION:
      return ContentTypeColours.STATION
    default:
      return "grey"
  }
}
