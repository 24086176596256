import "lazysizes"
import "lazysizes/plugins/attrchange/ls.attrchange"
import "lazysizes/plugins/blur-up/ls.blur-up"
import PropTypes from "prop-types"
import React from "react"
import Imgix from "react-imgix"
import { useMediaQuery } from "react-responsive"
import { useMediaQueries } from "hooks/useMediaQueries"

//
// An image loaded remotely from the Imgix service,
// with an array of responsive sizes.
//
// Supply width/height if you know the exact dimonesions of the image
// or use the responsive sizes instead.
//
const RemoteImage = ({
  imagePath,
  width,
  height,
  aspectRatio, // Expressed as a ratio, e.g. "1:1"
  fit = "fill",
  mobileImageSize,
  tabletImageSize,
  laptopImageSize,
  desktopImageSize,
  backgroundColor, // = "0FFF", // See: https://docs.imgix.com/apis/rendering/fill/bg
  style,
  className,
}) => {
  //  Base path for image
  const basePath = `https://superfonik.imgix.net/${imagePath}`

  // Test for high-DPI screens
  const isRetina = useMediaQuery({ minResolution: "2dppx" })

  // Media Queries hook
  const { isMobile, isTablet, isLaptop, isDesktop } = useMediaQueries()

  //
  // Build the image source URL with parameters
  //
  const buildSrc = () => {
    let params = ""
    if (backgroundColor) {
      params += `&bg=${backgroundColor}`
    }
    if (fit) {
      params += `&fit=${aspectRatio ? "crop" : fit}` // See: https://docs.imgix.com/apis/rendering/size/ar
    }
    if (aspectRatio) {
      params += `&ar=${aspectRatio}` // See: https://docs.imgix.com/apis/rendering/size/ar
    }
    if (params !== "") {
      return `${basePath}?${params.slice(1)}`
    } else {
      return basePath
    }
  }

  //
  // Build the image placeholder source, using media queries.
  // Start by trying to match the current breakpoint to the
  // supplied breakpoint image size, otherwise fallback
  // from largest to smallest breakpoints.
  //
  // NOTE: Ideally, supply image sizes for all breakpoints!
  //
  const buildPlaceholderSrc = () => {
    let width
    if (isMobile && mobileImageSize) {
      width = mobileImageSize
    } else if (isTablet && tabletImageSize) {
      width = tabletImageSize
    } else if (isLaptop && laptopImageSize) {
      width = laptopImageSize
    } else if (isDesktop && desktopImageSize) {
      width = desktopImageSize
    } else {
      if (desktopImageSize) {
        width = desktopImageSize
      } else if (laptopImageSize) {
        width = laptopImageSize
      } else if (tabletImageSize) {
        width = tabletImageSize
      } else if (mobileImageSize) {
        width = mobileImageSize
      }
    }
    return `${buildSrc()}&blur=200&px=16&w=${width}&dpr=${isRetina ? 2 : 1}`
  }

  if (width || height) {
    return (
      <Imgix
        src={buildSrc()}
        width={width}
        height={height}
        className={className}
      />
    )
  } else if (
    mobileImageSize ||
    tabletImageSize ||
    laptopImageSize ||
    desktopImageSize
  ) {
    const sizes = `
      ${desktopImageSize ? `(min-width: 1440px) ${desktopImageSize}px, ` : ""}
      ${laptopImageSize ? `(min-width: 1024px) ${laptopImageSize}px, ` : ""}
      ${tabletImageSize ? `(min-width: 768px) ${tabletImageSize}px, ` : ""}
      ${mobileImageSize ? `${mobileImageSize}px` : ""}
      `
    return (
      <Imgix
        src={buildSrc()}
        sizes={sizes}
        className={className ? `lazyload ${className}` : "lazyload"}
        // See: https://docs.imgix.com/libraries/react-imgix#lazy-loading
        attributeConfig={{
          src: "data-src",
          srcSet: "data-srcset",
          sizes: "data-sizes",
        }}
        // See:  https://docs.imgix.com/libraries/react-imgix#low-quality-image-placeholder-technique-lqip
        htmlAttributes={{
          src: `${buildPlaceholderSrc()}`,
          style: style ? style : {},
          // TODO.  See: https://github.com/aFarkas/lazysizes/issues/850
          // "data-lowsrc": `https://superfonik.imgix.net/${imagePath}?auto=format&blur=200&px=16&w=150`,
        }}
      />
    )
  } else {
    return (
      <Imgix
        src={`https://superfonik.imgix.net/${imagePath}?bg=${backgroundColor}`}
        className={className}
      />
    )
  }
}

RemoteImage.propTypes = {
  imagePath: PropTypes.any,
}

export default RemoteImage
