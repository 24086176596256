import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import BreakpointContext from "context/breakpointContext"
import { useChatlioContext } from "context/chatlioContext"
import { useProfileContext } from "context/profileContext"
import { useUserContext } from "context/userContext"
import { size } from "helpers/responsiveSize"
import React, { useContext } from "react"
import Button from "../controls/button"

const FooterChatButton = () => {
  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  // Check Chatlio online status
  const { isReady, isOnline } = useChatlioContext()

  // User context
  const { userId, email } = useUserContext()

  // Profile context
  const { activeProfileName } = useProfileContext()

  return (
    <Button
      colorBackground={true}
      shadowHeight={3}
      disabled={!isReady}
      style={{
        width: size(bpt, "18vw", "10vw", "9vw", "8vw"),
        height: size(bpt, "5vw", "3vw", "2.5vw", "2.25vw"),
      }}
      onClick={() => {
        // Update current page for context
        window._chatlio?.page()

        // Set identification context
        window._chatlio?.identify(userId, {
          email: email,
          profile: activeProfileName,
        })

        // Show chat widget
        window._chatlio?.showOrHide()
      }}
    >
      <FontAwesomeIcon
        icon={["fa", "comment"]}
        className={`
          ${isOnline ? "text-green" : "text-white"}
          mr-1.5
          -mt-0.5
        `}
      />
      <span className="font-medium">Chat</span>
    </Button>
  )
}

export default FooterChatButton
