import FooterChatButton from "components/chat/footerChatButton"
import Logo from "components/icons/logo"
import BreakpointContext from "context/breakpointContext"
import { Link } from "gatsby"
import { size } from "helpers/responsiveSize"
import React, { memo, useContext } from "react"

const Footer = memo(({ style, className }) => {
  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  return (
    <footer
      className={`${className ? className : ""} text-grey-light text-sm`}
      style={style ? style : {}}
    >
      <div
        className="
          grid
          py-10
          bg-blue-darker
          shadow-footer
          pb-16
          pr-6 tablet:pr-20 laptop:pr-24 desktop:pr-32
          pl-5 tablet:pl-10 laptop:pl-14
          text-xs tablet:text-sm laptop:text-base desktop:text-lg"
        style={{
          gridTemplateColumns: "1fr auto",
        }}
      >
        {/* Logo */}
        <Link to="/">
          <Logo
            color="white"
            style={{
              width: size(bpt, "20vw", "14vw", "14vw", "14vw"),
            }}
            className="select-none"
          />
        </Link>

        <div
          className="
            grid 
            grid-rows-4
            grid-flow-col
            gap-y-2
            gap-x-10"
        >
          <div className="font-extrabold select-none whitespace-nowrap">
            Customer service
          </div>
          <FooterChatButton />
          <div>
            <a href="mailto:support@superfonik.com">Email us</a>
          </div>
          <div>
            <Link to="/privacy">Privacy</Link>
          </div>
          <div className="font-extrabold select-none whitespace-nowrap">
            Stay tuned
          </div>
          <a
            href="https://www.instagram.com/superfonik/"
            target="_blank"
            rel="noreferrer"
          >
            Instagram
          </a>
          <a
            href="https://www.facebook.com/superfonik"
            target="_blank"
            rel="noreferrer"
          >
            Facebook
          </a>
          <a
            href="https://twitter.com/superfonik"
            target="_blank"
            rel="noreferrer"
          >
            Twitter
          </a>
        </div>

        {/* <div className="grid auto-rows-min gap-2 tablet:gap-2">
          <span className="font-extrabold select-none">Customer service</span>
          <FooterChatButton />
          <a href="mailto:support@superfonik.com">Email us</a>
          <Link to="/privacy">Privacy</Link>
        </div>

        <div className="grid auto-rows-min gap-2 tablet:gap-2">
          <span className="font-extrabold select-none">Stay tuned</span>
          <a
            href="https://www.instagram.com/superfonik/"
            target="_blank"
            rel="noreferrer"
          >
            Instagram
          </a>
          <a
            href="https://www.facebook.com/superfonik"
            target="_blank"
            rel="noreferrer"
          >
            Facebook
          </a>
          <a
            href="https://twitter.com/superfonik"
            target="_blank"
            rel="noreferrer"
          >
            Twitter
          </a>
        </div> */}
      </div>

      {/* Trademark */}
      <div
        className="
          flex flex-wrap place-content-center text-grey-light font-extrabold bg-blue-darkest
          text-xs tablet:text-sm laptop:text-base desktop:text-lg
          h-12 tablet:h-14 laptop:h-16 desktop:h-14"
        style={{
          lineHeight: 0,
        }}
      >
        superfonik<sup>&reg;</sup>
      </div>
    </footer>
  )
})

export default Footer
Footer.whyDidYouRender = true
