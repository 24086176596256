import { useQuery } from "react-query"
import { getFirestore } from "services/firebase"

export const paymentDetailsQueryName = "paymentDetailsQuery"

/**
 * Query the payment details in a user account.
 */
export const paymentDetailsQuery = async ({ queryKey }) => {
  const [, { userId }] = queryKey
  try {
    console.log("Loading payment details...")
    const firestore = await getFirestore()
    const userSnapshot = await firestore.collection("users").doc(userId).get()
    const userData = userSnapshot.data()
    return userData.paymentDetails
  } catch (error) {
    throw new Error(error)
  }
}

//
// A hook to use payment details by querying for them
//
export const usePaymentDetailsQuery = (userId) => {
  return useQuery([paymentDetailsQueryName, { userId }], paymentDetailsQuery, {
    staleTime: Infinity,
    enabled: userId !== undefined,
  })
}
