import Button from "components/controls/button"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

//
// Modal dialog
//
// Supply Cancel/OK handlers to enable the associated buttons.
//
const ModalDialog = (props) => {
  const {
    heading,
    message,
    primaryButtonText = "Okay",
    secondaryButtonText = "Cancel",
    onPrimaryButtonClicked,
    onSecondaryButtonClicked,
    isPrimaryButtonDisabled = false,
    isSecondaryButtonDisabled = false,
    areButtonsVisible = true,
    isWaiting = false, // Useful for async actions
    width = "w-dialog-xs tablet:w-dialog-sm laptop:w-dialog-md desktop:w-dialog-lg",
  } = props

  // Loading state
  const [loaded, setLoaded] = useState(false)

  // State of visibility
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (!loaded) {
      setLoaded(true)
      setTimeout(() => {
        setVisible(true)
      }, 10)
    }
  }, [loaded])

  return (
    <>
      {/* Blackdrop */}
      <div
        className={`
          fixed
          inset-0
          bg-black
          z-40
          transition-opacity duration-300 ease-in-out
          ${visible ? "opacity-80" : "opacity-0"}
        `}
      />

      <div
        className="
          fixed 
          inset-0 
          z-50 
          flex 
          items-center
          justify-center"
      >
        {/* Container */}
        <div
          className={`
            rounded-3xl
            shadow-dialog
            overflow-hidden
            border border-grey
            bg-grey-light
            ${width}
          `}
        >
          {/* Content */}
          <div className="h-full text-sm tablet:text-base">
            {heading && (
              <p
                className="
                  bg-grey-dark
                  font-bold 
                  text-lg tablet:text-xl
                  text-white
                  mb-3
                  whitespace-nowrap 
                  px-6
                  py-3 tablet:py-4
                  shadow-md"
              >
                {heading}
              </p>
            )}
            <div className="px-6 py-3">{message}</div>
          </div>

          {areButtonsVisible && (
            <div className="flex flex-col tablet:flex-row mt-2 px-6 mb-6">
              {/* Secondary button */}
              {onSecondaryButtonClicked && (
                <Button
                  type="secondary"
                  text={secondaryButtonText}
                  disabled={isSecondaryButtonDisabled}
                  onClick={() => {
                    onSecondaryButtonClicked()
                  }}
                  className={`
                  w-full
                  mb-3 tablet:mb-0
                  ${onPrimaryButtonClicked ? "tablet:mr-3" : ""}
                `}
                />
              )}
              {/* Primary button */}
              {onPrimaryButtonClicked && (
                <Button
                  type="primary"
                  text={primaryButtonText}
                  disabled={isPrimaryButtonDisabled}
                  loading={isWaiting}
                  onClick={() => {
                    onPrimaryButtonClicked()
                  }}
                  className={`
                    w-full mt-1 tablet:mt-0
                    ${onSecondaryButtonClicked ? "tablet:ml-3" : ""}
                  `}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

ModalDialog.propTypes = {
  heading: PropTypes.string,
  message: PropTypes.object,
  primaryButtonText: PropTypes.string,
  secondaryButtonText: PropTypes.string,
  onPrimaryButtonClicked: PropTypes.func.isRequired,
  onSecondaryButtonClicked: PropTypes.func,
  isPrimaryButtonDisabled: PropTypes.bool,
  isSecondaryButtonDisabled: PropTypes.bool,
  areButtonsVisible: PropTypes.bool,
  isWaiting: PropTypes.bool,
  width: PropTypes.string,
}

export default ModalDialog
