import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "components/controls/button"
import BreakpointContext from "context/breakpointContext"
import { size } from "helpers/responsiveSize"
import React, { useContext } from "react"

//
// Button to close card detail
//
const CloseButton = (props) => {
  const { onClick } = props

  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  return (
    <div
      style={{
        top: size(bpt, "4.5vw", "3.9vw", "2.2vw", "1.7vw"),
        right: size(bpt, "5vw", "3.6vw", "2.2vw", "1.7vw"),
        height: size(bpt, "7.5vw", "5vw", "4vw", "2.8vw"),
        width: size(bpt, "7.5vw", "5vw", "4vw", "2.8vw"),
      }}
      className="
        absolute
        tablet:top-10
        tablet:right-10
        border border-grey-light
        shadow-inner-grey-light
        z-10
        rounded-full
      "
    >
      <div
        style={{
          boxShadow:
            "inset 5px 5px 10px rgba(0, 0, 0, 0.2), inset -5px -5px 10px rgba(255, 255, 255, 0.6)",
          backgroundBlendMode: "soft-light, normal",
          backgroundImage:
            "linear-gradient(318deg, #000000 100%, #ffffff -5%), linear-gradient(to bottom, #ebecf0, #ebecf0)",
        }}
        className="              
          absolute
          inset-0
          rounded-full"
      >
        <Button
          flat={true}
          className="h-full w-full"
          onClick={onClick}
          onKeyDown={onClick}
          icon={
            <FontAwesomeIcon
              icon="times"
              style={{
                width: size(bpt, "5vw", "3.8vw", "3vw", "2vw"),
                height: size(bpt, "5vw", "3.8vw", "3vw", "2vw"),
              }}
            />
          }
        />
      </div>
    </div>
  )
}

export default CloseButton
