import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useLocation } from "@reach/router"
import AudiobookIcon from "components/icons/contentTypes/audiobookIcon"
import PodcastIcon from "components/icons/contentTypes/podcastIcon"
import StationIcon from "components/icons/contentTypes/stationIcon"
import { SubtitleColours, Subtitles } from "components/navbar/navbar"
import { useCountries } from "context/countryContext"
import React from "react"
import routes from "services/routes"
import Subnav from "./subnav"
import SubnavItem from "./subnavItem"

//
// Subnav filter for the Store
//
// Whitelist classes for PurgeCSS
// (See: https://www.viget.com/articles/a-better-approach-for-using-purgecss-with-tailwind/)
//
// text-green text-blue text-red text-orange text-purple bg-purple
//
const StoreFilter = ({ className }) => {
  // Grab the location from Reach Router
  const location = useLocation()

  // Store country
  const { storeURL } = useCountries()

  return (
    <Subnav className={className}>
      {/* Featured */}
      <SubnavItem
        text={Subtitles.FEATURED}
        to={routes.STORE_FEATURED(storeURL)}
        icon={
          <FontAwesomeIcon
            icon={["fa", "store"]}
            fixedWidth={true}
            style={{
              width: "1.1vw",
              height: "1.1vw",
              marginRight: "0.3vw",
            }}
          />
        }
        color={SubtitleColours.FEATURED}
        active={location.pathname === routes.STORE_FEATURED(storeURL)}
      />

      {/* Audiobooks */}
      <SubnavItem
        text={Subtitles.AUDIOBOOKS}
        to={routes.STORE_AUDIOBOOKS(storeURL)}
        icon={
          <AudiobookIcon
            style={{
              width: "1vw",
              height: "1vw",
              marginRight: "0.3vw",
            }}
          />
        }
        color={SubtitleColours.AUDIOBOOKS}
        active={location.pathname.includes(routes.STORE_AUDIOBOOKS(storeURL))}
      />

      {/* Podcasts */}
      <SubnavItem
        text={Subtitles.PODCASTS}
        to={routes.STORE_PODCASTS(storeURL)}
        icon={
          <PodcastIcon
            style={{
              width: "1vw",
              height: "1vw",
            }}
          />
        }
        color={SubtitleColours.PODCASTS}
        active={location.pathname.includes(routes.STORE_PODCASTS(storeURL))}
      />

      {/* Radio Stations */}
      <SubnavItem
        text={Subtitles.STATIONS}
        to={routes.STORE_STATIONS(storeURL)}
        icon={
          <StationIcon
            style={{
              width: "1vw",
              height: "1vw",
            }}
          />
        }
        color={SubtitleColours.STATIONS}
        active={location.pathname.includes(routes.STORE_STATIONS(storeURL))}
      />
    </Subnav>
  )
}

export default StoreFilter
