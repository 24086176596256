import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useLocation } from "@reach/router"
import AudiobookIcon from "components/icons/contentTypes/audiobookIcon"
import PodcastIcon from "components/icons/contentTypes/podcastIcon"
import StationIcon from "components/icons/contentTypes/stationIcon"
import { SubtitleColours, Subtitles } from "components/navbar/navbar"
import React from "react"
import routes from "services/routes"
import Subnav from "./subnav"
import SubnavItem from "./subnavItem"

//
// The Subnav filter for the Library
//
const LibraryFilter = ({ className }) => {
  // Grab the location from Reach Router
  const location = useLocation()

  return (
    <Subnav className={className ? className : ""}>
      {/* All items */}
      <SubnavItem
        text={Subtitles.ALL_ITEMS}
        to={routes.LIBRARY}
        icon={
          <FontAwesomeIcon
            icon={["fa", "grip-vertical"]}
            fixedWidth={true}
            style={{
              width: "1.1vw",
              height: "1.1vw",
              marginBottom: "0.1rem",
              marginRight: "0.3vw",
            }}
          />
        }
        color={SubtitleColours.ALL_ITEMS}
        active={location.pathname === routes.LIBRARY}
      />

      {/* Audiobooks */}
      <SubnavItem
        text={Subtitles.AUDIOBOOKS}
        to={routes.LIBRARY_AUDIOBOOKS}
        icon={
          <AudiobookIcon
            style={{
              width: "1vw",
              height: "1vw",
              marginRight: "0.3vw",
            }}
          />
        }
        color={SubtitleColours.AUDIOBOOKS}
        active={location.pathname === routes.LIBRARY_AUDIOBOOKS}
      />

      {/* Podcasts */}
      <SubnavItem
        text={Subtitles.PODCASTS}
        to={routes.LIBRARY_PODCASTS}
        icon={
          <PodcastIcon
            style={{
              width: "1vw",
              height: "1vw",
            }}
          />
        }
        color={SubtitleColours.PODCASTS}
        active={location.pathname === routes.LIBRARY_PODCASTS}
      />

      {/* Stations */}
      <SubnavItem
        text={Subtitles.STATIONS}
        to={routes.LIBRARY_STATIONS}
        icon={
          <StationIcon
            style={{
              width: "1vw",
              height: "1vw",
            }}
          />
        }
        color={SubtitleColours.STATIONS}
        active={location.pathname === routes.LIBRARY_STATIONS}
      />
    </Subnav>
  )
}

export default LibraryFilter
