import BreakpointContext from "context/breakpointContext"
import { useLayoutContext } from "context/layoutContext"
import { h1Responsive, h2Responsive } from "helpers/headingSize"
import { size } from "helpers/responsiveSize"
import { useMediaQueries } from "hooks/useMediaQueries"
import PropTypes from "prop-types"
import React, { useContext, useMemo } from "react"

const PageTitle = ({
  title,
  isResponsive = false,
  isNavbar = false,
  style,
  className,
}) => {
  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  // Media queries
  const { isMobile, isTouchDevice } = useMediaQueries()

  // Grab the subtitle from the layout context
  const { navbarSubtitle, navbarSubtitleColor } = useLayoutContext()

  //
  // Determine if this is a short subtitle
  // that can fit next to the title or should be
  // placed on the next line for mobile devices.
  //
  const hasSubtitleInline = useMemo(() => {
    // Anything bigger than a mobile is always inline!
    if (!isMobile) {
      return true
    }
    return navbarSubtitle?.length <= 10
  }, [navbarSubtitle, isMobile])

  //
  // Determine if we should show the subtitle at all
  //
  const shouldShowSubtitle = useMemo(() => {
    // Don't try to display a long subtitle in the navbar of a mobile device
    if (isNavbar && isMobile && !hasSubtitleInline) {
      return false
    }
    // Show a subtitle for everything else
    return true
  }, [isNavbar, isMobile, hasSubtitleInline])

  //
  // Determine whether the title and subtitle should use responsive sizing
  //
  const useResponsiveSizing = useMemo(() => {
    // Use fixed sizing in the navbar
    if (isNavbar) {
      return false
    }
    // Specified as being responsive
    if (isResponsive) {
      return true
    } else {
      return false
    }
  }, [isResponsive, isNavbar])

  const scrollToTop = () => {
    if (!isTouchDevice) {
      return
    }
    window.scrollTo(0, 0)
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      role="link"
      tabIndex={-1}
      onClick={scrollToTop}
      style={style ? style : {}}
      className={`
        flex
        ${hasSubtitleInline ? "flex-row" : "flex-col"}
        items-baseline
        ${className ? className : ""}
      `}
    >
      {/* Title */}
      <p
        style={{
          fontSize: useResponsiveSizing ? h1Responsive(bpt) : undefined,
        }}
        className={`
          font-bold
          leading-none
          select-none 
          tracking-tight tablet:tracking-normal
          ${
            !useResponsiveSizing
              ? "text-3xl tablet:text-4xl laptop:text-4xl desktop:text-5xl"
              : ""
          }
      `}
      >
        {title}
      </p>

      {/* Subtitle */}
      {navbarSubtitle && shouldShowSubtitle && (
        <p
          style={{
            fontSize: useResponsiveSizing ? h2Responsive(bpt) : undefined,
            marginLeft: hasSubtitleInline
              ? size(bpt, "2.5vw", "1.5vw", "1.1vw", "1.1vw")
              : undefined,
            marginTop: hasSubtitleInline ? undefined : "2vw",
          }}
          className={`
            font-medium
            leading-tight
            select-none
            tracking-normal
            ${hasSubtitleInline ? "line-clamp-1" : "line-clamp-2"}
            text-${navbarSubtitleColor ? navbarSubtitleColor : "grey-dark"}
             ${
               !useResponsiveSizing
                 ? "text-xl tablet:text-2.5xl desktop:text-4xl"
                 : ""
             }
          `}
        >
          {navbarSubtitle}
        </p>
      )}
    </div>
  )
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
}

export default PageTitle
