//
// Common Station attributes
//
const StationAttributes = (type = "Station") => `
  fragment StationAttributes on ${type} {
    type
    id
    ref
    title
    description
    artwork
    website
    location
  }
`

//
// Use CommonJS syntax so that we can require this in gatsby-node.js
// See: https://www.samdawson.dev/article/gatsby-node-sharing-code
//
module.exports = {
  StationAttributes: StationAttributes,
}
