import { useQuery } from "react-query"
import { getFirestore } from "services/firebase"

export const purchasesQueryName = "purchasesQuery"

//
// Query for the purchases in an account
//
export const purchasesQuery = async ({ queryKey }) => {
  const [, { userId }] = queryKey
  try {
    const firestore = await getFirestore()
    const purchasesRef = firestore
      .collection("users")
      .doc(userId)
      .collection("purchases")
    const purchasesSnapshot = await purchasesRef.get()
    return purchasesSnapshot.docs.map((doc) => {
      const purchase = doc.data()
      return {
        id: doc.id,
        ref: purchase.ref.path,
        amount: purchase.amount,
        currency: purchase.currency,
        chargedAt: purchase.chargedAt,
      }
    })
  } catch (error) {
    throw new Error(error)
  }
}

//
// A hook to use purchases by querying for them.
//
export const usePurchasesQuery = (userId) => {
  return useQuery([purchasesQueryName, { userId }], purchasesQuery, {
    staleTime: Infinity,
    enabled: userId !== undefined,
  })
}
