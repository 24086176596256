import { LAYOUT, useLayoutDispatcher } from "context/layoutContext"
import { useEffect } from "react"

//
// Custom hook to enable/disable the navbar for a page
//
export const useNavBar = ({
  hasNavBar = false,
  layoutType = "fluid",
  navbarTitle = undefined,
  navbarSubtitle = undefined,
  navbarSubtitleColor = undefined,
}) => {
  const dispatch = useLayoutDispatcher()

  useEffect(() => {
    dispatch({
      type: LAYOUT,
      payload: {
        layoutType: layoutType,
        hasNavBar: hasNavBar,
        navbarTitle: navbarTitle,
        navbarSubtitle: navbarSubtitle,
        navbarSubtitleColor: navbarSubtitleColor,
      },
    })
  }, [
    hasNavBar,
    layoutType,
    navbarTitle,
    navbarSubtitle,
    navbarSubtitleColor,
    dispatch,
  ])
}
