import React from "react"

const RedFaceAvatarIcon = (props) => {
  const { style, className } = props

  return (
    <div style={style ? style : {}} className={className ? className : ""}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="15 15 140 140"
      >
        <defs>
          <linearGradient
            id="br96ib52ac"
            x1="100%"
            x2="0%"
            y1="100%"
            y2="-9.901%"
          >
            <stop offset="0%" />
            <stop offset="100%" stopColor="#FFF" />
          </linearGradient>
          <rect id="i9bf6gt92b" width="140" height="140" x="0" y="0" rx="70" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <g>
            <g>
              <g transform="translate(-1046 -391) translate(1061 406)">
                <use
                  fill="#000"
                  filter="url(#syihft2cla)"
                  xlinkHref="#i9bf6gt92b"
                />
                <use fill="#FF005C" xlinkHref="#i9bf6gt92b" />
                <use
                  fill="url(#br96ib52ac)"
                  fillOpacity=".4"
                  style={{ mixBlendMode: "soft-light" }}
                  xlinkHref="#i9bf6gt92b"
                />
              </g>
              <g fill="#660024">
                <path
                  d="M.8 59.384c1.203 1.623 3.401 2.098 5.134 1.083 7.544-4.438 28.506-14.103 49.914-.181 1.693 1.1 3.916.785 5.114-.846l.307-.41c1.31-1.777.797-4.274-1.043-5.478-24.822-16.234-49.154-5.324-58.251-.044-2.067 1.2-2.6 3.947-1.175 5.876M6.129 25.352l17.356-11.401c.687-.446.687-1.46 0-1.906L6.125.648C4.289-.56 1.834-.03.64 1.83-.553 3.69-.026 6.171 1.81 7.38l8.562 5.617-8.566 5.624C-.03 19.824-.554 22.311.64 24.17l.004.004c1.194 1.857 3.649 2.387 5.485 1.18M60.192 18.62l-8.568-5.625 8.568-5.62c1.837-1.208 2.36-3.687 1.17-5.549C60.168-.027 57.713-.56 55.877.646L38.515 12.034c-.687.457-.687 1.472 0 1.925l17.358 11.394c1.836 1.208 4.291.678 5.485-1.18l.004-.003c1.19-1.858.667-4.341-1.17-5.549"
                  transform="translate(-1046 -391) translate(1061 406) translate(39 42)"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default RedFaceAvatarIcon
