import produce from "immer"
import * as React from "react"
import { createContext, useContext, useEffect, useReducer } from "react"
import { getAuth } from "services/auth"

//
// A context provider that stores state about the authenticated user.
//
export const UserStateContext = createContext()
export const UserDispatchContext = createContext()

const initialState = {
  hasLoaded: false,
  userId: undefined,
  email: undefined,
  lastSignInTime: undefined,
  hasPassword: undefined,
}

const HAS_LOADED = "HAS_LOADED"
const USER = "USER"

const reducer = (state, action) => {
  switch (action.type) {
    case HAS_LOADED:
      return produce(state, (draftState) => {
        draftState.hasLoaded = action.payload
      })
    case USER:
      const { userId, email, lastSignInTime, hasPassword, hasLoaded } =
        action.payload
      return produce(state, (draftState) => {
        draftState.userId = userId
        draftState.email = email
        draftState.lastSignInTime = lastSignInTime
        draftState.hasPassword = hasPassword
        draftState.hasLoaded = hasLoaded
      })
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  // Listen for changes to auth state in Firebase...
  useEffect(() => {
    getAuth().then((auth) => {
      // Listen to auth changes, including token updates,
      // so we can see when a user last logged in accurately.
      auth.onIdTokenChanged((user) => {
        // Handle user change
        handleUserChanged(user, dispatch)
      })
    })
  }, [])

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  )
}

//
// Handle a new Firebase user
//
const handleUserChanged = (user, dispatch) => {
  if (user) {
    // Determine if a user has a password set
    let hasPassword = false
    for (const provider of user.providerData) {
      if (provider.providerId === "password") {
        hasPassword = true
        break
      }
    }

    // Update user and set loaded
    dispatch({
      type: USER,
      payload: {
        userId: user.uid,
        email: user.email,
        lastSignInTime: user.metadata.lastSignInTime,
        hasPassword: hasPassword,
        hasLoaded: true,
      },
    })
    console.debug(`User '${user.email}' is authenticated`)
  } else {
    dispatch({
      type: USER,
      payload: {
        userId: undefined,
        email: undefined,
        lastSignInTime: undefined,
        hasPassword: undefined,
        hasLoaded: true,
      },
    })
    console.debug("There is no authenticated user.")
  }
}

export const useUserContext = () => {
  const context = useContext(UserStateContext)
  if (context === undefined) {
    throw new Error("useUserContext must be used within a UserProvider")
  }
  return context
}

export const useUserDispatcher = () => {
  const context = useContext(UserDispatchContext)
  if (context === undefined) {
    throw new Error(
      "useUserDispatcher must be used within a ProfilUserProvidereProvider"
    )
  }
  return context
}
