import produce from "immer"
import * as React from "react"
import { createContext, useContext, useReducer } from "react"

//
// A context provider that stores state about a profile
// while it is being handled.
//
export const ProfileHandlingStateContext = createContext()
export const ProfileHandlingispatchContext = createContext()

// Context mode
export const MODES = {
  CREATING: "CREATING",
  UPDATING: "UPDATING",
}

const initialState = {
  mode: undefined,
  id: undefined,
  avatar: undefined,
  name: undefined,
  birthMonth: undefined,
  birthYear: undefined,
  isLoading: false,
  isSaving: false,
  validationErrorName: undefined,
  validationErrorBirthMonth: undefined,
  validationErrorBirthYear: undefined,
}

export const MODE = "MODE"
export const ID = "ID"
export const AVATAR = "AVATAR"
export const NAME = "NAME"
export const BIRTH_MONTH = "BIRTH_MONTH"
export const BIRTH_YEAR = "BIRTH_YEAR"
export const VALIDATION_ERROR_NAME = "VALIDATION_ERROR_NAME"
export const VALIDATION_ERROR_BIRTH_MONTH = "VALIDATION_ERROR_BIRTH_MONTH"
export const VALIDATION_ERROR_BIRTH_YEAR = "VALIDATION_ERROR_BIRTH_YEAR"
export const IS_SAVING = "IS_SAVING"
export const IS_LOADING = "IS_LOADING"
export const PROFILE = "PROFILE"
export const RESET_PROFILE = "RESET_ALL" // Reset all profile state
export const RESET_VALIDATION = "RESET_VALIDATION" // Reset validation state

const reducer = (state, action) => {
  switch (action.type) {
    case MODE:
      return produce(state, (draftState) => {
        draftState.mode = action.payload
      })
    case ID:
      return produce(state, (draftState) => {
        draftState.id = action.payload
      })
    case AVATAR:
      return produce(state, (draftState) => {
        draftState.avatar = action.payload
      })
    case NAME:
      return produce(state, (draftState) => {
        const name = action.payload
        draftState.name = name
        draftState.validationErrorName = undefined // Reset validation error if the name changes!
      })
    case BIRTH_MONTH:
      return produce(state, (draftState) => {
        const birthMonth = action.payload
        draftState.birthMonth = birthMonth
        draftState.validationErrorBirthMonth = undefined // Reset validation error if the birth month changes!
      })
    case BIRTH_YEAR:
      return produce(state, (draftState) => {
        const birthYear = action.payload
        draftState.birthYear = birthYear
        draftState.validationErrorBirthYear = undefined // Reset validation error if the birth year changes!
      })
    case VALIDATION_ERROR_NAME:
      return produce(state, (draftState) => {
        const validationError = action.payload
        draftState.validationErrorName = validationError
      })
    case VALIDATION_ERROR_BIRTH_MONTH:
      return produce(state, (draftState) => {
        const validationError = action.payload
        draftState.validationErrorBirthMonth = validationError
      })
    case VALIDATION_ERROR_BIRTH_YEAR:
      return produce(state, (draftState) => {
        const validationError = action.payload
        draftState.validationErrorBirthYear = validationError
      })
    case IS_LOADING:
      return produce(state, (draftState) => {
        const isLoading = action.payload
        if (typeof isLoading !== "boolean") {
          console.error("IS_LOADING payload must be a boolean!")
          return
        }
        draftState.isLoading = isLoading
      })
    case IS_SAVING:
      return produce(state, (draftState) => {
        const isSaving = action.payload
        if (typeof isSaving !== "boolean") {
          console.error("IS_SAVING payload must be a boolean!")
          return
        }
        draftState.isSaving = isSaving
      })
    case PROFILE:
      return produce(state, (draftState) => {
        const profile = action.payload
        if (typeof profile !== "object") {
          console.error("PROFILE payload must be a profile object!")
          return
        }
        draftState.id = profile.id
        draftState.avatar = profile.avatar
        draftState.name = profile.name
        draftState.birthMonth = profile.birthMonth
        draftState.birthYear = profile.birthYear
      })

    case RESET_VALIDATION:
      return produce(state, (draftState) => {
        draftState.validationErrorName = undefined
        draftState.validationErrorBirthMonth = undefined
        draftState.validationErrorBirthYear = undefined
      })
    case RESET_PROFILE:
      return produce(state, (draftState) => {
        draftState.id = undefined
        draftState.avatar = undefined
        draftState.name = undefined
        draftState.birthMonth = undefined
        draftState.birthYear = undefined
        draftState.isLoading = false
        draftState.isSaving = false
        draftState.validationErrorName = undefined
        draftState.validationErrorBirthMonth = undefined
        draftState.validationErrorBirthYear = undefined
      })

    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

//
// Provider and Hooks
// ------------------
//

export const ProfileHandlingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <ProfileHandlingStateContext.Provider value={state}>
      <ProfileHandlingispatchContext.Provider value={dispatch}>
        {children}
      </ProfileHandlingispatchContext.Provider>
    </ProfileHandlingStateContext.Provider>
  )
}

export const useProfileHandlingContext = () => {
  const context = useContext(ProfileHandlingStateContext)
  if (context === undefined) {
    throw new Error(
      "useProfileHandlingContext must be used within a ProfileHandlingProvider"
    )
  }
  return context
}

export const useProfileHandlingDispatcher = () => {
  const context = useContext(ProfileHandlingispatchContext)
  if (context === undefined) {
    throw new Error(
      "useProfileHandlingDispatcher must be used within a ProfileHandlingProvider"
    )
  }
  return context
}
