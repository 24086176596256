import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const NavbarLink = ({ text, to, isActive, onClick, children }) => {
  // Handle touch start event
  const handleTouchStart = (event) => {
    event.preventDefault() // Stop mouse events
    onClick()
  }

  // Handle mouse down event
  const handleMouseDown = () => {
    onClick()
  }

  return (
    <Link
      to={to}
      onTouchStart={handleTouchStart}
      onMouseDown={handleMouseDown}
      className={`
        laptop:mr-2
        px-5 py-1
        my-1 laptop:my-0
        laptop:h-10 desktop:h-11
        text-grey-darker
        text-lg desktop:text-xl 
        tracking-wide desktop:tracking-wider
        no-underline
        rounded-3xl
        border border-grey-light
        flex
        justify-center
        items-center
        ${isActive ? "shadow-navbar-link" : ""}
        ${isActive ? "font-medium" : ""}
        ${!isActive ? "hover:border-grey-shadow-dark" : ""}
      `}
    >
      {children}
      <div className={`${isActive ? "transform scale-90" : ""}`}>{text}</div>
    </Link>
  )
}

NavbarLink.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.object,
}

export default NavbarLink
