import { ContentType } from "helpers/contentTypes"

// Link to the store
export const STORE_LINK = "superfonik.com"

/**
 * Construct the full URL to an item of content in the Store.
 *
 * @param location The page location
 * @param storeURL The Store URL
 * @param contentType The type of content
 * @param contentType The unique id of the content
 * @param contentSlug The content slug
 * @returns The direct URL to a content item in the store
 */
export function getStoreLink(
  location,
  storeURL,
  contentType,
  contentId,
  contentSlug
) {
  switch (contentType) {
    case ContentType.PODCAST:
    case ContentType.STATION:
      return `${location.origin}/${storeURL}/store/${contentType}s/${contentId}/`
    case ContentType.AUDIOBOOK:
      return `${location.origin}/${storeURL}/store/${contentType}s/${contentSlug}/`
    default:
      console.error(
        `Cannot determine the store linl URL for content type '${contentType}'!`
      )
      return
  }
}
