import produce from "immer"
import React, { createContext, useContext, useEffect, useReducer } from "react"

// Map countries of origin to store countries
export const StoreCountries = {
  GB: {
    storeCountry: "GB",
    storeURL: "uk",
  },
  // TODO: Enable on store go-live
  // US: {
  //   storeCountry: "US",
  //   storeURL: "us",
  // },
}

export const initialState = {
  countryOfOrigin: undefined, // The user's country of origin, as detected by IP address
  storeCountry: undefined, // The country code for the chosen store
  storeURL: undefined, // The URL for the chosen store
  isLoading: true, // A flag to indicate that geo location is still loading
}

// Reducer actions
export const COUNTRIES = "COUNTRIES"
export const STORE_COUNTRY = "STORE_COUNTRY"

export const reducer = (state, action) => {
  switch (action.type) {
    case COUNTRIES:
      return produce(state, (draftState) => {
        const { countryOfOrigin, storeCountry, storeURL } = action.payload
        draftState.countryOfOrigin = countryOfOrigin
        draftState.storeCountry = storeCountry
        draftState.storeURL = storeURL
        draftState.isLoading = false
      })
    case STORE_COUNTRY:
      return produce(state, (draftState) => {
        const { storeCountry, storeURL } = action.payload
        draftState.storeCountry = storeCountry
        draftState.storeURL = storeURL
      })
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const getCountryOfOrigin = (action) => {
  var xhr = new XMLHttpRequest()
  xhr.open("GET", "https://pro.ip-api.com/json?key=DrKv8TcDV0FdgWr", true) // TODO: Extract to config!
  xhr.onload = function (e) {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        const response = JSON.parse(xhr.responseText)

        // Assign the country of origin using the result of the IP address lookup
        const countryOfOrigin = response.countryCode

        // Attempt to map the country of origin to a store country
        const storeCountry = StoreCountries[countryOfOrigin]?.storeCountry

        // Attempt to map the country of origin to a store URL
        const storeURL = StoreCountries[countryOfOrigin]?.storeURL

        // Update the countries in the context
        action({
          type: COUNTRIES,
          payload: {
            countryOfOrigin: countryOfOrigin,
            storeCountry: storeCountry,
            storeURL: storeURL,
          },
        })
      } else {
        console.error(xhr.statusText)

        // Update the countries in the context
        action({
          type: COUNTRIES,
          payload: {
            countryOfOrigin: undefined,
            storeCountry: undefined,
            storeURL: undefined,
          },
        })
      }
    }
  }
  xhr.onerror = function (e) {
    console.error(xhr.statusText)

    // Update the countries in the context
    action({
      type: COUNTRIES,
      payload: {
        countryOfOrigin: undefined,
        storeCountry: undefined,
        storeURL: undefined,
      },
    })
  }
  xhr.send(null)
}

// Context
export const CountryContext = createContext()
export const CountryDispatchContext = createContext()

// Country context provider
export const CountryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  // Determine country code using external API
  useEffect(() => {
    getCountryOfOrigin(dispatch)
  }, [])

  return (
    <CountryContext.Provider value={state}>
      <CountryDispatchContext.Provider value={dispatch}>
        {children}
      </CountryDispatchContext.Provider>
    </CountryContext.Provider>
  )
}

// Hook to use the country information from the context
export const useCountries = () => {
  const context = useContext(CountryContext)
  return context
}

export const useCountriesDispatcher = () => {
  const context = useContext(CountryDispatchContext)
  if (context === undefined) {
    throw new Error(
      "useCountriesDispatcher must be used within a CountryProvider"
    )
  }
  return context
}
