//
// Keys for session storage
//
export const SessionStorageKey = {
  // A content item to jump back to once a user has finished with prerequisites, like singing in
  JUMP_BACK_TO_ITEM: "jumpBackToItem",
}

//
// Write an object to session storage
//
export const writeToSessionStorage = (key, object) => {
  if (sessionStorage) {
    sessionStorage.setItem(key, JSON.stringify(object))
  }
}

//
// Read an object from session storage
//
export const readFromSessionStorage = (key) => {
  if (sessionStorage) {
    return JSON.parse(sessionStorage.getItem(key))
  }
}

//
// Clear an object from session storage
//
export const clearFromSessionStorage = (key) => {
  if (sessionStorage) {
    sessionStorage.removeItem(key)
  }
}
