import PageTitle from "components/pageTitle"
import LibraryFilter from "components/subnavFilter/libraryFilter"
import LibraryTabBar from "components/tabBar/libraryTabBar"
import BreakpointContext from "context/breakpointContext"
import { useLayoutContext } from "context/layoutContext"
import { Screen, useSetScreen } from "context/screenContext"
import { size } from "helpers/responsiveSize"
import { useLocationSubtitles } from "hooks/useLocationSubtitles"
import { useMediaQueries } from "hooks/useMediaQueries"
import { useNavBar } from "hooks/useNavBar"
import React, { useContext } from "react"
import GridLayout from "./gridLayout"

//
// The layout for all Library pages
//
const LibraryLayout = ({ children }) => {
  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  // Breakpoints
  const { isLaptop, isDesktop } = useMediaQueries()

  // Layout context
  const { hasNavBar, hasTabBar } = useLayoutContext()

  // Use the Nav Bar
  useNavBar({
    hasNavBar: true,
    layoutType: "fluid",
    navbarTitle: "Library",
  })

  // Set subtitles automatically
  useLocationSubtitles()

  // Set the screen
  useSetScreen(Screen.LIBRARY)

  return (
    <>
      {/* Bring header & navigation in at the same time as the navbar for visual consistency! */}
      {hasNavBar && (
        <GridLayout
          type="fluid"
          container={false}
          style={{
            marginTop: size(bpt, "5vw", "2.8vw", "2.5vw", "2.5vw"),
          }}
        >
          {/* Page title */}
          <PageTitle
            title="Library"
            isResponsive={true}
            className="
              col-start-1 laptop:col-start-1
              col-span-full laptop:col-span-2"
          />

          {/* Sub nav */}
          {(isLaptop || isDesktop) && (
            <LibraryFilter className="laptop:col-start-3 laptop:col-span-8" />
          )}

          {/* Spacer */}
          <div className="col-span-full mb-6 tablet:mb-6 laptop:mb-12" />
        </GridLayout>
      )}

      {children}

      {/* Library Tab bar */}
      {hasTabBar && <LibraryTabBar />}
    </>
  )
}

export default LibraryLayout
LibraryLayout.whyDidYouRender = true
