import React from "react"
import resolveConfig from "tailwindcss/resolveConfig"
import tailwindConfig from "../../../tailwind.config.js"

// Load Tailwind config
const { theme } = resolveConfig(tailwindConfig)

const Logo = (props) => {
  // Tailwind theme colours
  const red = theme.colors.red["DEFAULT"]

  const { color = red, style, className } = props

  return (
    <div style={style ? style : {}} className={className ? className : ""}>
      <svg
        viewBox="0 0 320 147"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <polyline
            id="path-1"
            points="320 0 319.78705 114.969919 0 160 0.212950277 45.0295349"
          ></polyline>
          <polyline
            id="path-3"
            points="247.984138 0 247.893729 48.7074199 0 83.6140404 0.0904085575 34.9066205"
          ></polyline>
          <polyline
            id="path-5"
            points="247.984138 0 247.893729 48.7074199 0 83.6140404 0.0904085575 34.9066205"
          ></polyline>
          <polyline
            id="path-7"
            points="247.984138 0 247.893729 48.7074199 0 83.6140404 0.0904085575 34.9066205"
          ></polyline>
          <polyline
            id="path-9"
            points="247.984138 0 247.893729 48.7074199 0 83.6140404 0.0904085575 34.9066205"
          ></polyline>
          <polyline
            id="path-11"
            points="247.984138 0 247.893729 48.7074199 0 83.6140404 0.0904085575 34.9066205"
          ></polyline>
          <polyline
            id="path-13"
            points="247.984138 0 247.893729 48.7074199 0 83.6140404 0.0904085575 34.9066205"
          ></polyline>
          <polyline
            id="path-15"
            points="247.984138 0 247.893729 48.7074199 0 83.6140404 0.0904085575 34.9066205"
          ></polyline>
          <polyline
            id="path-17"
            points="247.984138 0 247.893729 48.7074199 0 83.6140404 0.0904085575 34.9066205"
          ></polyline>
          <polyline
            id="path-19"
            points="247.984138 0 247.893729 48.7074199 0 83.6140404 0.0904085575 34.9066205"
          ></polyline>
          <polyline
            id="path-21"
            points="247.984138 0 247.893729 48.7074199 0 83.6140404 0.0904085575 34.9066205"
          ></polyline>
          <polyline
            id="path-23"
            points="247.984138 0 247.893729 48.7074199 0 83.6140404 0.0904085575 34.9066205"
          ></polyline>
          <polyline
            id="path-25"
            points="247.984138 0 247.893729 48.7074199 0 83.6140404 0.0904085575 34.9066205"
          ></polyline>
          <polyline
            id="path-27"
            points="247.984138 0 247.893729 48.7074199 0 83.6140404 0.0904085575 34.9066205"
          ></polyline>
          <polyline
            id="path-29"
            points="247.984138 0 247.893729 48.7074199 0 83.6140404 0.0904085575 34.9066205"
          ></polyline>
          <polyline
            id="path-31"
            points="247.984138 0 247.893729 48.7074199 0 83.6140404 0.0904085575 34.9066205"
          ></polyline>
          <polyline
            id="path-33"
            points="247.984138 0 247.893729 48.7074199 0 83.6140404 0.0904085575 34.9066205"
          ></polyline>
          <polyline
            id="path-35"
            points="247.984138 0 247.893729 48.7074199 0 83.6140404 0.0904085575 34.9066205"
          ></polyline>
          <polyline
            id="path-37"
            points="247.984138 0 247.893729 48.7074199 0 83.6140404 0.0904085575 34.9066205"
          ></polyline>
          <polyline
            id="path-39"
            points="247.984138 0 247.893729 48.7074199 0 83.6140404 0.0904085575 34.9066205"
          ></polyline>
          <polyline
            id="path-41"
            points="247.984138 0 247.893729 48.7074199 0 83.6140404 0.0904085575 34.9066205"
          ></polyline>
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-70.000000, -96.000000)">
            <g transform="translate(70.000000, 91.000000)">
              <g>
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1"></use>
                </mask>
                <g id="Clip-2"></g>
                <path
                  d="M278.03518,5.90911695 L42.1783152,39.1208545 C19.0380815,42.3791114 0.175370816,65.3877195 0.128991906,90.4066136 C0.0827837394,115.429873 18.8697909,133.137198 42.0100246,129.878395 L51.8433168,128.494004 L51.8111836,145.978146 C51.8057373,148.709821 53.3067372,150.937288 55.6388423,151.651037 C56.4100382,151.891682 57.2215367,151.942431 58.0243211,151.829475 C59.6429611,151.601381 61.227834,150.69446 62.421118,149.238585 L83.0282782,124.102915 L277.866889,96.6672033 C301.006578,93.4089464 319.869833,70.4003383 319.916212,45.376533 C319.962421,20.3527277 301.174869,2.65086013 278.03518,5.90911695 M42.0290866,119.395332 C24.2338504,121.901095 9.78754329,108.285827 9.82287931,89.0413216 C9.85888981,69.8017271 24.3629277,52.1096817 42.1587085,49.603918 L278.015573,16.3921804 C295.811354,13.8864167 310.257661,27.5022305 310.222325,46.741825 C310.186314,65.985785 295.681732,83.6783761 277.886496,86.1841398 L81.6078835,113.822299 C79.9456732,114.056396 78.3825855,114.974776 77.2067296,116.408824 L61.5219341,135.540919 L61.5458978,122.627942 C61.5518888,119.330396 59.0743675,116.99543 56.0206279,117.425426 L42.0290866,119.395332"
                  id="Fill-1"
                  fill={color}
                  mask="url(#mask-2)"
                ></path>
              </g>
              <g id="Group-64" transform="translate(38.804333, 27.042277)">
                <g id="Group-6">
                  <mask id="mask-4" fill="white">
                    <use xlinkHref="#path-3"></use>
                  </mask>
                  <g id="Clip-5"></g>
                  <path
                    d="M11.2437746,46.2596074 C5.10089438,47.1245106 1.12945341,50.8013042 1.12073934,55.6278461 C1.11202526,60.1995553 4.73817089,61.7263686 8.13611662,61.6303289 L13.2610836,61.4791755 C14.661327,61.418605 15.9520998,61.5812177 15.9493767,63.1238558 C15.9466535,64.5677257 14.5485886,65.5897849 12.1059237,65.9341091 C9.49660202,66.3013519 7.82731149,65.7627655 7.14053323,64.3407228 L0.609876522,65.2607397 C1.50415876,69.9830566 5.58670422,72.2858273 11.5983287,71.4389316 C18.1121018,70.521643 22.1619695,66.7444443 22.1717903,61.5828547 C22.1793977,57.5158998 19.683359,55.5612731 14.7544587,55.7833649 L9.85170069,55.9929062 C8.66113981,56.0376519 7.29194033,55.8957751 7.29466348,54.4235297 C7.29575274,53.5979155 7.80443703,52.1349467 11.1854992,51.6591135 C13.1358188,51.3840907 14.7789671,52.0869269 15.3519177,53.4118385 L21.9615457,52.480908 C21.1462348,47.8180702 17.0680464,45.43945 11.2437746,46.2596074"
                    id="Fill-4"
                    fill={color}
                    mask="url(#mask-4)"
                  ></path>
                </g>
                <g id="Group-9">
                  <mask id="mask-6" fill="white">
                    <use xlinkHref="#path-5"></use>
                  </mask>
                  <g id="Clip-8"></g>
                  <path
                    d="M11.244755,45.735918 C4.80940848,46.6422929 0.645713167,50.553729 0.63645446,55.6962198 C0.627195752,60.6166187 4.47990741,62.2580249 8.08263397,62.1565284 L13.2163151,62.0091947 C14.8567402,61.9382563 15.4667257,62.262936 15.4650918,63.1922295 C15.4629133,64.3010518 14.2402192,65.1097499 12.106904,65.4104198 C9.62012407,65.7607465 8.072286,65.2614491 7.51676354,63.9245324 L7.45576499,63.7728333 L0.0351830892,64.817811 L0.0825658874,65.1179351 C0.897332165,70.304626 5.20589902,72.8676862 11.5971305,71.9673138 C18.3069704,71.0227412 22.6460365,66.9164973 22.6558588,61.5148084 C22.6640093,57.1269934 20.020376,55.0228504 14.7990095,55.2536731 L9.90060845,55.4670341 C8.41322429,55.5161453 7.77709661,55.1860088 7.7787305,54.3554834 C7.78090902,53.2657599 8.99053195,52.4914396 11.1848457,52.1825846 C13.0082665,51.9255692 14.5250606,52.584751 14.958586,53.8163513 L15.0152275,53.9827838 L22.5322089,52.9241641 L22.4891831,52.6284054 C21.7572006,47.5055591 17.4442767,44.8628296 11.244755,45.735918 M12.1058148,65.9342728 C14.5490243,65.5899486 15.9465446,64.5678894 15.9492677,63.1240195 C15.9519909,61.5813814 14.6612181,61.4187687 13.2609747,61.4793392 L8.13600769,61.6299469 C4.73806197,61.7265323 1.11191633,60.199719 1.12063041,55.6280098 C1.12934449,50.8014679 5.10078546,47.1246743 11.2436657,46.2597711 C17.0679375,45.4396137 21.1461259,47.8176882 21.9614368,52.4810717 L15.3523534,53.4114566 C14.7788581,52.0870906 13.1357098,51.3842544 11.1859349,51.6587316 C7.80432811,52.1351104 7.29618844,53.5980792 7.29455455,54.4236934 C7.2918314,55.8953931 8.66103088,56.0378156 9.85159177,55.9925242 L14.7543498,55.7835286 C19.6837947,55.5608911 22.1792887,57.5160635 22.1716813,61.5830184 C22.1618606,66.7440623 18.1119929,70.5218067 11.5982197,71.4390953 C5.5865953,72.2854453 1.50404983,69.9832203 0.609767596,65.2603577 L7.14096893,64.3408865 C7.82720256,65.7629292 9.49703772,66.3015156 12.1058148,65.9342728"
                    id="Fill-7"
                    fill={color}
                    mask="url(#mask-6)"
                  ></path>
                </g>
                <g id="Group-12">
                  <mask id="mask-8" fill="white">
                    <use xlinkHref="#path-7"></use>
                  </mask>
                  <g id="Clip-11"></g>
                  <path
                    d="M48.8843427,41.4404322 L42.6194654,42.3227972 L42.5944124,55.7023314 C42.5889661,58.7030272 40.7922322,60.8901136 38.0222447,61.280275 C35.2517126,61.6698907 33.4626035,59.9881042 33.4680498,56.9879541 L33.4925581,43.6078742 L27.2320379,44.4891478 L27.2074994,57.8692277 C27.1944584,64.7808139 31.4354911,68.6458672 38.0102629,67.7198478 C44.5893916,66.7932827 48.8467633,61.7320983 48.8592898,54.8205121 L48.8843427,41.4404322"
                    id="Fill-10"
                    fill={color}
                    mask="url(#mask-8)"
                  ></path>
                </g>
                <g id="Group-15">
                  <mask id="mask-10" fill="white">
                    <use xlinkHref="#path-9"></use>
                  </mask>
                  <g id="Clip-14"></g>
                  <path
                    d="M49.3695535,40.84384 L42.1363243,41.862625 L42.110182,55.7709234 C42.1052804,58.4927766 40.5405588,60.4021118 38.0232795,60.7562583 C35.5103573,61.1104048 33.9516267,59.6408878 33.9565283,56.9190347 L33.9821259,43.0107363 L26.7488967,44.0289756 L26.7232678,57.9372741 C26.7096833,65.1653548 31.1380688,69.2159393 38.0091191,68.2484482 C44.8801695,67.2809571 49.3303401,61.9802191 49.3439559,54.7521384 L49.3695535,40.84384 M38.0221903,61.2801113 C40.7921778,60.8899499 42.5889117,58.7028635 42.594358,55.7027134 L42.6194109,42.3226335 L48.8842883,41.4402685 L48.8592353,54.8203484 C48.8467088,61.7319346 44.5893372,66.7936647 38.0102084,67.7196841 C31.4354367,68.6457035 27.1944039,64.7811959 27.2074449,57.869064 L27.2319834,44.4895298 L33.4925037,43.6077105 L33.4679953,56.9877904 C33.462549,59.9884861 35.2516581,61.6702727 38.0221903,61.2801113"
                    id="Fill-13"
                    fill={color}
                    mask="url(#mask-10)"
                  ></path>
                </g>
                <g id="Group-18">
                  <mask id="mask-12" fill="white">
                    <use xlinkHref="#path-11"></use>
                  </mask>
                  <g id="Clip-17"></g>
                  <path
                    d="M66.8552765,38.4288228 C59.9139689,39.4061362 54.6686387,45.569049 54.6555676,52.7638432 L54.6136311,75.4002974 L60.8785084,74.5179324 L60.9030167,61.1618624 L61.3294619,61.6720734 C62.6354843,63.2196227 65.0024457,63.9191848 67.6635072,63.5443024 C74.0634528,62.6433843 78.9030338,56.5421333 78.9166742,49.3473391 C78.9297206,42.1525449 73.7437551,37.4586033 66.8552765,38.4288228 M66.8204202,57.2247793 C63.552641,57.6847878 61.0010501,55.3323602 61.0075731,51.8694733 C61.0141212,48.4065864 63.5755155,45.3338608 66.8432947,44.8733067 C70.0587894,44.4209378 72.5706223,46.7788221 72.5646421,50.2417091 C72.5580958,53.7051417 70.0359149,56.7718647 66.8204202,57.2247793"
                    id="Fill-16"
                    fill={color}
                    mask="url(#mask-12)"
                  ></path>
                </g>
                <g id="Group-21">
                  <mask id="mask-14" fill="white">
                    <use xlinkHref="#path-13"></use>
                  </mask>
                  <g id="Clip-20"></g>
                  <path
                    d="M66.8562024,37.9051335 C59.6360443,38.9217358 54.1853886,45.340027 54.1712282,52.8316713 L54.1282025,75.9919785 L61.3614317,74.9737391 L61.3848508,62.508765 C62.8302984,63.8478643 65.1231902,64.4301052 67.6622546,64.0726846 C74.2283122,63.1483023 79.3870463,56.6487047 79.4006899,49.2792928 C79.4148224,41.7871028 74.019719,36.8961707 66.8562024,37.9051335 M54.6134677,75.3999154 L54.6554042,52.7640069 C54.6690199,45.5692127 59.9143501,39.4062999 66.8551131,38.4289865 C73.7441363,37.458767 78.9301019,42.1527086 78.9165129,49.3475028 C78.903415,56.542297 74.063834,62.6430023 67.6633439,63.5444661 C65.0022823,63.9193485 62.6353209,63.2192407 61.3298432,61.6722372 L60.9028533,61.1614804 L60.878345,74.5180961 L54.6134677,75.3999154 M66.8431313,44.8734704 C63.5758967,45.3334788 61.0139578,48.4062044 61.0074098,51.8690913 C61.0008867,55.3319782 63.5524776,57.6849515 66.8202568,57.224943 C70.0357515,56.7720284 72.5579324,53.7047597 72.5644807,50.2418728 C72.5710036,46.7789859 70.058626,44.4205558 66.8431313,44.8734704 M66.8213461,56.70109 C63.828605,57.1229008 61.4861519,54.9712836 61.4915888,51.8008813 C61.4975892,48.630479 63.8493009,45.8185886 66.842042,45.3973234 C69.7824987,44.9831521 72.0813813,47.1402262 72.0759447,50.3106285 C72.0699441,53.4810308 69.7618027,56.2869187 66.8213461,56.70109"
                    id="Fill-19"
                    fill={color}
                    mask="url(#mask-14)"
                  ></path>
                </g>
                <g id="Group-24">
                  <mask id="mask-16" fill="white">
                    <use xlinkHref="#path-15"></use>
                  </mask>
                  <g id="Clip-23"></g>
                  <path
                    d="M94.977294,34.4689303 C87.8312056,35.4751647 82.8347712,41.3668745 82.8211274,48.7979482 C82.8075397,56.1106094 87.7495111,60.7161508 94.568277,59.7557535 C98.8131222,59.1582336 104.243082,57.0208041 106.222811,50.247275 L100.132216,51.1050844 C99.4100365,52.6406286 97.606767,53.6539569 94.8928764,54.035933 C91.7345678,54.4806625 89.1633702,53.1874002 89.1671826,51.1443734 C89.1682719,50.5686807 89.5489682,50.114129 90.0897856,50.0382795 L106.087743,47.7857114 C106.303417,46.6468767 106.405807,45.5473309 106.408011,44.4248666 C106.420512,37.6164141 101.72199,33.5194467 94.977294,34.4689303 M89.0168648,45.3263304 L89.1001932,44.9847346 C89.7553829,42.2323234 91.9959902,40.3268079 95.0982019,39.8902637 C98.4051944,39.4247984 100.377844,41.0525627 100.37458,42.8920508 C100.373487,43.383163 100.032548,43.7755071 99.5440155,43.8442629 L89.0168648,45.3263304"
                    id="Fill-22"
                    fill={color}
                    mask="url(#mask-16)"
                  ></path>
                </g>
                <g id="Group-27">
                  <mask id="mask-18" fill="white">
                    <use xlinkHref="#path-17"></use>
                  </mask>
                  <g id="Clip-26"></g>
                  <path
                    d="M94.9782744,33.945241 C87.5484338,34.99131 82.3515756,41.1285759 82.3368414,48.8657763 C82.3227102,56.4807443 87.4651054,61.2843676 94.5676235,60.2841357 C102.686421,59.1409356 105.692233,54.0481017 106.781493,49.9800554 L106.877892,49.6313657 L99.8282033,50.6245038 L99.7623031,50.7843881 C99.0259635,52.5769477 96.7091081,53.2568653 94.8938567,53.5122437 C92.0013275,53.9198669 89.6528836,52.8257779 89.6561513,51.0757813 C89.6561513,50.9721021 89.6871952,50.6185013 90.0885875,50.5617505 L106.479223,48.2540687 L106.523338,48.0450732 C106.770056,46.7932827 106.889874,45.587875 106.892078,44.3562746 C106.905124,37.3502858 101.89344,32.9717474 94.9782744,33.945241 M94.5687127,59.7559172 C87.7494022,60.7163145 82.8074308,56.1102274 82.8210185,48.7975663 C82.8352069,41.3670382 87.8310967,35.4753284 94.9771851,34.469094 C101.721881,33.5190647 106.420403,37.6165778 106.407902,44.4244847 C106.405698,45.5474946 106.303308,46.6464947 106.087634,47.7853294 L90.0896767,50.0384432 C89.5488593,50.1142927 89.168163,50.5688444 89.1670737,51.1445371 C89.1632613,53.1870183 91.7344589,54.4808262 94.8933121,54.0360967 C97.6066581,53.6541206 99.4099276,52.6407923 100.132651,51.1052481 L106.222703,50.2474387 C104.242973,57.0209678 98.8135579,59.1583973 94.5687127,59.7559172 M95.0980929,39.8904274 C91.9964259,40.3269716 89.755274,42.2319414 89.1000842,44.9843526 L89.0167559,45.3264941 L99.5439065,43.8438809 C100.03244,43.7751252 100.373378,43.3827811 100.374471,42.8922145 C100.377735,41.0521807 98.4050855,39.4244165 95.0980929,39.8904274 M89.6588745,44.7126038 C90.35709,42.3923714 92.3618725,40.798985 95.0970037,40.4137348 C98.1028159,39.9908326 99.8930143,41.3752234 99.8902942,42.9604245 C99.8897465,43.2387215 99.6495647,43.3058401 99.5449958,43.3205735 L89.6588745,44.7126038"
                    id="Fill-25"
                    fill={color}
                    mask="url(#mask-18)"
                  ></path>
                </g>
                <g id="Group-30">
                  <mask id="mask-20" fill="white">
                    <use xlinkHref="#path-19"></use>
                  </mask>
                  <g id="Clip-29"></g>
                  <path
                    d="M125.956113,30.5878341 L123.321194,30.9588966 C115.275921,32.0917289 111.359488,36.8789818 111.343149,45.5929498 L111.321909,56.9201806 L117.586786,56.0383613 L117.608026,44.7105848 C117.615107,40.931749 119.958104,37.9648855 123.308667,37.4934177 L125.944131,37.1223552 L125.956113,30.5878341"
                    id="Fill-28"
                    fill={color}
                    mask="url(#mask-20)"
                  ></path>
                </g>
                <g id="Group-33">
                  <mask id="mask-22" fill="white">
                    <use xlinkHref="#path-21"></use>
                  </mask>
                  <g id="Clip-32"></g>
                  <path
                    d="M126.441269,29.9912418 L123.322174,30.4305144 C114.954481,31.6086381 110.875748,36.5928816 110.858864,45.6609961 L110.837079,57.51208 L118.070308,56.4938406 L118.092094,44.6422111 C118.098629,41.118208 120.197088,38.4547426 123.308014,38.0165614 L126.427109,37.5778345 L126.441269,29.9912418 M111.322344,56.9205626 L111.34304,45.5927861 C111.359379,36.8788181 115.276357,32.0915651 123.321085,30.9587329 L125.956004,30.5876704 L125.944022,37.1221915 L123.309103,37.493254 C119.95854,37.9647218 117.614998,40.9315853 117.607918,44.7104211 L117.587222,56.0381976 L111.322344,56.9205626"
                    id="Fill-31"
                    fill={color}
                    mask="url(#mask-22)"
                  ></path>
                </g>
                <g id="Group-36">
                  <mask id="mask-24" fill="white">
                    <use xlinkHref="#path-23"></use>
                  </mask>
                  <g id="Clip-35"></g>
                  <path
                    d="M144.128887,15.147702 C137.549758,16.0742671 134.061948,19.9376833 134.050511,26.321051 L134.048332,27.4675252 C134.046154,28.6893033 133.333233,29.5487497 132.181886,29.7113624 L128.421217,30.2406723 L128.40978,36.3926715 L134.033083,35.6008895 L133.999316,53.7272963 L140.264193,52.8449313 L140.29796,34.7190702 L147.99358,33.6353492 L148.005017,27.4828043 L140.309397,28.5665252 L140.31321,26.3919894 C140.318111,23.7590822 141.879021,21.907589 144.387586,21.5539882 C145.247012,21.4333929 146.280719,21.3962866 148.016455,21.4301188 L148.028436,14.9900004 C146.358057,14.9468917 145.254637,14.9894547 144.128887,15.147702"
                    id="Fill-34"
                    fill={color}
                    mask="url(#mask-24)"
                  ></path>
                </g>
                <g id="Group-39">
                  <mask id="mask-26" fill="white">
                    <use xlinkHref="#path-25"></use>
                  </mask>
                  <g id="Clip-38"></g>
                  <path
                    d="M144.129867,14.6240127 C137.332886,15.5811358 133.578208,19.7605009 133.566226,26.388879 L133.564048,27.5353533 C133.562414,28.4602813 133.05972,29.0594382 132.182866,29.182762 L127.938021,29.7802818 L127.924405,36.9848983 L133.547709,36.1931162 L133.514486,54.3189774 L140.747716,53.3001924 L140.781483,35.1743312 L148.477103,34.0906102 L148.490718,26.8865394 L140.794554,27.9702604 L140.797821,26.3233974 C140.802179,23.9786093 142.144147,22.3939539 144.386388,22.078005 C145.267599,21.9535899 146.34052,21.9208491 148.220582,21.9623208 L148.499432,21.970506 L148.513593,14.4739506 L148.308267,14.4695851 C146.498462,14.4128344 145.32533,14.4559431 144.129867,14.6240127 M128.409671,36.3928352 L128.421108,30.240836 L132.181777,29.7109805 C133.333669,29.5489134 134.046045,28.689467 134.048224,27.4671432 L134.050402,26.3212147 C134.062384,19.937847 137.549649,16.0738851 144.128778,15.1478657 C145.254528,14.9890728 146.357948,14.9470554 148.028328,14.9901641 L148.016346,21.4297368 C146.28061,21.3959047 145.246903,21.4330109 144.387477,21.5541519 C141.878912,21.9072071 140.318547,23.7592459 140.313646,26.3916074 L140.309288,28.5666889 L148.005453,27.482968 L147.994016,33.6349672 L140.297851,34.7186882 L140.264629,52.845095 L133.999752,53.7269143 L134.032974,35.6010532 L128.409671,36.3928352"
                    id="Fill-37"
                    fill={color}
                    mask="url(#mask-26)"
                  ></path>
                </g>
                <g id="Group-42">
                  <mask id="mask-28" fill="white">
                    <use xlinkHref="#path-27"></use>
                  </mask>
                  <g id="Clip-41"></g>
                  <path
                    d="M163.096765,24.8769082 C156.007318,25.8749574 151.046285,31.7623017 151.032097,39.1928298 C151.018509,46.6233578 155.96048,51.1110323 163.049927,50.1129831 C170.139374,49.1143882 175.100408,43.2325007 175.114596,35.8019726 C175.128184,28.3708989 170.186212,23.878859 163.096765,24.8769082 M163.061909,43.6728647 C159.711346,44.1448781 157.377607,41.9359644 157.384129,38.2984598 C157.391223,34.6609552 159.734221,31.7934055 163.084784,31.321392 C166.430989,30.85047 168.764728,33.0593837 168.758207,36.6968883 C168.751113,40.3343929 166.408115,43.2019426 163.061909,43.6728647"
                    id="Fill-40"
                    fill={color}
                    mask="url(#mask-28)"
                  ></path>
                </g>
                <g id="Group-45">
                  <mask id="mask-30" fill="white">
                    <use xlinkHref="#path-29"></use>
                  </mask>
                  <g id="Clip-44"></g>
                  <path
                    d="M163.097746,24.3531643 C155.72019,25.3921395 150.562545,31.5234029 150.547811,39.2611489 C150.533679,46.9983493 155.671718,51.6802859 163.049274,50.6413107 C170.421928,49.6028812 175.584475,43.4710721 175.598664,35.7333261 C175.612795,27.9961257 170.470945,23.3147347 163.097746,24.3531643 M163.049818,50.1130922 C155.960371,51.1111414 151.0184,46.623467 151.031988,39.1929389 C151.046176,31.7618651 156.00721,25.8750665 163.096656,24.8770173 C170.186103,23.8784224 175.128075,28.371008 175.114487,35.8015361 C175.100843,43.2326099 170.139265,49.1144974 163.049818,50.1130922 M163.084675,31.3215012 C159.734112,31.7935146 157.391114,34.6610643 157.384562,38.2980233 C157.377498,41.9355279 159.711237,44.1449872 163.0618,43.6729738 C166.408006,43.2015061 168.751004,40.3339563 168.758098,36.6964517 C168.764619,33.0594928 166.430881,30.8505791 163.084675,31.3215012 M163.062889,43.1491208 C160.000436,43.5807539 157.862219,41.5562801 157.868739,38.2298132 C157.874745,34.8995266 160.021132,32.2764416 163.083585,31.8453542 C166.146584,31.4137211 168.279899,33.4343751 168.273921,36.7646617 C168.267372,40.0911286 166.125343,42.7180334 163.062889,43.1491208"
                    id="Fill-43"
                    fill={color}
                    mask="url(#mask-30)"
                  ></path>
                </g>
                <g id="Group-48">
                  <mask id="mask-32" fill="white">
                    <use xlinkHref="#path-31"></use>
                  </mask>
                  <g id="Clip-47"></g>
                  <path
                    d="M191.306033,20.9046833 C184.48291,21.8656262 180.06814,27.0102997 180.055069,34.007012 L180.030561,47.2452151 L186.295438,46.3633958 L186.319402,33.2708893 C186.325393,30.0961216 188.275167,27.7742521 191.294051,27.3491672 C194.312934,26.9240822 196.255084,28.6975431 196.249093,31.8728565 L196.22513,44.9648173 L202.490007,44.082998 L202.514542,30.8442492 C202.527042,23.8480826 198.129156,19.9437404 191.306033,20.9046833"
                    id="Fill-46"
                    fill={color}
                    mask="url(#mask-32)"
                  ></path>
                </g>
                <g id="Group-51">
                  <mask id="mask-34" fill="white">
                    <use xlinkHref="#path-33"></use>
                  </mask>
                  <g id="Clip-50"></g>
                  <path
                    d="M191.307013,20.380994 C184.191424,21.3828629 179.5844,26.7578135 179.570784,34.0753857 L179.545186,47.8374419 L186.77896,46.8186568 L186.804013,33.202843 C186.80946,30.3483896 188.570793,28.2562514 191.292853,27.8731839 C194.015457,27.4895707 195.770255,29.0867768 195.764808,31.9412302 L195.739755,45.5570441 L202.977342,44.5377133 L203.002969,30.7756572 C203.016555,23.4580849 198.422602,19.379125 191.307013,20.380994 M180.030452,47.2453788 L180.05496,34.0071757 C180.068031,27.0104634 184.482801,21.8652443 191.305924,20.904847 C198.129591,19.9439041 202.527477,23.8477006 202.514435,30.8444129 L202.489898,44.0831617 L196.225021,44.964981 L196.249529,31.8730202 C196.254975,28.6977068 194.31337,26.9242459 191.293942,27.3493309 C188.275059,27.7744158 186.325284,30.0957396 186.319837,33.271053 L186.295329,46.3630138 L180.030452,47.2453788"
                    id="Fill-49"
                    fill={color}
                    mask="url(#mask-34)"
                  ></path>
                </g>
                <g id="Group-54">
                  <mask id="mask-36" fill="white">
                    <use xlinkHref="#path-35"></use>
                  </mask>
                  <g id="Clip-53"></g>
                  <path
                    d="M212.339856,5.5428018 C210.167327,5.84892842 208.396736,7.97053327 208.392372,10.2727583 C208.388566,12.5749833 210.151533,14.1994734 212.324606,13.8933468 C214.497135,13.5877658 216.271539,11.4650696 216.275904,9.16284463 C216.280253,6.86061962 214.512384,5.23667517 212.339856,5.5428018 M215.448603,17.9864944 L209.183726,18.8683137 L209.138522,43.1467198 L215.403399,42.2643548 L215.448603,17.9864944"
                    id="Fill-52"
                    fill={color}
                    mask="url(#mask-36)"
                  ></path>
                </g>
                <g id="Group-57">
                  <mask id="mask-38" fill="white">
                    <use xlinkHref="#path-37"></use>
                  </mask>
                  <g id="Clip-56"></g>
                  <path
                    d="M212.340836,5.01905788 C209.901984,5.36229077 207.912995,7.75073326 207.908087,10.3410774 C207.903737,12.930876 209.884555,14.7605419 212.323408,14.417309 C214.766618,14.0729847 216.755606,11.6845422 216.760515,9.09474373 C216.764865,6.50439955 214.784046,4.67527932 212.340836,5.01905788 M212.324497,13.8934559 C210.151969,14.1995826 208.388457,12.5750924 208.392806,10.2728674 C208.396627,7.9706424 210.167218,5.84849188 212.339747,5.54291093 C214.51282,5.23678431 216.280144,6.86018308 216.275795,9.16295377 C216.271975,11.4651788 214.497026,13.5873293 212.324497,13.8934559 M215.933759,17.3896293 L208.70053,18.4084144 L208.653147,43.7383463 L215.886921,42.720107 L215.933759,17.3896293 M209.138413,43.1468289 L209.183617,18.8684228 L215.448494,17.9860579 L215.40329,42.2644639 L209.138413,43.1468289"
                    id="Fill-55"
                    fill={color}
                    mask="url(#mask-38)"
                  ></path>
                </g>
                <g id="Group-60">
                  <mask id="mask-40" fill="white">
                    <use xlinkHref="#path-39"></use>
                  </mask>
                  <g id="Clip-59"></g>
                  <path
                    d="M229.0613,3.66931776 L222.796422,4.55168274 L222.728344,41.2328554 L228.993221,40.3510361 L229.0613,3.66931776 M246.384342,13.6301652 L238.928359,14.680054 L232.146083,24.2190906 C230.825356,26.0842257 230.763813,26.4656562 230.762169,27.3949497 C230.760545,28.2161985 230.95988,28.7727924 232.09979,30.244492 L238.892413,38.956823 L246.910999,37.8278106 L238.377194,27.3414731 C237.942034,26.8323534 237.742155,26.5442342 237.7427,26.1715346 C237.743789,25.7328077 237.992685,25.291898 238.386452,24.7227535 L246.384342,13.6301652"
                    id="Fill-58"
                    fill={color}
                    mask="url(#mask-40)"
                  ></path>
                </g>
                <g id="Group-63">
                  <mask id="mask-42" fill="white">
                    <use xlinkHref="#path-41"></use>
                  </mask>
                  <g id="Clip-62"></g>
                  <path
                    d="M229.546565,3.07272554 L222.313336,4.09151058 L222.243079,41.8247548 L229.476852,40.8065154 L229.546565,3.07272554 M222.728344,41.2332374 L222.796422,4.55151904 L229.0613,3.66915406 L228.993221,40.3508724 L222.728344,41.2332374 M247.450183,12.9517209 L238.707239,14.1827756 L231.780092,23.9313534 C230.394009,25.8859801 230.279637,26.3923714 230.277991,27.4635417 C230.275824,28.4916033 230.571014,29.1387801 231.715826,30.6142996 L238.577617,39.4259444 L238.651142,39.5143446 L247.913663,38.2101688 L238.757345,26.9576961 C238.40497,26.5549841 238.231233,26.3105194 238.231233,26.1026152 C238.231777,25.8576047 238.380461,25.5487497 238.756801,25.0101633 L247.450183,12.9517209 M238.892413,38.957205 L232.100334,30.2443283 C230.95988,28.7726287 230.760545,28.2160348 230.762169,27.3953317 C230.763813,26.4654925 230.825356,26.0846077 232.146083,24.2194726 L238.928904,14.6798903 L246.384342,13.6300015 L238.386452,24.7225898 C237.99323,25.29228 237.743789,25.732644 237.7427,26.1719166 C237.742155,26.5446162 237.942034,26.8321897 238.377194,27.341855 L246.910999,37.8276469 L238.892413,38.957205"
                    id="Fill-61"
                    fill={color}
                    mask="url(#mask-42)"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default Logo
