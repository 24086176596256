import React from "react"

const Subnav = (props) => {
  const { className, children, onScroll } = props

  return (
    <div
      onScroll={onScroll}
      style={{
        height: "4vw",
      }}
      className={`
        flex
        justify-center
        items-center
        ${className}
      `}
    >
      {children}
    </div>
  )
}

export default Subnav
