import { isSSR } from "helpers/ssrCheck"
import produce from "immer"
import * as React from "react"
import { createContext, useContext } from "react"

//
// A context provider that stores the dimensions of the grid
//
export const GridDimensionsContext = createContext()

export const initialState = {
  offsetWidth: 0,
  gutterWidth: 0,
  span3Width: 0,
}

export const GRID_DIMENSIONS = "GRID_DIMENSIONS"

export const reducer = (state, action) => {
  switch (action.type) {
    case GRID_DIMENSIONS:
      return produce(state, (draftState) => {
        const dimensions = action.payload
        draftState.offsetWidth = dimensions.offsetWidth
        draftState.gutterWidth = dimensions.gutterWidth
        draftState.span3Width = dimensions.span3Width
      })
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

export const GridDimensionsProvider = ({ state, children }) => {
  return (
    <GridDimensionsContext.Provider value={state}>
      {children}
    </GridDimensionsContext.Provider>
  )
}

export const useGridDimensions = () => {
  const context = useContext(GridDimensionsContext)
  if (context === undefined) {
    if (isSSR) {
      // There is no Context available during SSR, so use initial state as a placeholder
      return initialState
    } else {
      throw new Error(
        "useGridDimensionsContext must be used within a GridDimensionsProvider"
      )
    }
  }
  return context
}
