import { useMutation, useQueryClient } from "react-query"
import { profileContentIDsQueryName } from "reactQuery/queries/profileContentIDsQuery"
import {
  profileContentQuery,
  profileContentQueryName,
} from "reactQuery/queries/profileContentQuery"
import { addContentToProfile } from "services/cloudFunctions"

//
// A hook to use the profile content addition mutation
//
export const useAddProfileContentMutation = ({
  userId,
  profileId,
  onMutate,
  onError,
  onSuccess,
}) => {
  // Get the React Query Client from the context
  const queryClient = useQueryClient()

  return useMutation(
    ({ profileId, contentRef }) =>
      new Promise((resolve, reject) => {
        // Invoke cloud function
        addContentToProfile(profileId, contentRef)
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      }),
    {
      onMutate: onMutate,
      onError: () => {
        if (onError) {
          onError()
        }
      },
      onSuccess: (data, variables, context) => {
        // Extract the content ref
        const { contentRef: contentRefToAdd } = variables

        // Add profile content ID to cache
        queryClient.setQueryData(
          [profileContentIDsQueryName, { userId, profileId }],
          (oldProfileContentIDs) => {
            // Add to new data
            const newProfileContentIDs = Array.from(oldProfileContentIDs)
            newProfileContentIDs.push(contentRefToAdd)
            return newProfileContentIDs
          }
        )

        // Prefetch profile content to smooth out library loading
        queryClient.prefetchQuery(
          [profileContentQueryName, { userId, profileId }],
          profileContentQuery
        )

        if (onSuccess) {
          onSuccess()
        }
      },
    }
  )
}
