import { useProfileContext } from "context/profileContext"
import { Screen, useScreenContext } from "context/screenContext"
import { useSelectedItemContext } from "context/selectedItemContext"
import { useUserContext } from "context/userContext"
import { Link } from "gatsby"
import { ContentType } from "helpers/contentTypes"
import {
  SessionStorageKey,
  writeToSessionStorage,
} from "helpers/sessionStorageHelper"
import React, { useCallback, useEffect, useMemo, useReducer } from "react"
import { usePaymentDetailsQuery } from "reactQuery/queries/paymentDetailsQuery"
import { useProfileContentIDsQuery } from "reactQuery/queries/profileContentIDsQuery"
import { usePurchasesQuery } from "reactQuery/queries/purchasesQuery"
import routes from "services/routes"
import { useSalePrice } from "./useSalePrice"

/**
 * Call to action details for a content item
 */
export const useCallToAction = (contentItem) => {
  // Initial state
  const initialState = {
    isLoaded: false, // Flag to indicate that all data has loaded and the CTA is ready to use
    text: undefined, // CTA text
    isAvailable: undefined, // Content item is available
    inLibrary: undefined, // Content item is already in the user's library
    isPurchased: undefined, // Content item has been purchased
    explanation: undefined, // An explanation of the CTA for an end user
  }

  // CTA state reducer
  function reducer(_, action) {
    switch (action.type) {
      case "CTA":
        const {
          isLoaded,
          text,
          isAvailable,
          inLibrary,
          isPurchased,
          explanation,
        } = action.payload
        return {
          isLoaded: isLoaded,
          text: text,
          isAvailable: isAvailable,
          inLibrary: inLibrary,
          isPurchased: isPurchased,
          explanation: explanation,
        }
      default:
        throw new Error()
    }
  }

  // CTA state
  const [state, dispatch] = useReducer(reducer, initialState)

  // Screen context
  const { screen } = useScreenContext()

  // Active profile
  const { activeProfileId, activeProfileName } = useProfileContext()

  // User context
  const { hasLoaded: authHasLoaded, userId } = useUserContext()

  // Load purchases
  const { isLoading: arePurchasesLoading, data: purchases } =
    usePurchasesQuery(userId)

  // Load Profile Content references
  const { isLoading: isProfileContentLoading, data: profileContentRefs } =
    useProfileContentIDsQuery(userId, activeProfileId)

  // Payment details
  const { isLoading: arePaymentDetailsLoading, data: paymentDetails } =
    usePaymentDetailsQuery(userId)

  // Get the selected item from the context
  const { selectedItem } = useSelectedItemContext()

  // Item is free
  const FREE = "Free!"

  // Item is in library
  const IN_LIBRARY = "In Library"

  // Item has been purchased and is available
  const GET = "Get"

  // Item can be removed
  const REMOVE = "Remove"

  // Item is unavailable
  const UNAVAILABLE = "Unavailable"

  // Determine if a user is signed in
  const isSignedIn = useMemo(() => {
    return authHasLoaded && userId !== undefined
  }, [authHasLoaded, userId])

  // Check if this content item has been purchased
  const isPurchased = useMemo(() => {
    if (!contentItem || arePurchasesLoading) {
      return
    }
    if (!purchases) {
      return false
    }
    for (const purchase of purchases) {
      if (contentItem.ref === purchase.ref) {
        return true
      }
    }
    return false
  }, [contentItem, arePurchasesLoading, purchases])

  // Check if this content item is in this profile library
  const isInLibrary = useMemo(() => {
    if (!contentItem || !profileContentRefs) {
      return
    }
    for (const profileContentRef of profileContentRefs) {
      if (profileContentRef === contentItem.ref) {
        return true
      }
    }
    return false
  }, [contentItem, profileContentRefs])

  // Check if the user has a payment method setup
  const hasPaymentMethod = useMemo(() => {
    return paymentDetails !== undefined
  }, [paymentDetails])

  // Get the sale price with currency symbol
  const salePrice = useSalePrice(contentItem)

  // Set a content item to jump back to if an item is selected
  const setJumpBackItem = useCallback(() => {
    if (selectedItem) {
      writeToSessionStorage(SessionStorageKey.JUMP_BACK_TO_ITEM, {
        type: selectedItem.type,
        id: selectedItem.id,
        slug: selectedItem.slug,
      })
    }
  }, [selectedItem])

  /**
   * Set the CTA for Library items
   */
  const setLibraryCTA = () => {
    dispatch({
      type: "CTA",
      payload: {
        isLoaded: true,
        text: REMOVE,
        isAvailable: true,
      },
    })
  }

  /**
   * Determine the CTA state for store items
   */
  const determineStoreCTA = useCallback(() => {
    // Prompt to sign in to get content
    const SIGN_IN_PROMPT = (
      <>
        You need to
        <Link
          to={routes.SIGN_IN}
          onClick={setJumpBackItem}
          className="underline mx-1.25 font-extrabold"
        >
          sign in
        </Link>
        or
        <Link
          to={routes.SIGN_UP}
          onClick={setJumpBackItem}
          className="underline mx-1.25 font-extrabold"
        >
          sign up
        </Link>
        to get this {contentItem?.type}.
      </>
    )

    // Prompt to st up a payment method
    const SETUP_PAYMENT_METHOD_PROMPT = (
      <>
        You need to
        <Link
          to={routes.PAYMENT_DETAILS}
          onClick={setJumpBackItem}
          className="underline mx-1.25 font-extrabold"
        >
          set up a payment method
        </Link>
        before you can buy this {contentItem?.type}.
      </>
    )

    switch (contentItem.type) {
      /**
       * Stations & Podcasts
       */
      case ContentType.STATION:
      case ContentType.PODCAST:
        // Not signed in
        if (!isSignedIn) {
          dispatch({
            type: "CTA",
            payload: {
              isLoaded: true,
              text: FREE,
              isAvailable: false,
              explanation: SIGN_IN_PROMPT,
            },
          })
        }
        // Is signed in | Not in library
        else if (isSignedIn && !isInLibrary) {
          dispatch({
            type: "CTA",
            payload: {
              isLoaded: true,
              text: FREE,
              isAvailable: true,
              isInLibrary: isInLibrary,
              explanation: `This ${contentItem.type} is free to add to ${activeProfileName}'s library.`,
            },
          })
        }
        // Is signed in | Is in library
        else if (isSignedIn && isInLibrary) {
          dispatch({
            type: "CTA",
            payload: {
              isLoaded: true,
              text: IN_LIBRARY,
              isAvailable: false,
              isInLibrary: isInLibrary,
              explanation: `This ${contentItem.type} has been added to ${activeProfileName}'s library.`,
            },
          })
        }
        // No matching criteria!
        else {
          console.warn(
            `Failed to construct a CTA for content item '${contentItem.ref}'!`
          )
          // Default safe fallback
          dispatch({
            type: "CTA",
            payload: {
              isLoaded: true,
              text: undefined,
              isAvailable: false,
              explanation: undefined,
            },
          })
        }
        break

      /**
       * Audiobooks
       */
      case ContentType.AUDIOBOOK:
        // Not signed in | Has sale price
        if (!isSignedIn && salePrice) {
          dispatch({
            type: "CTA",
            payload: {
              isLoaded: true,
              text: salePrice,
              isAvailable: false,
              explanation: SIGN_IN_PROMPT,
            },
          })
        }
        // Not signed in | No sale price
        else if (!isSignedIn && !salePrice) {
          dispatch({
            type: "CTA",
            payload: {
              isLoaded: true,
              text: UNAVAILABLE,
              isAvailable: false,
              explanation: SIGN_IN_PROMPT,
            },
          })
        }
        // Is signed in | Not purchased | In library
        else if (isSignedIn && !isPurchased && isInLibrary) {
          dispatch({
            type: "CTA",
            payload: {
              isLoaded: true,
              text: IN_LIBRARY,
              isAvailable: false,
              isInLibrary: isInLibrary,
              isPurchased: isPurchased,
              explanation: `This free ${contentItem.type} is in ${activeProfileName}'s library.`,
            },
          })
        }
        // Is signed in | Not purchased | Has Payment Method | Has sale price
        else if (isSignedIn && !isPurchased && hasPaymentMethod && salePrice) {
          dispatch({
            type: "CTA",
            payload: {
              isLoaded: true,
              text: salePrice,
              isAvailable: true,
              isInLibrary: isInLibrary,
              isPurchased: isPurchased,
              explanation: `This ${contentItem.type} is available to purchase.`,
            },
          })
        }
        // Is signed in | Not purchased| Has Payment Method | No sale price
        else if (isSignedIn && !isPurchased && hasPaymentMethod && !salePrice) {
          dispatch({
            type: "CTA",
            payload: {
              isLoaded: true,
              text: UNAVAILABLE,
              isAvailable: false,
              isInLibrary: isInLibrary,
              isPurchased: isPurchased,
              explanation: `Sorry, this ${contentItem.type} isn't currently available to purchase.`,
            },
          })
        }
        // Is signed in | Not purchased | No Payment Method | Has sale price
        else if (isSignedIn && !isPurchased && !hasPaymentMethod && salePrice) {
          dispatch({
            type: "CTA",
            payload: {
              isLoaded: true,
              text: salePrice,
              isAvailable: false,
              isInLibrary: isInLibrary,
              isPurchased: isPurchased,
              explanation: SETUP_PAYMENT_METHOD_PROMPT,
            },
          })
        }
        // Is signed in | Not purchased | No Payment Method | No sale price
        else if (
          isSignedIn &&
          !isPurchased &&
          !hasPaymentMethod &&
          !salePrice
        ) {
          dispatch({
            type: "CTA",
            payload: {
              isLoaded: true,
              text: UNAVAILABLE,
              isAvailable: false,
              isInLibrary: isInLibrary,
              isPurchased: isPurchased,
              explanation: `Sorry, this ${contentItem.type} isn't currently available to purchase.`,
            },
          })
        }
        // Is signed in | Is purchased | In library
        else if (isSignedIn && isPurchased && isInLibrary) {
          dispatch({
            type: "CTA",
            payload: {
              isLoaded: true,
              text: IN_LIBRARY,
              isAvailable: false,
              isInLibrary: isInLibrary,
              isPurchased: isPurchased,
              explanation: `This ${contentItem.type} has been added to ${activeProfileName}'s library.`,
            },
          })
        }
        // Is signed in | Is purchased | Not in library
        else if (isSignedIn && isPurchased && !isInLibrary) {
          dispatch({
            type: "CTA",
            payload: {
              isLoaded: true,
              text: GET,
              isAvailable: true,
              isInLibrary: isInLibrary,
              isPurchased: isPurchased,
              explanation: `This ${contentItem.type} was previously purchased and is available to add to ${activeProfileName}'s library.`,
            },
          })
        }
        // No matching criteria!
        else {
          console.warn(
            `Failed to construct a CTA for content item '${contentItem.ref}'!`
          )
          // Default safe fallback
          dispatch({
            type: "CTA",
            payload: {
              isLoaded: true,
              text: undefined,
              isAvailable: false,
              explanation: undefined,
            },
          })
        }
        break

      default:
        console.warn(`Unrecognised content type: ${contentItem.type}`)
        // Default safe fallback
        dispatch({
          type: "CTA",
          payload: {
            isLoaded: true,
            text: undefined,
            isAvailable: false,
            explanation: undefined,
          },
        })
    }
  }, [
    contentItem,
    activeProfileName,
    hasPaymentMethod,
    isInLibrary,
    isPurchased,
    isSignedIn,
    salePrice,
    setJumpBackItem,
  ])

  useEffect(() => {
    // Wait until we have a content item
    if (!contentItem) {
      return
    }

    // Wait until we know which sceen we're dealing with
    if (!screen) {
      return
    }

    // Wait until user auth has loaded, so we know whether to check for user data or not
    if (!authHasLoaded) {
      return
    }

    // Check to see if purchases are still loading
    if (userId && arePurchasesLoading) {
      return
    }

    // Check to see if profile content for user is still loading
    if (userId && isProfileContentLoading) {
      return
    }

    if (userId && arePaymentDetailsLoading) {
      return
    }

    // Determine which screen we're dealing with
    switch (screen) {
      case Screen.STORE:
      case Screen.SEARCH:
        determineStoreCTA()
        break
      case Screen.LIBRARY:
        setLibraryCTA()
        break
      default:
        console.warn(`Cannot set the CTA for unrecognised screen '${screen}'!`)
    }
  }, [
    contentItem,
    authHasLoaded,
    userId,
    arePurchasesLoading,
    isProfileContentLoading,
    arePaymentDetailsLoading,
    screen,
    determineStoreCTA,
  ])

  return state
}
