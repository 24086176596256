import { useLocation } from "@reach/router"
import Button from "components/controls/button"
import Logo from "components/icons/logo"
import PageTitle from "components/pageTitle"
import BreakpointContext from "context/breakpointContext"
import { useLayoutContext } from "context/layoutContext"
import { useProfileContext } from "context/profileContext"
import { useUserContext } from "context/userContext"
import { Link, navigate } from "gatsby"
import { constructAvatar } from "helpers/constructAvatar"
import { size } from "helpers/responsiveSize"
import { useMediaQueries } from "hooks/useMediaQueries"
import { useScrollingPrevention } from "hooks/useScrollingPrevention"
import HamburgerMenuIcon from "images/hamburger-menu-icon.svg"
import React, { memo, useContext, useMemo, useState } from "react"
import routes from "services/routes"
import MobileNavigationSidebar from "./mobileNavigationSidebar"
import MobileProfilesSidebar from "./mobileProfilesSidebar"

const NavbarMobile = memo(
  ({
    isScrolled,
    animationDuration = 300, // Duration of fade animation
  }) => {
    // The current breakpoint
    const bpt = useContext(BreakpointContext)

    // Grab the location from Reach Router
    const location = useLocation()

    // Media queries
    const { isMobile, isTablet } = useMediaQueries()

    // State of navigation sidebar display
    const [isNavSidebarDisplayed, setNavSidebarDisplayed] = useState(false)

    // State of profile switcher sidebar display
    const [isProfilesSidebarDisplayed, setProfilesSidebarDisplayed] =
      useState(false)

    // Prevent scrolling if the menu is displayed
    useScrollingPrevention(isNavSidebarDisplayed || isProfilesSidebarDisplayed)

    // User state
    const { hasLoaded, userId } = useUserContext()

    // Active profile
    const { activeProfileAvatar } = useProfileContext()

    // Layout context items
    const { navbarTitle } = useLayoutContext()

    /**
     * Determine whether to display the center-aligned
     * items on the navbar.
     */
    const displayCenterAlignedItems = useMemo(() => {
      // Check if this is the home page
      if (location.pathname === routes.HOME) {
        if (isTablet) {
          return true
        } else if (isMobile) {
          // Hide items on mobbile if we need room for auth buttons
          if (isScrolled && hasLoaded && !userId) {
            return false
          } else {
            return true
          }
        }
      } else {
        if (isScrolled) {
          return false
        } else {
          return true
        }
      }
    }, [isScrolled, isMobile, isTablet, location.pathname, hasLoaded, userId])

    /**
     * Determine whether to display the Sign In/Sign Up
     * buttons on the navbar.
     */
    const displayAuthButtons = useMemo(() => {
      if (hasLoaded && userId) {
        // User is signed in!
        return false
      } else {
        // Check if this is the home page
        if (location.pathname === routes.HOME) {
          if (isTablet) {
            // Always display auth buttons on a tablet, as we have the room
            return true
          } else if (isMobile && isScrolled) {
            // Only display the auth buttons once we're scrolling, as the logo will vanish
            return true
          }
        } else {
          // Don't display buttons anywhere but home page
          return false
        }
      }
    }, [hasLoaded, userId, location.pathname, isScrolled, isMobile, isTablet])

    /**
     * Determine whether to display the hamburger menu
     */
    const displayHamburgerMenu = useMemo(() => {
      if (navbarTitle && isScrolled) {
        return false
      } else {
        return true
      }
    }, [navbarTitle, isScrolled])

    return (
      <>
        {/* Navigation Sidebar */}
        <MobileNavigationSidebar
          isSidebarDisplayed={isNavSidebarDisplayed}
          setSidebarDisplayed={setNavSidebarDisplayed}
          animationDuration={animationDuration}
        />

        {/* Profile Switcher Sidebar */}
        <MobileProfilesSidebar
          isSidebarDisplayed={isProfilesSidebarDisplayed}
          setSidebarDisplayed={setProfilesSidebarDisplayed}
          animationDuration={animationDuration}
        />

        {/* Navbar content */}
        <div
          className="
            laptop:hidden
            h-full
            grid grid-cols-3
            mx-m-offsets-fluid
            tablet:mx-t-offsets-fluid
            items-center justify-center"
        >
          {/* Left-aligned items */}
          <div className="flex items-center">
            {navbarTitle && (
              <PageTitle
                title={navbarTitle}
                isNavbar={true}
                style={{
                  opacity: navbarTitle && isScrolled ? 100 : 0,
                }}
                className="absolute
                transition-opacity duration-100 ease-in-out"
              />
            )}

            {/* Hamburger Button */}
            <Button
              flat={true}
              icon={<HamburgerMenuIcon />}
              onClick={() => {
                if (displayHamburgerMenu) {
                  setNavSidebarDisplayed(true)
                }
              }}
              style={{
                height: size(bpt, "7vw", "4.5vw"),
                width: size(bpt, "7vw", "4.5vw"),
                opacity: displayHamburgerMenu ? 100 : 0,
              }}
              className="absolute transition-opacity duration-100 ease-in-out"
            />
          </div>

          {/* Center-aligned items */}
          <div
            style={{
              visibility: displayCenterAlignedItems ? "visible" : "hidden",
            }}
            className="justify-self-center z-10"
          >
            {/* Logo */}
            <Link to="/">
              <Logo className="w-18 h-9" />
            </Link>
          </div>

          {/* Right-aligned items */}
          <div className="justify-self-end flex flex-row">
            {/* Profile Button */}
            {hasLoaded && userId && activeProfileAvatar && (
              <Button
                shadowHeight={3}
                flat={userId ? false : true}
                icon={constructAvatar(activeProfileAvatar)}
                onClick={() => {
                  setProfilesSidebarDisplayed(true)
                }}
                style={{
                  height: size(bpt, "9vw", "4.5vw"),
                  width: size(bpt, "9vw", "4.5vw"),
                }}
              />
            )}

            {/* Sign in/up buttons, if no user present */}
            {displayAuthButtons && (
              <div
                // style={{
                //   visibility: displayAuthButtons ? "visible" : "hidden",
                // }}
                className="flex flex-row"
              >
                <Button
                  text="Sign in"
                  size="xs"
                  flat={true}
                  shadowHeight={2}
                  onClick={() => {
                    navigate(routes.SIGN_IN)
                  }}
                  className="w-20 h-4"
                />
                <Button
                  type="primary"
                  text="Sign up"
                  size="xs"
                  shadowHeight={2}
                  onClick={() => {
                    navigate(routes.SIGN_UP)
                  }}
                  className="w-20 h-4"
                />
              </div>
            )}
          </div>
        </div>
      </>
    )
  }
)

export default NavbarMobile
NavbarMobile.whyDidYouRender = true
