import Button from "components/controls/button"
import BreakpointContext from "context/breakpointContext"
import { ContentType } from "helpers/contentTypes"
import { size } from "helpers/responsiveSize"
import React, { useContext, useState } from "react"

//
// The description of the content item
//
const Description = (props) => {
  // Props
  const { contentType, contentItem } = props

  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  // See more detail
  const [showMore, setShowMore] = useState(false)

  return (
    <>
      {contentItem.description && (
        <>
          <p
            style={{
              fontSize: size(bpt, "3.3vw"),
              marginTop: size(bpt, "5vw"),
            }}
            className="
              tablet:text-sm laptop:text-base
              ml-1 tablet:ml-0 laptop:ml-1
              tablet:mt-4 laptop:mt-7
              font-bold"
          >
            About this
            {contentType === ContentType.AUDIOBOOK
              ? " audiobook"
              : contentType === ContentType.PODCAST
              ? " podcast"
              : contentType === ContentType.STATION
              ? " radio station"
              : ""}
          </p>
          <div
            style={{
              fontSize: size(bpt, "3.7vw"),
              letterSpacing: size(bpt, "0.07vw", "0.19px", "0.19px", "0.19px"),
              lineHeight: size(bpt, "5vw", "18px", "19px", "20px"),
            }}
            className={`
              tablet:text-sm laptop:text-base
              tablet:mt-1 laptop:mt-1 desktop:mt-0
              ml-1 tablet:ml-0 laptop:ml-1
              no-embedded-styling
              ${showMore ? "" : "line-clamp-5 tablet:line-clamp-3"}
            `}
            dangerouslySetInnerHTML={{
              __html: contentItem.description,
            }}
          ></div>

          {/* More button */}
          <Button
            shadowHeight={1}
            text={showMore ? "Less" : "More"}
            size={size(bpt, undefined, "xs", "sm", "sm")}
            style={{
              marginTop: size(bpt, "2vw", "1vw", "1vw", "1vw"),
              marginBottom: size(bpt, "2vw", "1vw", "1vw", "1vw"),
              height: size(bpt, "5vw"),
              width: size(bpt, "20vw", "10vw", "10vw", "8vw"),
            }}
            styleInner={{
              fontSize: size(bpt, "3vw", "1.5vw", "1.2vw", "1vw"),
            }}
            className="self-end"
            classNameInner="font-extrabold"
            onClick={() => {
              setShowMore(!showMore)
            }}
          />
        </>
      )}
    </>
  )
}

export default Description
