/**
 * Route constants
 */
const routes = {
  // Home
  HOME: "/",

  // 404 Not Found
  NOT_FOUND: "/404",

  //
  // AUTH
  // ------------------------------
  //

  // Auth actions from firebase emails
  AUTH_ACTION: "/app/auth-action",

  // Sign-in
  SIGN_IN: "/app/sign-in/",

  // Sign-up
  SIGN_UP: "/app/sign-up/",

  // Reset password (Step 1: send reset link)
  RESET_PASSWORD_STEP_1: "/app/reset-password/step-1",

  // Reset password (Step 2: change password)
  RESET_PASSWORD_STEP_2: "/app/reset-password/step-2",

  //
  // ACCOUNT
  // ------------------------------
  //

  // Account overview
  ACCOUNT_OVERVIEW: "/app/account",

  // Payment details
  PAYMENT_DETAILS: "/app/account/payment",

  // Purchases
  PURCHASES: "/app/account/purchases",

  //
  // PAYMENT
  // ------------------------------
  //

  // Bank authentication complete
  BANK_AUTH_COMPLETE: "/app/bank-auth-complete",

  //
  // SETUP
  // ------------------------------
  //

  // Setup: Superfonik account creation confirmation
  SETUP_1_CONFIRMATION: "/app/setup/confirmation",

  // Setup: Platform overview for grown-ups
  SETUP_2_FOR_GROWN_UPS: "/app/setup/for-grown-ups",

  // Setup: Platform overview for children
  SETUP_3_FOR_CHILDREN: "/app/setup/for-children",

  // Setup: Create profile
  SETUP_4_PROFILE: "/app/setup/profiles",

  // Setup: Choose profile avatar
  SETUP_5_PROFILE_AVATAR: "/app/setup/profiles/avatars",

  // Setup: Create a profile
  SETUP_6_PROFILE_CREATE: "/app/setup/profiles/create",

  // Setup: Add more profiles
  SETUP_7_PROFILE_ADD: "/app/setup/profiles/add",

  // Setup: Choose device
  SETUP_8_DEVICE: "/app/setup/device",

  // Setup: Get player app
  SETUP_9_PLAYER_GET: "/app/setup/player/get",

  // Setup: Register player app
  SETUP_10_PLAYER_REGISTER: "/app/setup/player/sign-in",

  // Setup: Get content
  SETUP_11_GET_CONTENT: "/app/setup/content",

  //
  // PROFILES
  // ------------------------------
  //

  // Choose Profile
  CHOOSE_PROFILE: "/app/profiles/choose",

  // Choose an Avatar (profile setup)
  CHOOSE_AVATAR: "/app/profiles/avatars/",

  // Choose Profile
  EDIT_PROFILES: "/app/profiles/edit",

  // Edit an existing profile
  EDIT_PROFILE: "/app/profile/edit/",

  // Create a Profile (profile setup)
  CREATE_PROFILE: "/app/profiles/create/",

  // -----------------------------

  // STORE
  STORE_CHOOSER: "/store/choose",
  STORE_WARNING: "/store/warning",
  STORE: (storeURL) =>
    storeURL ? `/${storeURL}/store/` : routes.STORE_CHOOSER,
  STORE_FEATURED: (storeURL) =>
    storeURL ? `/${storeURL}/store/featured/` : routes.STORE_CHOOSER,
  STORE_COLLECTION: (storeURL, slug) =>
    storeURL ? `/${storeURL}/store/collection/${slug}` : routes.STORE_CHOOSER,
  STORE_AUDIOBOOKS: (storeURL) =>
    storeURL ? `/${storeURL}/store/audiobooks/` : routes.STORE_CHOOSER,
  STORE_PODCASTS: (storeURL) =>
    storeURL ? `/${storeURL}/store/podcasts/` : routes.STORE_CHOOSER,
  STORE_STATIONS: (storeURL) =>
    storeURL ? `/${storeURL}/store/stations/` : routes.STORE_CHOOSER,

  // STORE SEARCH
  STORE_SEARCH: (storeURL) =>
    storeURL ? `/${storeURL}/store/search/` : routes.STORE_CHOOSER,

  // LIBRARY
  LIBRARY: "/app/library/",
  LIBRARY_AUDIOBOOKS: "/app/library/audiobooks/",
  LIBRARY_PODCASTS: "/app/library/podcasts/",
  LIBRARY_STATIONS: "/app/library/stations/",

  // -----------------------------

  // Privacy Policy
  PRIVACY_POLICY: "/privacy",

  // Terms & Conditions
  TsANDCs: "/terms",
}

export default routes
