import React from "react"

const YellowFaceAvatarIcon = (props) => {
  const { style, className } = props

  return (
    <div style={style ? style : {}} className={className ? className : ""}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="15 15 140 140"
      >
        <defs>
          <linearGradient
            id="y7arc7wvbc"
            x1="100%"
            x2="0%"
            y1="100%"
            y2="-9.901%"
          >
            <stop offset="0%" />
            <stop offset="100%" stopColor="#FFF" />
          </linearGradient>
          <rect id="mry3t4e9pb" width="140" height="140" x="0" y="0" rx="70" />
          <path
            id="8ub2ypii1d"
            d="M0.044 0.009L48.044 0.009 48.044 30 0.044 30z"
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <g>
            <g>
              <g transform="translate(-718 -391) translate(733 406)">
                <use
                  fill="#000"
                  filter="url(#r9ddhy4ksa)"
                  xlinkHref="#mry3t4e9pb"
                />
                <use fill="#FDDF08" xlinkHref="#mry3t4e9pb" />
                <use
                  fill="url(#y7arc7wvbc)"
                  fillOpacity=".4"
                  style={{ mixBlendMode: "soft-light" }}
                  xlinkHref="#mry3t4e9pb"
                />
              </g>
              <g>
                <g transform="translate(-718 -391) translate(733 406) translate(47 42) translate(0 40)">
                  <mask id="bit1uuxg0e" fill="#fff">
                    <use xlinkHref="#8ub2ypii1d" />
                  </mask>
                  <path
                    fill="#655903"
                    d="M13.672 8.009H26.42v7.256c0 3.172-2.164 6.128-5.296 6.648-3.988.66-7.452-2.42-7.452-6.284v-7.62zm34.372-4c0-2.212-1.788-4-4-4h-40c-2.208 0-4 1.788-4 4 0 2.208 1.792 4 4 4h1.628v7.62c0 8.912 8.144 15.948 17.384 14.064 6.72-1.372 11.364-7.608 11.364-14.464v-7.22h9.624c2.212 0 4-1.792 4-4z"
                    mask="url(#bit1uuxg0e)"
                  />
                </g>
                <path
                  fill="#655903"
                  d="M45.002 0C51.078 0 56 4.925 56 11s-4.922 11-10.998 11C38.922 22 34 17.075 34 11S38.922 0 45.002 0M11 0c6.075 0 11 4.925 11 11s-4.925 11-11 11S0 17.075 0 11 4.925 0 11 0"
                  transform="translate(-718 -391) translate(733 406) translate(47 42)"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default YellowFaceAvatarIcon
