//
// Log a "sign_up" event when a user
// signs up for the service.
//
export const captureSignUp = () => {
  if (window.gtag) {
    window.gtag("event", "sign_up", {
      method: "Email",
    })
  }
}

//
// Log a "select_content" event when a user
// looks at a content item.
//
export const captureContentSelected = (
  contentType,
  contentId,
  contentTitle,
  currency,
  price,
  supplier
) => {
  if (window.gtag) {
    // Select Content
    window.gtag("event", "select_content", {
      content_type: contentType,
      item_id: contentId,
    })
    // View Item: https://developers.google.com/analytics/devguides/collection/ga4/reference/events#view_item
    window.gtag("event", "view_item", {
      currency: currency,
      value: price,
      items: [
        {
          item_id: contentId,
          item_name: contentTitle,
          item_category: contentType,
          item_brand: supplier,
        },
      ],
    })
  }
}

//
// Log a "purchase" event
//
export const captureContentItemPurchased = (
  contentType,
  contentId,
  title,
  supplier,
  saleCurrency,
  salePriceIncTax,
  salePriceExTax,
  transactionId
) => {
  if (contentType === undefined) {
    console.error("Cannot capture sales metric, as content type is undefined!")
    return
  }
  if (contentId === undefined) {
    console.error("Cannot capture sales metric, as content id is undefined!")
    return
  }
  if (title === undefined) {
    console.error("Cannot capture sales metric, as content title is undefined!")
    return
  }
  if (supplier === undefined) {
    console.error(
      "Cannot capture sales metric, as content supplier is undefined!"
    )
    return
  }
  if (saleCurrency === undefined) {
    console.error("Cannot capture sales metric, as sale currency is undefined!")
    return
  }
  if (salePriceIncTax === undefined) {
    console.error(
      "Cannot capture sales metric, as sale price inc tax is undefined!"
    )
    return
  }
  if (salePriceExTax === undefined) {
    console.error(
      "Cannot capture sales metric, as sale price ex tax is undefined!"
    )
    return
  }
  if (transactionId === undefined) {
    console.error(
      "Cannot capture sales metric, as transaction id is undefined!"
    )
    return
  }
  // Capture purchase in Google Analytics
  if (window.gtag) {
    window.gtag("event", "purchase", {
      currency: saleCurrency,
      transaction_id: transactionId,
      value: salePriceIncTax,
      tax: salePriceIncTax - salePriceExTax,
      items: [
        {
          item_id: contentId,
          item_name: title,
          item_category: contentType,
          item_category2: supplier,
          quantity: 1,
        },
      ],
    })
  }
}
