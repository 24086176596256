import React from "react"

const PurpleFaceAvatarIcon = (props) => {
  const { style, className } = props

  return (
    <div style={style ? style : {}} className={className ? className : ""}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="15 15 140 140"
      >
        <defs>
          <linearGradient
            id="w6pisa2w2c"
            x1="100%"
            x2="0%"
            y1="100%"
            y2="-9.901%"
          >
            <stop offset="0%" />
            <stop offset="100%" stopColor="#FFF" />
          </linearGradient>
          <rect id="pwjp4q29yb" width="140" height="140" x="0" y="0" rx="70" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <g>
            <g>
              <g transform="translate(-390 -391) translate(405 406)">
                <use
                  fill="#000"
                  filter="url(#1ezbl5kp4a)"
                  xlinkHref="#pwjp4q29yb"
                />
                <use fill="#8D82FB" xlinkHref="#pwjp4q29yb" />
                <use
                  fill="url(#w6pisa2w2c)"
                  fillOpacity=".4"
                  style={{ mixBlendMode: "soft-light" }}
                  xlinkHref="#pwjp4q29yb"
                />
              </g>
              <g fill="#271C95">
                <g>
                  <path
                    d="M48 4.047C48 1.81 46.208 0 44 0H4C1.788 0 0 1.81 0 4.047c0 2.234 1.788 4.047 4 4.047h1.624v.368c0 9.013 8.144 16.136 17.388 14.226 6.72-1.384 11.36-7.694 11.36-14.634v.04H44c2.208 0 4-1.813 4-4.047"
                    transform="translate(-390 -391) translate(405 406) translate(30 52)"
                  />
                  <path
                    d="M79 4.047C79 1.81 77.212 0 75 0H35c-2.208 0-4 1.81-4 4.047 0 2.234 1.792 4.047 4 4.047h1.628v.368c0 9.013 8.144 16.136 17.388 14.226 6.72-1.384 11.36-7.694 11.36-14.634v.04H75c2.212 0 4-1.813 4-4.047"
                    transform="translate(-390 -391) translate(405 406) translate(30 52)"
                  />
                </g>
                <path
                  d="M54.85 38.113l-25.068 4.798c-2.14.468-4.245-.955-4.697-3.17-.448-2.218.923-4.392 3.068-4.855l25.068-4.798c2.14-.464 4.241.951 4.693 3.166.452 2.218-.92 4.396-3.064 4.86"
                  transform="translate(-390 -391) translate(405 406) translate(30 52)"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default PurpleFaceAvatarIcon
