import { useMediaQueries } from "hooks/useMediaQueries"
import React, { memo, useEffect, useState } from "react"
import { useInView } from "react-intersection-observer"
import NavbarDesktop from "./desktop/navbarDesktop"
import NavbarMobile from "./mobile/navbarMobile"

export const Titles = {
  STORE: "Store",
  LIBRARY: "Library",
}

export const Subtitles = {
  FEATURED: "Featured",
  ALL_ITEMS: "All Items",
  AUDIOBOOKS: "Audiobooks",
  PODCASTS: "Podcasts",
  STATIONS: "Radio",
}

export const SubtitleColours = {
  FEATURED: "purple",
  ALL_ITEMS: "purple",
  AUDIOBOOKS: "blue",
  PODCASTS: "orange",
  STATIONS: "red",
}

const Navbar = memo(() => {
  // Breakpoints
  const { isMobile, isTablet, isLaptop, isDesktop } = useMediaQueries()

  // Intersection Observer
  const { ref: stickyHeaderMarkerRef, inView: isHeaderInView } = useInView({
    threshold: 1, // Immediate
    initialInView: true,
  })

  // Scrolling state
  const [ticking, setTicking] = useState(false)
  const [scrolledToTop, setScrolledToTop] = useState(true)

  // Watch scroll position
  useEffect(() => {
    const onScroll = (e) => {
      const scrollPosition = window.scrollY
      if (!ticking) {
        // Scroll event throttling; see: https://developer.mozilla.org/en-US/docs/Web/API/Document/scroll_event
        window.requestAnimationFrame(() => {
          if (scrollPosition === 0) {
            setScrolledToTop(true)
          } else {
            setScrolledToTop(false)
          }
          setTicking(false)
        })
        setTicking(true)
      }
    }
    window.addEventListener("scroll", onScroll, { passive: true })

    return function cleanup() {
      // Clean up scroll listener
      window.removeEventListener("scroll", onScroll)
    }
  })

  return (
    <>
      <nav
        className={`
          fixed
          top-0 left-0 right-0
          h-15 laptop:h-20
          z-10
          bg-grey-light
          ${scrolledToTop ? "shadow-sm" : "shadow-md"}
        `}
      >
        {/* Initial content */}
        <div
          className="
            absolute top-0 inset-x-0 h-full z-10"
        >
          {/* Navbar for Mobile & Tablets */}
          {(isMobile || isTablet) && (
            <NavbarMobile isScrolled={!isHeaderInView} />
          )}

          {/* Navbar for Laptops & Desktops */}
          {(isLaptop || isDesktop) && (
            <NavbarDesktop isScrolled={!isHeaderInView} />
          )}
        </div>
      </nav>
      <div
        id="sticky-header-marker-ref"
        ref={stickyHeaderMarkerRef}
        className="
          absolute inset-x-0
          top-8 tablet:top-11 laptop:top-15"
      />
    </>
  )
})

export default Navbar
Navbar.whyDidYouRender = true
