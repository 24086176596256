import Button from "components/controls/button"
import Checkbox from "components/controls/checkbox"
import Input from "components/controls/input"
import AudiobookIcon from "components/icons/contentTypes/audiobookIcon"
import ModalDialog from "components/utils/modalDialog"
import RemoteImage from "components/utils/remoteImage"
import BreakpointContext from "context/breakpointContext"
import { useCountries } from "context/countryContext"
import { useUserContext } from "context/userContext"
import { Link } from "gatsby"
import { captureContentItemPurchased } from "helpers/analyticsHelper"
import { getCurrencySymbol } from "helpers/currencySymbols"
import { size } from "helpers/responsiveSize"
import { useMediaQueries } from "hooks/useMediaQueries"
import { useSalePrice } from "hooks/useSalePrice"
import { useScrollingPrevention } from "hooks/useScrollingPrevention"
import PropTypes from "prop-types"
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useReauthenticateBeforePaymentMutation } from "reactQuery/mutations/reauthenticateBeforePaymentMutation"
import { useUserAccountQuery } from "reactQuery/queries/userAccountQuery"
import { signInWithEmailAndPassword } from "services/auth"
import {
  cancelPaymentIntent,
  purchaseAudiobook,
  validateDiscountCode,
} from "services/cloudFunctions"
import routes from "services/routes"

/**
 * A modal dialog body that allows a user to confirm a purchase
 *
 * When a user clicks "Pay", we call the "purchaseAudiobook" cloud function.
 * That function will attempt a purchase using the card details stored at Stripe.
 *
 * If successful, this dialog will immediately invoke the "onPaymentSuccess" callback.
 *
 * If the bank request authorisation however, this dialog will host the bank's auth page
 * in an <iframe>.  The bank auth will succeed or fail and eventually call the "bankAuthComplete"
 * page, which is still embedded in the <iframe>.  That page will post a message to this
 * dialog, which is listening for it.
 * Once the confirmation is received, this dialog will once again call the "purchaseAudiobook"
 * cloud function, to see if the auth was successful and complete the purchase!
 */

const PaymentDialog = ({
  selectedItem,
  onCancelClicked,
  onPaymentSuccess,
  bankAuthPaymentIntentId,
  setBankAuthPaymentIntentId,
}) => {
  // Auth state
  const { lastSignInTime, email } = useUserContext()

  // Store country, for pricing code
  const { storeCountry } = useCountries()

  // Media queries for iframe sizing
  const { isMobile } = useMediaQueries()

  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  // Discounted to free
  const FREE = "Free!"

  // Is reauthenticating state
  const [isReauthenticating, setIsReauthenticating] = useState(false)

  // Is paying state
  const [isPaying, setIsPaying] = useState(false)

  // Is applying discount state
  const [isApplyingDiscount, setIsApplyingDiscount] = useState(false)

  // The user's password for purchase
  const [purchasePassword, setPurchasePassword] = useState(undefined)

  // Purchase password error
  const [passwordError, setPasswordError] = useState(undefined)

  // Purchase error
  const [purchaseError, setPurchaseError] = useState(undefined)

  // Redirect URL for bank authorisation
  const [redirectURL, setRedirectURL] = useState(undefined)

  // Display the bank authorisation frame
  const [showBankAuth, setShowBankAuth] = useState(false)

  // A supplied discount code
  const [discountCode, setDiscountCode] = useState(undefined)

  // Dicount code error
  const [discountCodeError, setDiscountCodeError] = useState(undefined)

  // Discounted price, if any
  const [discountedPrice, setDiscountedPrice] = useState(undefined)

  // The current Payment Intent ID
  const [currentPaymentIntentId, setCurrentPaymentIntentId] =
    useState(undefined)

  // Get the sale price with currency symbol
  const salePrice = useSalePrice(selectedItem)

  // The state of asking for a password during purchasing
  const [
    doNotReauthenticateBeforePayment,
    setDoNotReauthenticateBeforePayment,
  ] = useState(false)

  // User context
  const { userId } = useUserContext()

  // User account query
  const { isLoading: isUserAccountDataLoading, data: userAccount } =
    useUserAccountQuery(userId)

  // Prevent background from scrolling
  useScrollingPrevention(true)

  //
  // Determine if the user should reauthenticate before purchasing
  //
  const shouldUserReauthenticate = useMemo(() => {
    if (isUserAccountDataLoading) {
      return
    }

    // Check to see if the user has foregone re-authentication
    if (!userAccount?.reauthenticateBeforePayment) {
      return false
    }

    // Work out how many minutes have elapsed since the last authentication
    const tNow = new Date()
    const tLastSignIn = Date.parse(lastSignInTime)
    const diffMs = +tLastSignIn - +tNow
    const diffMins = Math.abs(Math.floor(diffMs / 1000 / 60))

    // Extract reauth timeout from config
    const reauthTimeout = process.env.GATSBY_PURCHASE_REAUTH_TIMEOUT
    return diffMins > reauthTimeout
  }, [
    isUserAccountDataLoading,
    userAccount?.reauthenticateBeforePayment,
    lastSignInTime,
  ])

  //
  // Determine the max width of the payment dialog,
  // depending on whether we are showing the Bank Auth
  // iframe, which must accommodate the standard 3D Secure
  // page sizes
  //
  const dialogWidth = useMemo(() => {
    if (showBankAuth) {
      return "max-w-bank-auth-sm tablet:max-w-bank-auth-lg"
    } else
      return "w-dialog-xs tablet:w-dialog-sm laptop:w-dialog-md desktop:w-dialog-lg"
  }, [showBankAuth])

  //
  // Determine if the primary button should be disabled
  //
  const isPrimaryButtonDisabled = useMemo(() => {
    // Wait for account data to load
    if (isUserAccountDataLoading) {
      return true
    }
    // Wait for discount code to be applied
    if (isApplyingDiscount) {
      return true
    }
    // Disable if the user has put in a discount code but has not applied it yet
    if (
      discountCode !== undefined &&
      discountCode !== "" &&
      discountedPrice === undefined
    ) {
      return true
    }
    // Disable if the user needs to reauthenticate but hasn't put in the password yet
    if (
      shouldUserReauthenticate &&
      (purchasePassword === undefined || purchasePassword === "")
    ) {
      return true
    }
    return false
  }, [
    isUserAccountDataLoading,
    shouldUserReauthenticate,
    purchasePassword,
    discountCode,
    discountedPrice,
    isApplyingDiscount,
  ])

  // Determine the text on the primary button, depending on whether
  // the user should reauthenticate or not.
  const primaryButtonText = useMemo(() => {
    if (shouldUserReauthenticate) {
      return "Unlock"
    }
    if (discountedPrice === FREE) {
      return "Get"
    } else {
      return "Pay"
    }
  }, [shouldUserReauthenticate, discountedPrice])

  // Mutate 'reauthenticateBeforePayment' field on user account
  const reauthenticateBeforePaymentMutation =
    useReauthenticateBeforePaymentMutation()

  //
  // Apply a given discount code
  //
  const applyDiscount = async () => {
    // Start spinner...
    setIsApplyingDiscount(true)

    try {
      // Validate the discount code and get new prices
      const discountPricesData = await validateDiscountCode(
        discountCode,
        storeCountry,
        selectedItem.ref
      )

      // Stop spinner
      setIsApplyingDiscount(false)

      // Clear existing error message
      setDiscountCodeError(undefined)

      // Extract the discounted prices
      const discountedPrices = discountPricesData?.data
      if (discountedPrices === undefined) {
        setDiscountCodeError("Code cannot be applied!")
        return
      }

      // Check if pricing is available
      if (discountedPrices.isAvailable) {
        if (discountedPrices.salePriceIncTax === undefined) {
          setDiscountCodeError("Code cannot be applied!")
          return
        } else if (discountedPrices.salePriceIncTax === 0) {
          setDiscountedPrice(FREE)
        } else {
          // Determine currency symbol
          const currencySymbol = getCurrencySymbol(discountedPrices.currency)
          // Display sale price inc tax, with currency symbol, to 2 decimal places
          setDiscountedPrice(
            `${currencySymbol}${discountedPrices.salePriceIncTax.toFixed(2)}`
          )
        }
      } else {
        setDiscountCodeError("Code cannot be applied!")
        return
      }
    } catch (error) {
      // Stop spinner
      setIsApplyingDiscount(false)

      // Clear any existing discount price
      setDiscountedPrice(undefined)

      // Set error message
      switch (error.details?.code) {
        case "no-such-code":
          setDiscountCodeError("We don't recognise that code!")
          break
        case "no-associated-coupon":
        case "no-such-coupon":
        case "invalid-coupon":
          setDiscountCodeError("Sorry, that code isn't working!")
          break
        case "maximum-applications-reached":
          setDiscountCodeError("You've used that code already!")
          break
        default:
          setDiscountCodeError("Sorry, that code isn't working!")
          break
      }
    }
  }

  //
  // Handle a click of the primary button
  //
  const handlePrimaryButtonClicked = () => {
    if (shouldUserReauthenticate) {
      // Prompt for authentication
      reauthenticate()
    } else {
      // Try to make the purchase
      attemptPurchase(currentPaymentIntentId)
    }
  }

  //
  // Reauthenticate the user
  //
  const reauthenticate = async () => {
    // Start spinner...
    setIsReauthenticating(true)

    try {
      // Attempt to reauthenticate
      await signInWithEmailAndPassword(email, purchasePassword)

      // Clear the password
      setPurchasePassword(undefined)

      // Stop spinner
      setIsReauthenticating(false)

      // Update reauthentication flag (best effort)
      reauthenticateBeforePaymentMutation.mutate({
        reauthenticateBeforePayment: !doNotReauthenticateBeforePayment,
      })
    } catch (error) {
      // Stop spinner
      setIsReauthenticating(false)
      switch (error.code) {
        case "auth/wrong-password":
          setPasswordError("Incorrect password!")
          return
        case "auth/too-many-requests":
          setPasswordError("Incorrect password!")
          setPurchaseError(error.message)
          return
        default:
          setPurchaseError(error.message)
          return
      }
    }
  }

  //
  // Handle a Cancel click by cancelling any
  // PaymentIntent that is current Incomplete
  //
  const handleCancelClicked = () => {
    if (currentPaymentIntentId) {
      // Cancel the Payment Intent in the background
      cancelPaymentIntent(currentPaymentIntentId)

      // Forget this payment intent Id
      setCurrentPaymentIntentId(undefined)
    }

    // Clear any discount code
    setDiscountCode(undefined)

    // Clear error messages
    setDiscountCodeError(undefined)
    setPasswordError(undefined)

    // Invoke callback
    onCancelClicked()
  }

  //
  // Attempt to make a purchase at the service using the payment method
  // stored by Stripe.
  //
  // If a payment intent was previously created, reuse it.
  //
  const attemptPurchase = useCallback(
    async (paymentIntentId) => {
      // Make sure payment is possible
      if (isPrimaryButtonDisabled) {
        return
      }

      // Indicate that we are paying
      setIsPaying(true)

      // Reset all purchase state
      setCurrentPaymentIntentId(undefined)
      setPasswordError(undefined)
      setPurchaseError(undefined)
      setRedirectURL(undefined)
      setShowBankAuth(false)

      try {
        // Capture existing Payment Intent ID
        if (paymentIntentId) {
          setCurrentPaymentIntentId(paymentIntentId)
        }

        // Clear previous purchase errors
        setPurchaseError(undefined)

        // Remove the redirection URL
        setRedirectURL(undefined)

        // Make sure bank auth screen is hidden
        setShowBankAuth(false)

        // Attempt to make the purchase
        const response = await purchaseAudiobook(
          origin,
          selectedItem.ref,
          storeCountry,
          discountCode,
          paymentIntentId
        )

        // Capture the PaymentIntent Id generated by the cloud function
        setCurrentPaymentIntentId(response.data?.paymentIntentId)

        // Payment status
        const status = response.data?.status
        switch (status) {
          case "redirect-to-bank-auth":
            // Halt proceedings if the bank auth failed and cancel the PaymentIntent
            const bankAuthFailed = response.data?.bankAuthFailed
            if (bankAuthFailed) {
              setIsPaying(false)
              setShowBankAuth(false)
              // TODO: nuke
              // setPasswordCaptured(false)
              setCurrentPaymentIntentId(undefined)
              setPurchaseError(
                <>
                  Sorry, your bank could not authenticate you, so this payment
                  has failed. Please try again or
                  <Link className="mx-1 underline" to={routes.PAYMENT_DETAILS}>
                    update your payment details.
                  </Link>
                </>
              )
              // Cancel the Payment Intent in the background
              cancelPaymentIntent(paymentIntentId)
              // Bail out early!
              return
            }
            // Extract the Bank Auth URL
            const bankAuthURL = response.data?.bankAuthURL
            if (!bankAuthURL) {
              // TODO: payment error!
            }
            // Set the redirection URL for bank authorisation
            setRedirectURL(bankAuthURL)
            // Display the bank auth frame
            setShowBankAuth(true)
            break
          case "success":
            // Log a purchase event in Google Analytics, unless this is a demo account or the discount made it free!
            if (userAccount?.demoAccount) {
              console.debug(
                "Won't capture purchase analytics event, as this is a demo account!"
              )
            } else if (discountedPrice === FREE) {
              console.debug(
                "Won't capture purchase analytics event, as the discount made the content item free!"
              )
            } else {
              captureContentItemPurchased(
                selectedItem.type,
                selectedItem.id,
                selectedItem.title,
                selectedItem.supplier,
                selectedItem.saleCurrency,
                selectedItem.salePriceIncTax,
                selectedItem.salePriceExTax,
                response.data?.paymentIntentId
              )
            }
            // Call success callback
            onPaymentSuccess()
            return
          default:
            console.error(`Unrecognised payment status: '${status}'!`)
            break
        }
      } catch (error) {
        setIsPaying(false)

        if (error.details?.errorCode !== undefined) {
          switch (error.details.errorCode) {
            case "card_declined":
              setPurchaseError(
                "We're sorry but we couldn't complete this transaction, as your card was declined. Please try again or contact support."
              )
              break
            case "expired_card":
              setPurchaseError(
                <>
                  We're sorry but we couldn't complete this transaction, as your
                  card has expired, so please
                  <Link to={routes.PAYMENT_DETAILS} className="mx-1 underline">
                    update your payment details.
                  </Link>
                </>
              )
              break
            case "card_invalid_for_store_country":
              setPurchaseError(
                <>
                  We're sorry but we couldn't complete this transaction, as your
                  card is not valid for this region. Please
                  <Link to={routes.STORE_CHOOSER} className="mx-1 underline">
                    switch store
                  </Link>
                  or
                  <Link to={routes.PAYMENT_DETAILS} className="mx-1 underline">
                    update your payment details.
                  </Link>
                </>
              )
              break
            default:
              setPurchaseError(
                "We're sorry but we couldn't complete this transaction.  Please try again or contact support."
              )
              break
          }
        } else {
          setPurchaseError(
            "We're sorry but we couldn't complete this transaction.  Please try again or contact support."
          )
        }
      }
    },
    [
      isPrimaryButtonDisabled,
      selectedItem.ref,
      selectedItem.id,
      selectedItem.saleCurrency,
      selectedItem.salePriceExTax,
      selectedItem.salePriceIncTax,
      selectedItem.supplier,
      selectedItem.title,
      selectedItem.type,
      storeCountry,
      discountCode,
      discountedPrice,
      onPaymentSuccess,
      userAccount,
    ]
  )

  //
  // Listen for bank auth to complete before attempting a purchase once more
  //
  // NOTE: The window message listener is registered in the StoreWrapper component.
  //
  useEffect(() => {
    if (bankAuthPaymentIntentId) {
      // Attempt to complete the purchase after bank auth has completed
      attemptPurchase(bankAuthPaymentIntentId)

      // Reset bank auth payment intent id
      setBankAuthPaymentIntentId(undefined)
    }
  }, [bankAuthPaymentIntentId, setBankAuthPaymentIntentId, attemptPurchase])

  //
  // Build a dialog body that embeds the bank's authentication
  // page in an <iframe> element.
  //
  const buildBankAuthBody = () => {
    return (
      <div className="rounded-2xl overflow-scroll neumorphic-1-inner p-1">
        <iframe
          title="Bank Authorisation"
          style={{
            // See: https://stripe.com/docs/payments/3d-secure#render-iframe
            height: isMobile ? "400px" : "500px",
            width: isMobile ? "250px" : "600px",
          }}
          className="w-full-- h-full-- overflow-scroll-- rounded-xl bg-grey-light"
          src={redirectURL}
        />
      </div>
    )
  }

  //
  // Build the purchase dialog body
  //
  const buildPurchaseBody = () => {
    return (
      <div>
        {/* Content rows */}
        <div className="grid auto-rows-min gap-5 tablet:gap-5">
          {/* Two columns for media */}
          <div
            style={{
              gridTemplateColumns: "auto 1fr",
            }}
            className="grid gap-5"
          >
            {/* Cover art container */}
            <div
              className="
                neumorphic-2-ridge 
                rounded-xl 
                p-1 
                overflow-hidden
                w-30 h-30"
            >
              <RemoteImage
                imagePath={selectedItem.artwork}
                aspectRatio="1:1"
                mobileImageSize={120}
                tabletImageSize={120}
                laptopImageSize={120}
                desktopImageSize={120}
                className="rounded-lg h-full w-full"
              />
            </div>

            {/* Metadata stack, centered vertically */}
            <div className="flex items-center">
              <div className="flex flex-col text-sm desktop:text-base mt-1">
                {/* Media type */}
                <div className="flex items-center mb-2">
                  <AudiobookIcon className="w-3.5 desktop:w-4 h-3.5 desktop:h-4 mr-0.5" />
                  <span className="text-blue font-medium text-xs desktop:text-sm">
                    Audiobook
                  </span>
                </div>
                {/* Title */}
                <div className="font-bold line-clamp-2">
                  {selectedItem.title}
                </div>
                {/* Author */}
                <div className="line-clamp-1">by {selectedItem.author}</div>
                {/* Price */}
                <div className="flex-row font-bold text-base mt-2">
                  <span
                    className={`${
                      discountedPrice !== undefined
                        ? "line-through"
                        : "text-red"
                    }`}
                  >
                    {salePrice}
                  </span>
                  {discountedPrice !== undefined && (
                    <span className="text-red ml-2">{discountedPrice}</span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <hr className="text-grey mx-1" />

          {/* Discount Code */}
          {shouldUserReauthenticate === false && (
            <div
              style={{
                gridTemplateColumns: isMobile ? undefined : "2fr 1fr",
              }}
              className={`grid gap-3 ${
                isMobile ? "grid-flow-row gap-3" : "grid-flow-col"
              }`}
            >
              <Input
                placeholder="Discount code"
                hasValidation={true}
                validationError={discountCodeError}
                onValueChanged={setDiscountCode}
                onEnterPressed={applyDiscount}
                className="text-sm w-full"
              />

              <Button
                text="Apply"
                shadowHeight={1}
                size={size(bpt, "md", "md", "lg", "lg")}
                disabled={discountCode === undefined || discountCode === ""}
                loading={isApplyingDiscount}
                onClick={applyDiscount}
                className="
                  h-9 tablet:h-10 desktop:h-11
                  w-full
                  ml-3
                  mt-0.5
                  justify-self-end 
                  text-grey-dark"
              />
            </div>
          )}

          {/* Password */}
          {shouldUserReauthenticate && (
            <>
              {/* <hr className="text-grey mx-1" /> */}
              <p className="text-xs tablet:text-sm desktop:text-base mx-1">
                We need your password to keep your credit card safe from little
                fingers!
              </p>
              <Input
                type="password"
                placeholder={shouldUserReauthenticate ? "Password" : ""}
                autocomplete="current-password"
                isFocussed={true}
                disabled={isPaying}
                onEnterPressed={handlePrimaryButtonClicked}
                onValueChanged={setPurchasePassword}
                hasValidation={true}
                validationError={passwordError}
                className="text-sm -mb-2"
              />
              <Checkbox
                isChecked={doNotReauthenticateBeforePayment}
                setChecked={setDoNotReauthenticateBeforePayment}
                className="
                  pt-px
                  pl-0.5"
              >
                <p
                  style={{
                    fontSize: size(
                      bpt,
                      "2.7vw",
                      undefined,
                      undefined,
                      undefined
                    ),
                  }}
                  className="text-xs desktop:text-sm select-none"
                >
                  Don't ask for password to make purchases
                </p>
              </Checkbox>
            </>
          )}

          {/* Purchase error */}
          {purchaseError && (
            <>
              <hr className="text-grey mx-1" />
              <p className="text-red text-sm mx-1">{purchaseError}</p>
            </>
          )}

          <hr className="text-grey mx-1" />
        </div>
      </div>
    )
  }

  return (
    <ModalDialog
      heading="Please confirm purchase"
      primaryButtonText={primaryButtonText}
      secondaryButtonText="Cancel"
      onPrimaryButtonClicked={handlePrimaryButtonClicked}
      onSecondaryButtonClicked={handleCancelClicked}
      isPrimaryButtonDisabled={isPrimaryButtonDisabled}
      isSecondaryButtonDisabled={isPaying}
      isWaiting={isReauthenticating || isPaying}
      areButtonsVisible={!showBankAuth}
      width={dialogWidth}
      message={
        redirectURL && showBankAuth ? buildBankAuthBody() : buildPurchaseBody()
      }
    />
  )
}

PaymentDialog.propTypes = {
  selectedItem: PropTypes.object.isRequired,
  onCancelClicked: PropTypes.func.isRequired,
  onPaymentSuccess: PropTypes.func.isRequired,
}

export default PaymentDialog
PaymentDialog.whyDidYouRender = true
