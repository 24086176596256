import Backdrop from "components/backdrop"
import { useMediaQueries } from "hooks/useMediaQueries"
import PropTypes, { oneOf } from "prop-types"
import React from "react"

// Location of sidebar
export const SidebarLocation = {
  LEFT: "left",
  RIGHT: "right",
}

//
// A sidebar for menu-based navigation
//
const MobileSidebar = ({
  location,
  isSidebarDisplayed,
  setSidebarDisplayed,
  children,
  animationDuration,
}) => {
  // Media queries for React
  const { isMobile } = useMediaQueries()

  return (
    <>
      <Backdrop
        duration={animationDuration}
        className="laptop:hidden"
        visible={isSidebarDisplayed}
        onDismiss={() => {
          setSidebarDisplayed(false)
        }}
      />
      <div
        style={{
          width: isMobile ? "75vw" : "35vw",
          transform: `translate(${
            isSidebarDisplayed
              ? "0%"
              : location === SidebarLocation.LEFT
              ? "-100%"
              : "100%"
          })`,
        }}
        className={`
          laptop:hidden
          fixed
          inset-y-0
          ${location === SidebarLocation.LEFT ? "left-0" : "right-0"}
          ${location === SidebarLocation.LEFT ? "origin-left" : "origin-right"} 
          z-40
          bg-grey-light
          ${isSidebarDisplayed ? "shadow-2xl" : ""}
          flex flex-col
          overflow-hidden
          transition-transform duration-300 ease-in-out
        `}
      >
        {children}
      </div>
    </>
  )
}

MobileSidebar.propTypes = {
  location: oneOf([SidebarLocation.LEFT, SidebarLocation.RIGHT]).isRequired,
  isSidebarDisplayed: PropTypes.bool.isRequired,
  setSidebarDisplayed: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
  animationDuration: PropTypes.number.isRequired,
}

export default MobileSidebar
MobileSidebar.whyDidYouRender = true
