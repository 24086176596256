import { useSetupContext } from "context/setupContext"
import { useMemo } from "react"

//
// Custom hook to determine whether we are in a setup process flow
//
export const useIsSetupProcess = () => {
  // Get the setup context
  const { hasProfile, hasDevice } = useSetupContext()

  // We are in the setup context if a user doesn't have BOTH
  // a profile AND a device.
  return useMemo(() => {
    return hasProfile === false || hasDevice === false
  }, [hasProfile, hasDevice])
}
