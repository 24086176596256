import { useQuery } from "react-query"
import { getFirestore } from "services/firebase"

export const profileContentIDsQueryName = "profileContentIDsQuery"

//
// Query for the content IDs in a profile
//
export const profileContentIDsQuery = async ({ queryKey }) => {
  const [, { userId, profileId }] = queryKey
  try {
    const firestore = await getFirestore()
    const profileContentRef = firestore
      .collection("users")
      .doc(userId)
      .collection("profiles")
      .doc(profileId)
      .collection("content")
    const profileContentSnapshot = await profileContentRef.get()
    return profileContentSnapshot.docs.map((doc) => {
      return doc.data().ref.path
    })
  } catch (error) {
    throw new Error(error)
  }
}

//
// A hook to use profile content IDs by
// querying for them.
//
export const useProfileContentIDsQuery = (userId, profileId) => {
  return useQuery(
    [profileContentIDsQueryName, { userId, profileId }],
    profileContentIDsQuery,
    {
      staleTime: Infinity,
      enabled: userId !== undefined && profileId !== undefined,
    }
  )
}
