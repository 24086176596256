import { oneOf } from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import "services/fontawesome"

//
// Input component
//
// Add 'onValueChanged' delegate for updates to internal state.
//
const Input = (props) => {
  const {
    value,
    type,
    autocomplete,
    maxLength,
    placeholder,
    disabled = false,
    isFocussed = false,
    hasValidation = false,
    validationError,
    onValueChanged,
    onEnterPressed,
    children,
    childAlignment = "right",
    className,
    style,
    innerClassName,
    innerStyle,
  } = props

  // Input value state, using "value" prop for initial value if present
  const [inputValue, setInputValue] = useState(value ? value : "")

  // State of focus
  const [focussed, setFocussed] = useState(false)

  // Handle updates to the input value
  const handleChange = (event) => {
    setInputValue(event.target.value)
    if (onValueChanged) {
      onValueChanged(event.target.value)
    }
  }

  // A reference to the inner input element
  const inputRef = useRef(null)

  // Focus input if specified
  useEffect(() => {
    if (isFocussed) {
      inputRef.current.focus()
      setFocussed(true) // Only focus once!
    }
  }, [focussed, isFocussed])

  //
  // Update value if it is changed externally,
  // avoiding "undefined" which causes React errors
  //
  useEffect(() => {
    setInputValue(value === undefined ? "" : value)
  }, [value])

  // Use background clip to ensure shadows on Safari iOS
  // See: https://newbedev.com/iphone-ios-will-not-display-box-shadow-properly
  return (
    <div className={`${className ? className : ""}`} style={style ? style : {}}>
      <div className="relative">
        <form>
          <input
            ref={inputRef}
            type={type}
            autoComplete={autocomplete}
            placeholder={placeholder}
            value={inputValue}
            max={2}
            maxLength={maxLength ? maxLength : undefined}
            onChange={handleChange}
            onKeyPressCapture={(e) => {
              // Handle Enter keypress
              if (e.key === "Enter") {
                e.preventDefault() // Preven form submission!
                if (onEnterPressed) {
                  onEnterPressed()
                }
              }
            }}
            style={innerStyle ? innerStyle : {}}
            disabled={disabled}
            className={`
              ${type === "password" ? "pr-10" : ""}
              ${childAlignment === "left" ? "pl-10" : "pr-10"}
              block w-full px-4
              rounded-full focus:outline-none shadow-input text-grey-darker border border-grey-light
              ${validationError && "border-red"}
              ${innerClassName ? innerClassName : ""}
              bg-clip-padding 
              h-10 tablet:h-11 desktop:h-12
              text-sm tablet:text-base desktop:text-lg
            `}
          />
        </form>
        {/* Implement "make password visible" feature! */}
        {/* {type === "password" && (
          <div
            className="
              absolute inset-y-0 right-0 z-10 pr-3
              flex items-center
              text-grey text-sm"
          >
            <FontAwesomeIcon icon="eye-slash" />
          </div>
        )} */}

        {/* Children */}
        {children && (
          <div
            className={`
              absolute inset-y-0
              flex items-center
              ${childAlignment === "left" ? "left-0 pl-4" : "right-0 pr-3"}
            `}
          >
            {children}
          </div>
        )}
      </div>
      {hasValidation && validationError && (
        <p
          className="
            text-xs
            text-red
            mt-0.75
            pl-4
            h-4
            font-medium
            text-left
          "
        >
          {validationError}
        </p>
      )}
    </div>
  )
}

Input.propTypes = {
  type: oneOf(["number", "email", "password"]),
  validationLevel: oneOf(["error", "warning", "success"]),
  childAlignment: oneOf(["left", "right"]),
}

export default Input
Input.whyDidYouRender = true
