//
// Helper to determine a size based on the current breakpoint
//
export const size = (
  breakpoint,
  mobileSize,
  tabletSize,
  laptopSize,
  desktopSize
) => {
  // Ignore if this is the SSR build process
  if (typeof window === "undefined") {
    return
  }
  if (!breakpoint) {
    return
  }
  switch (breakpoint) {
    case "mobile":
      return mobileSize
    case "tablet":
      return tabletSize
    case "laptop":
      return laptopSize
    case "desktop":
      return desktopSize
    default:
      console.warn("No sizes provided to size helper?")
      break
  }
}
