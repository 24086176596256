import { Link } from "gatsby"
import { useMediaQueries } from "hooks/useMediaQueries"
import SingleChevronRightIcon from "images/single-chevron-right-icon.svg"
import PropTypes from "prop-types"
import React, { memo, useMemo } from "react"

//
// A link on the mobile navigation sidebar
//
const MobileNavbarLink = memo(
  ({ title, subtitle, to, onClick, setSidebarDisplayed, icon, className }) => {
    // Media queries for React
    const { isMobile } = useMediaQueries()

    //
    // Calculate the font size, depending on whether this
    // is a title or subtitle and taking the breakpoint into account.
    //
    const fontSize = useMemo(() => {
      if (title) {
        if (isMobile) {
          return "6vw"
        } else {
          return "2.6vw"
        }
      } else if (subtitle) {
        if (isMobile) {
          return "4.5vw"
        } else {
          return "2.2vw"
        }
      }
    }, [isMobile, title, subtitle])

    //
    // Determine the sizing of the grid columns, depending
    // on whether or not there is an icon and taking the
    // breakpoint into account.
    //
    const gridTemplateColumns = useMemo(() => {
      if (icon) {
        if (isMobile) {
          return "5vw 1fr auto"
        } else {
          return "2.5vw 1fr auto"
        }
      } else {
        return "1fr auto"
      }
    }, [icon, isMobile])

    return (
      <>
        {/* Act like a link */}
        {to !== undefined && (
          <Link
            to={to}
            onClick={() => {
              if (setSidebarDisplayed) {
                setSidebarDisplayed(false)
              }
            }}
            style={{
              fontSize: fontSize,
              gridTemplateColumns: gridTemplateColumns,
            }}
            className={`
              grid grid-flow-col
              items-center
              h-10
              ${icon ? "ml-0.5" : ""}
              ${className ? className : ""}
            `}
          >
            {icon}
            {title && <p className="font-bold">{title}</p>}
            {subtitle && <p>{subtitle}</p>}

            <span className="text-red flex justify-end">
              <SingleChevronRightIcon />
            </span>
          </Link>
        )}

        {/* Act like a button, not a link */}
        {onClick && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div
            role="button"
            tabIndex="-1"
            onClick={onClick}
            style={{
              fontSize: fontSize,
              gridTemplateColumns: gridTemplateColumns,
            }}
            className={`
              grid grid-flow-col
              items-center
              h-10
              ${icon ? "ml-0.5" : ""}
              ${className ? className : ""}
            `}
          >
            {icon}
            {title && <p className="font-bold">{title}</p>}
            {subtitle && <p>{subtitle}</p>}
            <SingleChevronRightIcon />
          </div>
        )}
      </>
    )
  }
)

MobileNavbarLink.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  setSidebarDisplayed: PropTypes.func,
  icon: PropTypes.object,
}

export default MobileNavbarLink
MobileNavbarLink.whyDidYouRender = true
