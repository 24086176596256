import { size } from "helpers/responsiveSize"

//
// Heading 1 size
//
export const h1Responsive = (bpt) => {
  return size(bpt, "8vw", "4.5vw", "4vw", "4vw")
}

//
// Heading 2 size
//
export const h2Responsive = (bpt) => {
  return size(bpt, "6vw", "3vw", "3vw", "3vw")
}
