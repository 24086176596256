import React, { useEffect, useRef } from "react"
import resolveConfig from "tailwindcss/resolveConfig"
import tailwindConfig from "../../../../tailwind.config.js"

const MenuCallout = () => {
  // Load Tailwind config
  const { theme } = resolveConfig(tailwindConfig)

  // Theme colours
  const greyLight = theme.colors.grey.light
  const greyDark = theme.colors.grey.dark

  // A reference to the container div for maintaining aspect ratio
  const canvasRef = useRef(null)

  useEffect(() => {
    if (canvasRef.current) {
      // Find the canvas element
      const canvas = canvasRef.current

      // Get a 2d drawing context
      const ctx = canvas.getContext("2d")

      // Clear the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      // Line style
      ctx.strokeStyle = greyDark
      ctx.lineWidth = 7

      // Fill style
      ctx.fillStyle = greyLight

      // Shortcuts
      const fullX = canvas.width
      const fullY = canvas.height
      const halfX = canvas.width / 2
      const offset = 10
      const topY = 50
      const p1 = [halfX - offset, topY]
      const p2 = [halfX, topY - offset]
      const p3 = [halfX + offset, topY]

      // Begin!
      ctx.beginPath()

      // Bottom left
      ctx.moveTo(0, fullY)

      ctx.lineTo(p1[0], p1[1])
      ctx.arcTo(p2[0], p2[1], p3[0], p3[1], offset)
      ctx.lineTo(fullX, fullY)

      ctx.stroke()
      ctx.fill()
    }
  }, [greyLight, greyDark])

  return (
    <canvas
      ref={canvasRef}
      width="100"
      height="100"
      style={{
        height: "30px",
        width: "30px",
        marginBottom: "-1px",
        marginRight: "2rem",
        zIndex: 1,
      }}
    />
  )
}

export default MenuCallout
