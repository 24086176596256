import { oneOf } from "prop-types"
import React, { useState } from "react"
import "services/fontawesome"
import { SpinnerCircular } from "spinners-react"
import resolveConfig from "tailwindcss/resolveConfig"
import tailwindConfig from "../../../tailwind.config.js"

// Load Tailwind config
const { theme } = resolveConfig(tailwindConfig)

//
// A styled button
//
// Whitelist classes for PurgeCSS
// (See: https://www.viget.com/articles/a-better-approach-for-using-purgecss-with-tailwind/)
//
//   neumorphic-1-outer neumorphic-2-outer neumorphic-3-outer neumorphic-4-outer neumorphic-5-outer neumorphic-6-outer neumorphic-7-outer neumorphic-8-outer
//   neumorphic-1-inner neumorphic-2-inner neumorphic-3-inner neumorphic-4-inner neumorphic-5-inner neumorphic-6-inner neumorphic-7-inner neumorphic-8-inner
//   neumorphic-1-color neumorphic-2-color neumorphic-3-color neumorphic-4-color neumorphic-5-color neumorphic-6-color neumorphic-7-color neumorphic-8-color
//
const Button = (props) => {
  // Props
  const {
    type,
    text,
    size = "lg",
    rounded = "rounded-full",
    icon,
    iconHover,
    shadowHeight = 5,
    keyline = false,
    disabled = false,
    loading = false,
    flat = false,
    colorBackground = false, // Special shadows for colour backgrounds
    depressed = false, // Button is premanently pressed (or just sad!)
    onClick, // Callback delegate invoked after mouse released, with animation delay
    onMouseDown, // Callback delegate invoked as soon as mouse goes down
    children,
    justifyChildren = "center",
    className, // Extra classes for container
    classNameInner, // Extra classes for inner container
    style, // Extra styles for container
    styleInner, // Extra styles for inner container
  } = props

  // Tailwind theme colours
  const red = theme.colors.red["DEFAULT"]
  const grey = theme.colors.grey["DEFAULT"]

  // Hover state for icon transitions
  const [hovering, setHovering] = useState(false)

  // State of button press
  const [pressed, setPressed] = useState(false)

  // Handle mouse over
  const handleMouseOver = () => {
    setHovering(true)
  }

  // Handle mouse out
  const handleMouseOut = () => {
    setHovering(false)
    setPressed(false)
  }

  // Handle mouse down
  const handleMouseDown = () => {
    if (disabled || loading) {
      return
    }
    setPressed(true)
    if (onMouseDown) {
      onMouseDown()
    }
  }

  // Handle mouse up
  const handleMouseUp = () => {
    if (disabled || loading) {
      return
    }
    setTimeout(() => {
      setPressed(false)
    }, 25)
  }

  const handleTouchStart = () => {
    if (disabled || loading) {
      return
    }
    setPressed(true)
    if (onMouseDown) {
      onMouseDown()
    }
  }

  const handleTouchEnd = () => {
    if (disabled || loading) {
      return
    }
    setTimeout(() => {
      setPressed(false)
    }, 25)
  }

  //
  // Handler is attached as "onClickCapture" so that
  // React attached click handler to the "capture"
  // phase, which allows the button handler
  // to prevent propagation using "event.stopPropagation()"
  //
  const handleClick = (event) => {
    event.stopPropagation()
    if (loading) {
      return
    }
    if (!disabled && onClick) {
      setTimeout(() => {
        setPressed(false)
        onClick(event)
      }, 75)
    }
  }

  // Colour of inner segment of spinner
  const spinnerPrimaryColour = () => {
    switch (type) {
      case "primary":
        return red
      case "secondary":
        return grey
      default:
        return red
    }
  }

  // Colour of outer ring of spinner
  const spinnerSecondaryColour = () => {
    switch (type) {
      case "primary":
        return "white"
      case "secondary":
        return "white"
      default:
        return grey
    }
  }

  return (
    <div
      role="button"
      tabIndex="-1"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onFocus={handleMouseOver}
      onBlur={handleMouseOut}
      onClickCapture={handleClick}
      onKeyDown={handleClick}
      style={{
        ...style,
        outline: "none", // Prevent outline flash
      }}
      className={`
        ${
          size === "xxxs"
            ? "h-4"
            : size === "xxs"
            ? "h-5"
            : size === "xs"
            ? "h-6"
            : size === "sm"
            ? "h-7"
            : size === "md"
            ? "h-8"
            : size === "lg"
            ? "h-10"
            : size === "xl"
            ? "h-12"
            : ""
        }
        ${rounded}
        select-none
        no-tap-overlay
        no-double-tap-zoom
        transform-gpu
        transition-all duration-100 ease-in-out
        ${
          pressed || depressed || disabled || loading || flat
            ? ""
            : colorBackground
            ? `neumorphic-${shadowHeight}-color`
            : `neumorphic-${shadowHeight}-outer`
        }
        ${pressed || depressed ? (flat ? "scale-90" : "scale-99") : ""}
        ${disabled || loading ? "opacity-50" : ""}
        ${
          disabled
            ? "cursor-not-allowed"
            : loading
            ? "cursor-wait"
            : "cursor-pointer"
        }
        ${className}
      `}
    >
      {/* Button children */}
      {!loading && children && (
        <div
          className={`
            ${rounded}
            h-full w-full
            absolute
            inset-0
            flex 
            items-center
            justify-${justifyChildren}
          `}
        >
          {children}
        </div>
      )}

      {/* Overlay for child component */}
      {!flat && children && (pressed || depressed) && (
        <div
          className={`
            ${rounded}
            h-full w-full
            absolute
            inset-0
            bg-gradient-to-br from-black to-white
            opacity-5            
          `}
        />
      )}

      {/* Icon */}
      {icon && (
        <div
          className={`
            ${rounded}
            absolute
            inset-0
            flex 
            items-center 
            justify-center            
          `}
        >
          {icon && !hovering && icon}
          {iconHover && hovering && iconHover}
          {!iconHover && hovering && icon}
        </div>
      )}

      {/* Keyline ring & content */}
      <div
        className={`
          ${rounded}
          absolute
          inset-0
          ${!children ? "transition-all duration-100 ease-in-out" : ""}
          ${
            type === "primary" && keyline
              ? "border-2 border-red notouch:hover:border-red-dark shadow-inner-red notouch:hover:shadow-inner-red-dark"
              : ""
          }
          ${
            type === "secondary" && keyline
              ? "border-2 border-grey notouch:hover:border-grey-dark shadow-inner-grey notouch:hover:shadow-inner-grey-dark"
              : ""
          }
          ${
            (type === "primary" || type === "secondary") && !keyline && !flat
              ? "text-white"
              : ""
          }  
          ${
            type === "primary" && (keyline || flat)
              ? "text-red notouch:hover:text-red-dark"
              : ""
          }
          ${
            type === "secondary" && (keyline || flat)
              ? "text-grey notouch:hover:text-grey-dark"
              : ""
          }      
          ${
            type === "primary" && !keyline && !flat
              ? "bg-red notouch:hover:bg-red-dark"
              : ""
          }
          ${
            type === "secondary" && !keyline && !flat
              ? "bg-grey notouch:hover:bg-grey-dark"
              : ""
          }
          ${
            type === undefined && (disabled || loading)
              ? "border-2 border-grey"
              : ""
          }    
          ${
            !flat && keyline && children
              ? "-m-0.5 notouch:hover:border-4 border-white"
              : ""
          }      
          ${
            !flat && keyline && children && (pressed || depressed)
              ? "-m-0.5 border-4 border-white"
              : ""
          }
        `}
      >
        {/* Inner shadow & text */}
        <div
          style={styleInner ? styleInner : {}}
          className={`
            ${rounded}
            absolute
            inset-0
            flex 
            items-center 
            justify-center 
            font-bold
            select-none
            uppercase
            ${
              size === "xxxs"
                ? "text-xxxs"
                : size === "xxs"
                ? "text-xxs"
                : size === "xs"
                ? "text-xs"
                : size === "sm"
                ? "text-sm"
                : size === "md"
                ? "text-base"
                : size === "lg"
                ? "text-lg"
                : size === "xl"
                ? "text-xl"
                : ""
            }
            whitespace-nowrap
            transition-shadow duration-100 ease-in-out
            ${
              (pressed || depressed) && !flat
                ? `neumorphic-${shadowHeight}-inner`
                : ""
            }
            ${classNameInner ? classNameInner : ""}
          `}
        >
          {/* Button text */}
          {!loading && text}

          {/* Loading spinner */}
          {loading && (
            <SpinnerCircular
              thickness={150}
              color={spinnerPrimaryColour()}
              secondaryColor={spinnerSecondaryColour()}
              className="w-full h-full p-1.5"
            />
          )}
        </div>
      </div>
    </div>
  )
}

Button.propTypes = {
  type: oneOf(["primary", "secondary"]),
  size: oneOf(["custom", "xxxs", "xxs", "xs", "sm", "md", "lg", "xl"]),
  shadowHeight: oneOf([1, 2, 3, 4, 5, 6, 7, 8]),
  justifyChildren: oneOf(["start", "center", "end"]),
}

export default Button
