import { library } from "@fortawesome/fontawesome-svg-core"

import { faCheck, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons"
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { faImage } from "@fortawesome/free-solid-svg-icons"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { faCreditCard } from "@fortawesome/free-solid-svg-icons"
import { faComment } from "@fortawesome/free-solid-svg-icons"
import { faStore } from "@fortawesome/free-solid-svg-icons"
import { faGripVertical } from "@fortawesome/free-solid-svg-icons"
import { faShareSquare } from "@fortawesome/free-solid-svg-icons"

import { faCheckCircle } from "@fortawesome/free-regular-svg-icons"

import { faCcAmex } from "@fortawesome/free-brands-svg-icons"
import { faCcDinersClub } from "@fortawesome/free-brands-svg-icons"
import { faCcDiscover } from "@fortawesome/free-brands-svg-icons"
import { faCcJcb } from "@fortawesome/free-brands-svg-icons"
import { faCcMastercard } from "@fortawesome/free-brands-svg-icons"
import { faCcVisa } from "@fortawesome/free-brands-svg-icons"

// Add icons to the library
library.add(faCheck)
library.add(faCheckCircle)
library.add(faEyeSlash)
library.add(faPencilAlt)
library.add(faAngleLeft)
library.add(faAngleRight)
library.add(faTimes)
library.add(faImage)
library.add(faSearch)
library.add(faChevronRight)
library.add(faCreditCard)
library.add(faComment)
library.add(faStore)
library.add(faGripVertical)
library.add(faShareSquare)

// Credit cards
library.add(faCcAmex)
library.add(faCcDinersClub)
library.add(faCcDiscover)
library.add(faCcJcb)
library.add(faCcMastercard)
library.add(faCcVisa)
