import { BANK_AUTH_COMPLETE_CODE } from "app/payment/bankAuthComplete"
import Backdrop from "components/backdrop"
import ContentCardDetail from "components/contentCardDetail/contentCardDetail"
import BreakpointContext from "context/breakpointContext"
import {
  GridDimensionsProvider,
  initialState,
  reducer,
} from "context/gridDimensionsContext"
import { useLayoutContext } from "context/layoutContext"
import { useProfileContext } from "context/profileContext"
import { Screen, useScreenContext } from "context/screenContext"
import {
  SELECTED_ITEM,
  useSelectedItemContext,
  useSelectedItemDispatchContext,
} from "context/selectedItemContext"
import { useUserContext } from "context/userContext"
import { ContentType } from "helpers/contentTypes"
import { size } from "helpers/responsiveSize"
import { useCallToAction } from "hooks/useCallToAction"
import { useLocationSubtitles } from "hooks/useLocationSubtitles"
import { useMediaQueries } from "hooks/useMediaQueries"
import { useMountOnLoad } from "hooks/useMountOnLoad"
import { useNavBar } from "hooks/useNavBar"
import { useScrollingPrevention } from "hooks/useScrollingPrevention"
import GridLayout from "layouts/gridLayout"
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react"
import { useQueryClient } from "react-query"
import { useAddProfileContentMutation } from "reactQuery/mutations/addProfileContentMutation"
import { purchasesQueryName } from "reactQuery/queries/purchasesQuery"
import { useUserAccountQuery } from "reactQuery/queries/userAccountQuery"
import PageTitle from "./pageTitle"
import PaymentDialog from "./payment/paymentDialog"
import StoreFilter from "./subnavFilter/storeFilter"
import StoreTabBar from "./tabBar/storeTabBar"

//
// A wrapper for all Store pages
//
const StoreWrapper = ({ pageContext, children, location }) => {
  // Mount state
  const isMounted = useMountOnLoad()

  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  // Get the React Query Client from the context
  const queryClient = useQueryClient()

  // The screen context
  const { screen } = useScreenContext()

  // The layout context
  const { hasTabBar } = useLayoutContext()

  // Determine if we are wrapping a collection
  const isCollection = location.pathname.includes("/store/collection")

  // Breakpoints
  const { isMobile, isTablet, isLaptop, isDesktop } = useMediaQueries()

  // Auth state
  const { userId } = useUserContext()

  // Active profile
  const { activeProfileId } = useProfileContext()

  // Error message for content addition
  const [additionErrorMessage, setAdditionErrorMessage] = useState(undefined)

  // Get the selected item from the context
  const { selectedItem } = useSelectedItemContext()

  // Selected item dispatcher
  const dispatchSelectedItem = useSelectedItemDispatchContext()

  // Grid dimensions state, using reducer
  const [gridSpyState, gridSpyDispatch] = useReducer(reducer, initialState)

  // Display the payment dialog
  const [showPaymentDialog, setShowPaymentDialog] = useState(false)

  // The Payment Intent Id produced by a bank authorisation, which needs to be passed back to the Payment Dialog
  const [bankAuthPaymentIntentId, setBankAuthPaymentIntentId] =
    useState(undefined)

  // Get CTA detail
  const {
    isLoaded: isCTALoaded,
    isAvailable,
    isPurchased,
  } = useCallToAction(selectedItem)

  // Content addition mutation
  const addContentMutation = useAddProfileContentMutation({
    userId,
    profileId: activeProfileId,
    onMutate: () => {
      // Clear any existing error message as mutation begins
      setAdditionErrorMessage(undefined)
    },
    onError: () => {
      // Set error message
      setAdditionErrorMessage(
        "We're sorry but we couldn't add this title. Please try again later or contact support."
      )
      // Dimiss payment dialog if it's open
      setShowPaymentDialog(false)
    },
    onSuccess: () => {
      // Dimiss payment dialog if it's open
      setShowPaymentDialog(false)
    },
  })

  //
  // Handle content card being dismissed
  //
  const handleContentCardDismissed = useCallback(() => {
    // Reset selected item
    dispatchSelectedItem({
      type: SELECTED_ITEM,
      payload: undefined,
    })
    setAdditionErrorMessage(undefined)
  }, [dispatchSelectedItem])

  //
  // Handle a CTA button click
  //
  const handleCTAButtonClicked = () => {
    switch (selectedItem.type) {
      case ContentType.PODCAST:
      case ContentType.STATION:
        // Add the content to this profile without paying
        addContentMutation.mutate({
          profileId: activeProfileId,
          contentRef: selectedItem.ref,
        })
        return
      case ContentType.AUDIOBOOK:
        if (isCTALoaded && isPurchased) {
          // Add the content to this profile without paying
          addContentMutation.mutate({
            profileId: activeProfileId,
            contentRef: selectedItem.ref,
          })
          return
        } else if (isCTALoaded && isAvailable) {
          // show the payment dialog
          setShowPaymentDialog(true)
          return
        }
        break
      default:
        console.error(`Cannot determine CTA action for '${selectedItem.ref}'!`)
        return
    }
  }

  //
  // Handle a successful payment for an audiobook
  //
  const handlePaymentSuccess = () => {
    // Invalidate the purchases query
    queryClient.invalidateQueries(purchasesQueryName)

    // Hide payment dialog
    setShowPaymentDialog(false)

    // Add the content to this profile
    addContentMutation.mutate({
      profileId: activeProfileId,
      contentRef: selectedItem.ref,
    })
  }

  //
  // Determine whether or not to display the store filter subnav
  //
  const displayStoreFilter = useMemo(() => {
    // Don't dsiplay the filter for collections
    if (isCollection) {
      return false
    }
    // Don't display the filters for the search screen
    if (screen === Screen.SEARCH) {
      return false
    }
    // Only display the filters on larger screens
    if (isLaptop || isDesktop) {
      return true
    } else {
      return false
    }
  }, [isCollection, screen, isLaptop, isDesktop])

  // Use the navbar on this page
  useNavBar({
    hasNavBar: true,
    layoutType: "fluid",
    navbarTitle: screen,
  })

  // Set subtitles automatically
  useLocationSubtitles()

  // Prevent background scrolling when card detail modal is open
  useScrollingPrevention(selectedItem !== undefined)

  // Use the selected item from the page context if available
  useEffect(() => {
    if (pageContext?.selectedItem) {
      dispatchSelectedItem({
        type: SELECTED_ITEM,
        payload: pageContext?.selectedItem,
      })
    }
  }, [dispatchSelectedItem, pageContext?.selectedItem])

  // Warm up user account query, to speed up Payment Dialog rendering
  useUserAccountQuery(userId)

  /**
   * Handle message events posted to the window object
   *
   * @param {*} message The message posted to the window
   */
  async function handleMessageBroadcast(message) {
    // Check for a bank auth completion message, sent by
    // the BankAuthComplete component
    if (message.data?.code === BANK_AUTH_COMPLETE_CODE) {
      // Extract the Payment Method ID
      const paymentIntentId = message.data?.paymentIntentId
      if (!paymentIntentId) {
        throw Error(
          "Missing the Payment Method ID in the bank auth completion message!"
        )
      }
      console.debug(
        `--> Received bank auth complete message for Payment Intent '${paymentIntentId}'.`
      )
      // Capture the bank auth payment intent Id
      setBankAuthPaymentIntentId(paymentIntentId)
    }
  }

  //
  // Register a window message listener just once, which will
  // respond to messages posted to the window object, which is
  // a basic broadcast mechanism for inter-frame communications.
  //
  useEffect(() => {
    window.addEventListener("message", handleMessageBroadcast, false)
    return function cleanup() {
      window.removeEventListener("message", handleMessageBroadcast)
    }
  }, [])

  /**
   * Handle a URL change by wiping out the state that
   * handles the selected item display, so that the
   * browser back/forward behaviour is as expected.
   */
  const handleURLChange = useCallback(() => {
    // Reset selected item
    dispatchSelectedItem({
      type: SELECTED_ITEM,
      payload: undefined,
    })
  }, [dispatchSelectedItem])

  //
  // Register a listener to detect when the browser URL changes
  //
  useEffect(() => {
    window.addEventListener("popstate", handleURLChange, false)
  }, [handleURLChange])

  return (
    <>
      {/* White backdrop for content card */}
      {/* SSR issue: was causing rehydration issues on mobile Safari. 
          The key attribute helps to distiguish between SSR and client render, however
          it is effectively "re-rendered" during rehydration, as
          it doesn't match due to having a different key. */}
      <Backdrop
        key={isMounted} // See: https://github.com/gatsbyjs/gatsby/discussions/17914#discussioncomment-139432
        color="bg-grey-light"
        visible={selectedItem !== undefined}
        opacity={0.92}
        duration={300}
        onDismiss={handleContentCardDismissed}
      >
        {selectedItem && (
          <ContentCardDetail
            contentItem={selectedItem}
            ctaButtonType="primary"
            ctaButtonIsLoading={addContentMutation.isLoading}
            ctaButtonOnClick={handleCTAButtonClicked}
            errorMessage={additionErrorMessage}
            onDismiss={handleContentCardDismissed}
          />
        )}
      </Backdrop>

      {/* Purchase dialog */}
      {showPaymentDialog && (
        <PaymentDialog
          selectedItem={selectedItem}
          bankAuthPaymentIntentId={bankAuthPaymentIntentId}
          setBankAuthPaymentIntentId={setBankAuthPaymentIntentId}
          onCancelClicked={() => {
            setShowPaymentDialog(false)
          }}
          onPaymentSuccess={handlePaymentSuccess}
        />
      )}
      {/* Store Tab bar */}
      {hasTabBar && (isMobile || isTablet) && <StoreTabBar />}
      <GridLayout
        type="fluid"
        container={false}
        spyDispatch={gridSpyDispatch}
        style={{
          opacity: isMounted ? 100 : 0,
          marginTop: size(bpt, "4vw", "2.5vw", "2.5vw", "2.5vw"),
        }}
        className="transition-opacity duration-300 ease-in-out"
      >
        {/* Display the page title & subtitle */}
        {!isCollection && screen && (
          <PageTitle
            title={screen}
            isResponsive={true}
            className="
              col-start-1 laptop:col-start-1
              col-span-full laptop:col-span-2"
          />
        )}

        {/* Display the page title & subtitle, with special consideration as this is a collection */}
        {isCollection && screen && (
          <PageTitle
            title={screen}
            isResponsive={true}
            className="
              col-start-1 col-span-full"
          />
        )}

        {/* Display the store filter on larger screens only, if this is not a collection or search */}
        {displayStoreFilter && (
          <StoreFilter className="laptop:col-start-3 laptop:col-span-8" />
        )}

        {/* Spacer */}
        <div className="col-span-full mb-4 tablet:mb-6 laptop:mb-12" />

        {/* Sub-pages */}
        <GridDimensionsProvider state={gridSpyState}>
          {children}
        </GridDimensionsProvider>

        {/* Spacer for bottom */}
        <div className="pb-15 laptop:pb-20" />
      </GridLayout>
    </>
  )
}

export default StoreWrapper
StoreWrapper.whyDidYouRender = true
