import produce from "immer"
import React, { createContext, useContext, useEffect, useReducer } from "react"

/**
 * A context provider that interacts with the Chatlio API.
 */
export const ChatlioContext = createContext()
export const ChatlioDispatchContext = createContext()

const initialState = {
  isReady: false,
  isOnline: false,
  isExpanded: false,
}

export const READY = "READY"
export const ONLINE = "ONLINE"
export const EXPANDED = "EXPANDED"

const reducer = (state, action) => {
  switch (action.type) {
    case READY:
      return produce(state, (draftState) => {
        draftState.isReady = action.payload.isReady
      })
    case ONLINE:
      return produce(state, (draftState) => {
        draftState.isOnline = action.payload.isOnline
      })
    case EXPANDED:
      return produce(state, (draftState) => {
        draftState.isExpanded = action.payload.isExpanded
      })
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

export const ChatlioProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  // Listen for Chatlio API events
  useEffect(() => {
    // Handle Chatlio Ready event
    function onChatlioReady() {
      dispatch({
        type: READY,
        payload: {
          isReady: true,
        },
      })
    }

    // Handle Chatlio Online event
    function onChatlioOnline() {
      dispatch({
        type: ONLINE,
        payload: {
          isOnline: true,
        },
      })
    }

    // Handle Chatlio Offline event
    function onChatlioOffline() {
      dispatch({
        type: ONLINE,
        payload: {
          isOnline: false,
        },
      })
    }

    // Handle Chatlio visibility change event
    function onChatlioVisibiltyChanged(event) {
      dispatch({
        type: EXPANDED,
        payload: {
          isExpanded: event.data.expanded,
        },
      })
    }

    // Listen for Chatlio Ready event
    document.addEventListener("chatlio.ready", onChatlioReady)

    // Listen for Chatlio Online event
    document.addEventListener("chatlio.online", onChatlioOnline)

    // Listen for Chatlio Offline event
    document.addEventListener("chatlio.offline", onChatlioOffline)

    // Listen for Chatlio visibility change event
    document.addEventListener(
      "chatlio.visibilityChange",
      onChatlioVisibiltyChanged
    )

    /**
     * Check to see if the widget is ready manually, in case
     * we missed the event.
     */
    const chatlioAPI = window._chatlio
    if (chatlioAPI) {
      onChatlioReady()
      if (chatlioAPI.isOnline()) {
        onChatlioOnline()
      } else {
        onChatlioOffline()
      }
    }

    return function cleanup() {
      // Clean up Chatlio event listeners
      document.removeEventListener("chatlio.ready", onChatlioReady)
      document.removeEventListener("chatlio.online", onChatlioOnline)
      document.removeEventListener("chatlio.offline", onChatlioOffline)
      document.removeEventListener(
        "chatlio.visibilityChange",
        onChatlioVisibiltyChanged
      )
    }
  }, [])

  return (
    <ChatlioContext.Provider value={state}>
      <ChatlioDispatchContext.Provider value={dispatch}>
        {children}
      </ChatlioDispatchContext.Provider>
    </ChatlioContext.Provider>
  )
}

export const useChatlioContext = () => {
  const context = useContext(ChatlioContext)
  if (context === undefined) {
    throw new Error("useChatlioContext must be used within a ChatlioProvider")
  }
  return context
}
