import { gql, request } from "graphql-request"
import { AudiobookAttributes } from "graphql/audiobookAttributes"
import { PodcastAttributes } from "graphql/podcastAttributes"
import { StationAttributes } from "graphql/stationAttributes"
import { useQuery } from "react-query"
import { getFirestore } from "services/firebase"

// Query name
export const profileContentQueryName = "profileContentQuery"

// GraphQL query
const query = gql`
  ${AudiobookAttributes()}
  ${PodcastAttributes()}
  ${StationAttributes()}

  query LibraryItems($paths: [ID]!) {
    selection(paths: $paths) {
      ... on Audiobook {
        ...AudiobookAttributes
      }
      ... on Podcast {
        ...PodcastAttributes
      }
      ... on Station {
        ...StationAttributes
      }
    }
  }
`

//
// Query for the content items in a given profile
//
export const profileContentQuery = async ({ queryKey }) => {
  const [, { userId, profileId }] = queryKey
  try {
    // Load profile content IDs from Firestore first
    const firestore = await getFirestore()
    const profileContentRef = firestore
      .collection("users")
      .doc(userId)
      .collection("profiles")
      .doc(profileId)
      .collection("content")
    const profileContentSnapshot = await profileContentRef.get()
    const profileContentIDs = profileContentSnapshot.docs.map((doc) => {
      return doc.data().ref.path
    })
    // Check if the profile has any content before requesting content items...
    if (profileContentIDs.length === 0) {
      return []
    }
    // Run GraphQL query to get content items
    const contentItems = await request(
      process.env.GATSBY_GRAPHQL_CONTENT_ENDPOINT,
      query,
      {
        paths: profileContentIDs,
      }
    )
    return contentItems?.selection
  } catch (error) {
    throw new Error(error)
  }
}

//
// A hook to use the profile content query
//
export const useProfileContentQuery = ({ userId, profileId, select }) => {
  return useQuery(
    [profileContentQueryName, { userId, profileId }],
    profileContentQuery,
    {
      staleTime: Infinity,
      enabled: userId !== undefined && profileId !== undefined,
      select: select ? select : (contentItems) => contentItems,
    }
  )
}
