import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useChatlioContext } from "context/chatlioContext"
import { useProfileContext } from "context/profileContext"
import { useUserContext } from "context/userContext"
import React from "react"
import Button from "../controls/button"

const GetHelpMenuButton = ({ onClick, className }) => {
  // Chatlio API status
  let { isReady, isOnline } = useChatlioContext()

  // User context
  const { userId, email } = useUserContext()

  // Profile context
  const { activeProfileName } = useProfileContext()

  return (
    <Button
      size="sm"
      shadowHeight={2}
      disabled={!isReady}
      onClick={() => {
        // Update current page for context
        window._chatlio?.page()

        // Set identification context
        window._chatlio?.identify(userId, {
          email: email,
          profile: activeProfileName,
        })

        // Show chat widget
        window._chatlio?.showOrHide()

        // Callback delegate
        onClick()
      }}
      className={className ? className : ""}
    >
      <span className="uppercase font-bold text-sm">Get help</span>
      <FontAwesomeIcon
        icon={["fa", "comment"]}
        className={`
          ${isOnline ? "text-green" : ""}
          ml-2
          -mt-0.5
        `}
      />
    </Button>
  )
}

export default GetHelpMenuButton
