import { useMediaQuery } from "react-responsive"

//
// Custom hook to surface media queries in React
//
export const useMediaQueries = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  })
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1023px)",
  })
  const isLaptop = useMediaQuery({
    query: "(min-width: 1024px) and (max-width: 1439px)",
  })
  const isDesktop = useMediaQuery({
    query: "(min-width: 1440px)",
  })
  const isTouchDevice = useMediaQuery({
    query: "(hover: none)",
  })

  return { isMobile, isTablet, isLaptop, isDesktop, isTouchDevice }
}
