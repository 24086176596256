import ChatBackdrop from "components/chat/chatBackdrop"
import Footer from "components/footer/footer"
import Navbar from "components/navbar/navbar"
import GridOverlay from "components/utils/gridOverlay"
import { BreakpointProvider } from "context/breakpointContext"
import { useLayoutContext } from "context/layoutContext"
import { useMediaQueries } from "hooks/useMediaQueries"
import { useProfileEnforcer } from "hooks/useProfileEnforcer"
import { useSetupEnforcer } from "hooks/useSetupEnforcer"
import React, { useEffect, useState } from "react"
import { useMemo } from "react"
import "styles/mainLayout.css"

//
// The main layout
//
const MainLayout = ({ children }) => {
  // Media queries
  const { isMobile, isTablet, isLaptop, isDesktop } = useMediaQueries()

  // Current breakpoint
  const [breakpoint, setBreakpoint] = useState(undefined)

  // Layout context
  const { hasNavBar, hasTabBar, footerVisible } = useLayoutContext()

  // Extract grid overlay status from environment config
  const showGridOverlay = process.env.GATSBY_SHOW_GRID_OVERLAY === "true"

  // Determine bottom padding, including safe insets for iOS
  const paddingBottom = useMemo(() => {
    if (hasTabBar) {
      if (isLaptop || isDesktop) {
        return 0
      } else {
        return "calc(env(safe-area-inset-bottom, 0) + 3.75rem)"
      }
    } else {
      return 0
    }
  }, [hasTabBar, isLaptop, isDesktop])

  // Determine current breakpoint for Provider
  useEffect(() => {
    if (isMobile) {
      setBreakpoint("mobile")
    } else if (isTablet) {
      setBreakpoint("tablet")
    } else if (isLaptop) {
      setBreakpoint("laptop")
    } else if (isDesktop) {
      setBreakpoint("desktop")
    }
  }, [isMobile, isTablet, isLaptop, isDesktop, hasNavBar])

  // Make sure the user has chosen an active profile
  useProfileEnforcer()

  // Make sure the setup process has completed!
  useSetupEnforcer()

  // useRerenderWarning("Main Layout")

  return (
    <>
      {/* Backdrop for chat widget */}
      <ChatBackdrop />

      {/* Media Query Breakpoint Provider */}
      <BreakpointProvider value={breakpoint}>
        <div
          id="main-layout"
          className="
            grid auto-rows-min
            touch:min-h-screen-touch 
            notouch:min-h-screen-no-touch" // Cope with mobile Safari (see: see: https://dev.to/maciejtrzcinski/100vh-problem-with-ios-safari-3ge9)
          style={{
            gridTemplateRows: "1fr auto",
          }}
        >
          {/* Container, to take up majority of the vertical space */}
          <div>
            {/* Navbar */}
            {hasNavBar && <Navbar />}

            {/* Main body */}
            <main
              id="body"
              className={`
                ${hasNavBar ? "pt-15 laptop:pt-20" : ""}
              `}
            >
              {children}
              {showGridOverlay && (
                <GridOverlay type="fluid" container={false} offsets={true} />
              )}
            </main>
          </div>

          {/* Footer, pushed to the bottom */}
          {footerVisible === true && (
            <Footer
              style={{
                paddingBottom: paddingBottom,
              }}
            />
          )}

          {/* Cookie Bar: fixed position! */}
          {/* TODO: reinstate! */}
          {/* <CookieBar /> */}
        </div>
      </BreakpointProvider>
    </>
  )
}

export default MainLayout
MainLayout.whyDidYouRender = true
