import PropTypes from "prop-types"
import React from "react"
import Button from "../controls/button"
import resolveConfig from "tailwindcss/resolveConfig"
import tailwindConfig from "../../../tailwind.config"

// Load Tailwind config
const { theme } = resolveConfig(tailwindConfig)

const TabBarButton = ({ text, color, className, icon, isActive, onClick }) => {
  // Colours from theme
  const greyDark = theme.colors.grey.dark

  return (
    <Button
      flat={true}
      onClick={onClick}
      className={className ? className : ""}
    >
      <div
        style={{
          color: isActive ? color : greyDark,
        }}
        className={`h-9 flex flex-col items-center justify-end`}
      >
        {icon}
        <span
          className={`text-xs mt-1 ${
            isActive ? "font-extrabold" : "font-medium"
          }`}
        >
          {text}
        </span>
      </div>
    </Button>
  )
}

TabBarButton.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default TabBarButton
TabBarButton.whyDidYouRender = true
