import { useMediaQueries } from "hooks/useMediaQueries"
import React, { useEffect, useState } from "react"

//
// A box that maintains its aspect ratio
// See: https://css-tricks.com/aspect-mobileRatio-boxes/
//
const AspectRatioBox = (props) => {
  const {
    mobileRatio,
    tabletRatio,
    laptopRatio,
    desktopRatio,
    className,
    classNameInner, // Classes for inset box
    backgroundColor, // Dynamic background color using style
    children,
    style,
    styleInner, // Styles for inset box
    onClick,
  } = props

  // Media queries for React
  const { isMobile, isTablet, isLaptop, isDesktop } = useMediaQueries()

  // Current top padding
  const [paddingTop, setPaddingTop] = useState("0%")

  //
  // Calculate the top padding to maintain the
  // correct aspect ratio dynamically.
  //
  useEffect(() => {
    // Check for transition states, as breakpoints may overlap
    let isTransition = false
    if (!isMobile && !isTablet && !isLaptop && !isDesktop) {
      isTransition = true
    }
    if (
      (isMobile ? 1 : 0) +
        (isTablet ? 1 : 0) +
        (isLaptop ? 1 : 0) +
        (isDesktop ? 1 : 0) >
      1
    ) {
      isTransition = true
    }
    if (isTransition) {
      // Don't update padding during transitions
      return
    }

    // Determine which ratio to use
    if (isDesktop) {
      if (desktopRatio) {
        setPaddingTop(desktopRatio)
      } else {
        laptopRatio
          ? setPaddingTop(laptopRatio)
          : tabletRatio
          ? setPaddingTop(tabletRatio)
          : mobileRatio
          ? setPaddingTop(mobileRatio)
          : setPaddingTop("100%")
      }
    } else if (isLaptop) {
      if (laptopRatio) {
        setPaddingTop(laptopRatio)
      } else {
        tabletRatio
          ? setPaddingTop(tabletRatio)
          : mobileRatio
          ? setPaddingTop(mobileRatio)
          : setPaddingTop("100%")
      }
    } else if (isTablet) {
      if (tabletRatio) {
        setPaddingTop(tabletRatio)
      } else {
        mobileRatio ? setPaddingTop(mobileRatio) : setPaddingTop("100%")
      }
    } else if (isMobile) {
      mobileRatio ? setPaddingTop(mobileRatio) : setPaddingTop("100%")
    } else {
      console.warn(
        `No active breakpoint! (Using the previous value of ${paddingTop})`
      )
      setPaddingTop(paddingTop)
    }
  }, [
    mobileRatio,
    tabletRatio,
    laptopRatio,
    desktopRatio,
    isMobile,
    isTablet,
    isLaptop,
    isDesktop,
    paddingTop,
  ])

  return (
    <>
      {paddingTop !== "0%" && (
        <div
          style={{
            paddingTop: paddingTop,
            backgroundColor: backgroundColor,
            ...style,
          }}
          className={`
            h-0
            overflow-hidden
            relative
            focus:outline-none
            no-tap-overlay
            ${className ? className : ""}
          `}
          tabIndex="0"
          role="button"
          onClick={onClick}
          onKeyDown={onClick}
        >
          {/* Inner container */}
          <div
            style={styleInner}
            className={`
              absolute
              inset-0
              ${classNameInner ? classNameInner : ""}
            `}
          >
            {children}
          </div>
        </div>
      )}
    </>
  )
}

export default AspectRatioBox
