import React, { memo } from "react"
import { Transition } from "react-transition-group"

//
// An opaque backdrop that covers the entire page
//
const Backdrop = memo((props) => {
  const {
    color = "bg-black",
    visible,
    onDismiss, // Callback for backdrop click
    opacity = 0.4,
    duration = 150, // Duration of fade animation
    className,
    children,
  } = props

  // Default style
  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    visibility: "hidden",
    opacity: 0,
  }

  // Transition styles
  const transitionStyles = {
    entering: {
      visibility: "visible",
      opacity: opacity,
    },
    entered: {
      visibility: "visible",
      opacity: opacity,
    },
    exiting: {
      visibility: "visible",
      opacity: 0,
    },
    exited: {
      visibility: "hidden",
      opacity: 0,
    },
  }

  //
  // Action to perform if the backdrop is clicked
  //
  const backdropClicked = () => {
    if (onDismiss) {
      onDismiss()
    }
  }

  return (
    <Transition in={visible} appear={visible} timeout={duration}>
      {(state) => (
        <>
          <div
            id="backdrop"
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            className={`
              fixed
              inset-0
              z-40
              flex
              ${color ? color : ""}
              ${className ? className : ""}
            `}
          />

          {/* 100% opacity container for children */}
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <div
            id="backdrop-container"
            role="dialog"
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            className="
                fixed
                inset-0
                z-40
                overflow-y-auto"
            onClick={backdropClicked}
            onKeyDown={backdropClicked}
          >
            {children}
          </div>
        </>
      )}
    </Transition>
  )
})

export default Backdrop
Backdrop.whyDidYouRender = true
