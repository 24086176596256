import { getFirebase } from "services/firebase"
import {
  SignUpError,
  SignInError,
  SignOutError,
  UpdatePasswordError,
  ResetPasswordError,
} from "services/errors"
import { createUserAccount } from "services/cloudFunctions"

//
// Load Firebase Auth
//
let authLoaded = false
export const getAuth = () => {
  return new Promise(async (resolve, reject) => {
    const firebaseInstance = await getFirebase()
    if (firebaseInstance && authLoaded) {
      console.debug("Firebase & Auth already loaded.")
      resolve(firebaseInstance.auth())
    } else if (firebaseInstance && !authLoaded) {
      console.debug("Firebase already loaded; loading auth...")
      import("firebase/auth")
        .then(() => {
          console.debug("Auth loaded.")
          resolve(firebaseInstance.default.auth())
        })
        .catch((error) => {
          reject(error)
        })
    } else if (!firebaseInstance && !authLoaded) {
      console.debug("Loading Firebase & Auth...")
      Promise.all([getFirebase(), import("firebase/auth")])
        .then(() => {
          resolve(firebaseInstance.default.auth())
        })
        .catch((error) => {
          reject(error)
        })
    }
  })
}

//
// Create a user account with an email address and password
//
export const signUpWithPassword = async (email, password) => {
  try {
    console.debug(`Creating account for '${email}'...`)

    // create the user account using a cloud function,
    // in order to set the UID to the email address
    await createUserAccount(email, password)

    // Sign in!
    await signInWithEmailAndPassword(email, password)
  } catch (error) {
    throw new SignUpError(error.details.code, error.details.message)
  }
}

//
// Sign in wih an email address and password
//
export const signInWithEmailAndPassword = async (email, password) => {
  return new Promise(async (resolve, reject) => {
    const auth = await getAuth()
    try {
      const response = await auth.signInWithEmailAndPassword(email, password)
      resolve(response)
    } catch (error) {
      reject(new SignInError(error.code, error.message))
    }
  })
}

//
// Update a user's password
//
export const updatePassword = async (password) => {
  return new Promise(async (resolve, reject) => {
    const auth = await getAuth()
    const user = auth.currentUser
    if (!user) {
      reject(
        new UpdatePasswordError(
          "auth/no-current-user",
          "There is no current user!"
        )
      )
    }
    try {
      await auth.currentUser.updatePassword(password)
      resolve()
    } catch (error) {
      reject(new UpdatePasswordError(error.code, error.message))
    }
  })
}

//
// Send a password reset email
//
export const sendPasswordResetEmail = async (email) => {
  return new Promise(async (resolve, reject) => {
    const auth = await getAuth()
    try {
      await auth.sendPasswordResetEmail(email)
      resolve()
    } catch (error) {
      reject(new ResetPasswordError(error.code, error.message))
    }
  })
}

//
// Check reset verification code
//
export const verifyPasswordResetCode = async (code) => {
  return new Promise(async (resolve, reject) => {
    const auth = await getAuth()
    try {
      await auth.verifyPasswordResetCode(code)
      resolve()
    } catch (error) {
      reject(new ResetPasswordError(error.code, error.message))
    }
  })
}

//
// Confirm password change
//
export const confirmPasswordReset = async (code, newPassword) => {
  return new Promise(async (resolve, reject) => {
    const auth = await getAuth()
    try {
      await auth.confirmPasswordReset(code, newPassword)
      resolve()
    } catch (error) {
      reject(new ResetPasswordError(error.code, error.message))
    }
  })
}

//
// Sign a user out
//
export const signOut = () => {
  return new Promise(async (resolve, reject) => {
    const auth = await getAuth()
    try {
      // Sign out of Firebase
      await auth.signOut()
      // Clear out local storage
      if (localStorage) {
        localStorage.clear()
      }
      resolve()
    } catch (error) {
      reject(new SignOutError(error.code, error.message))
    }
  })
}
