import { useQuery } from "react-query"
import { getFirestore } from "services/firebase"

export const profilesQueryName = "profilesQuery"

//
// Query the profiles in a user account
//
export const profilesQuery = async ({ queryKey }) => {
  const [, { userId }] = queryKey
  try {
    const firestore = await getFirestore()
    const profilesRef = firestore
      .collection("users")
      .doc(userId)
      .collection("profiles")
    const profiles = await profilesRef.orderBy("name").get()
    let profilesToReturn = []
    for (const profile of profiles.docs) {
      const data = profile.data()
      profilesToReturn.push({
        id: profile.id,
        name: data.name,
        avatar: data.avatar,
        birthMonth: data.birthMonth,
        birthYear: data.birthYear,
      })
    }
    return profilesToReturn
  } catch (error) {
    throw new Error(error)
  }
}

//
// A hook to use profiles by querying for them
//
export const useProfilesQuery = (userId) => {
  return useQuery([profilesQueryName, { userId }], profilesQuery, {
    staleTime: Infinity,
    enabled: userId !== undefined,
  })
}
