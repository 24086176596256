import { useEffect } from "react"

//
// Custom hook to prevent the body of the document from
// scrolling, which is desirable when there is a modal on
// the screen.
//
export const useScrollingPrevention = (scrollingPrevented = false) => {
  useEffect(() => {
    if (scrollingPrevented) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [scrollingPrevented])
}
