import BreakpointContext from "context/breakpointContext"
import { size } from "helpers/responsiveSize"
import React, { useContext } from "react"

//
// Metadata on Content Card Detail
//
const ContentCardMetadata = (props) => {
  // Props
  const { contentItem } = props

  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  //
  // Container for an item of metadata in the list
  //
  const metadataItem = (itemName, item) => {
    return (
      <>
        <tr>
          <th
            style={{
              fontSize: size(bpt, "3vw"),
            }}
            className="
              uppercase 
              tablet:text-sm
              text-right 
              align-text-top"
          >
            {itemName}
          </th>
          <td
            style={{
              fontSize: size(bpt, "3vw"),
            }}
            className="
              tablet:text-sm
              pl-1 tablet:pl-4
              align-text-top"
          >
            {item}
          </td>
        </tr>
      </>
    )
  }

  //
  // Unwrap the nested categories and create markup
  //
  const unwrapCategories = (categories) => {
    return categories.map((categoryMap, index) => {
      const category = categoryMap.category
      const subcategory = categoryMap.subcategory
      return (
        <p key={index} className="line-clamp-1">
          {category}
          &nbsp;
          {subcategory ? (
            <span className="font-light">({subcategory})</span>
          ) : (
            ""
          )}
        </p>
      )
    })
  }

  return (
    <>
      {(contentItem.audience ||
        contentItem.duration ||
        contentItem.publicationDate ||
        contentItem.publisher ||
        contentItem.categories) && (
        <div
          data-nosnippet // See: https://developers.google.com/search/docs/advanced/robots/robots_meta_tag#data-nosnippet-attr
          className="
            mt-2 tablet:mt-3 laptop:mt-4 desktop:mt-5
            rounded-lg tablet:rounded-2xl laptop:rounded-2xl
            border border-grey-light
            px-1.5 tablet:px-3
            py-1.5 tablet:py-4
            neumorphic-2-ridge tablet:neumorphic-4-ridge laptop:neumorphic-5-ridge"
        >
          <table
            style={{
              borderCollapse: "separate",
              borderSpacing: 0,
            }}
            className="w-full"
          >
            <tbody>
              {/* ISBN */}
              {contentItem.isbn && metadataItem("ISBN:", contentItem.isbn)}

              {/* Audience */}
              {contentItem.audience &&
                metadataItem("Audience:", contentItem.audience)}

              {/* Duration */}
              {contentItem.duration &&
                metadataItem("Duration:", contentItem.duration)}

              {/* Publication Date */}
              {contentItem.publicationDate &&
                metadataItem("Published:", contentItem.publicationDate)}

              {/* Publisher */}
              {contentItem.publisher &&
                metadataItem("Publisher:", contentItem.publisher)}

              {/* Categories */}
              {contentItem.categories &&
                metadataItem(
                  "Categories:",
                  unwrapCategories(contentItem.categories)
                )}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default ContentCardMetadata
