import { useMutation, useQueryClient } from "react-query"
import { userAccountQueryName } from "reactQuery/queries/userAccountQuery"
import { updateReauthenticateBeforePayment } from "services/cloudFunctions"

//
// A hook to mutate the user account field "reauthenticateBeforePayment".
// This is a "best-effort" mutation, so won't return errors, as the worst-case is that
// the user is prompted to use a password for the next purchase again.
//
export const useReauthenticateBeforePaymentMutation = () => {
  // Get the React Query Client from the context
  const queryClient = useQueryClient()

  return useMutation(
    ({ reauthenticateBeforePayment }) =>
      new Promise((resolve, reject) => {
        // Invoke cloud function
        updateReauthenticateBeforePayment(reauthenticateBeforePayment)
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      }),
    {
      onSuccess: () => {
        // Invalidate query, so reauthentication on payment status is refetched in the background
        queryClient.invalidateQueries([userAccountQueryName])
      },
    }
  )
}
