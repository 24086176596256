//
// Error for Firebase sign-up exceptions
//
export class SignUpError extends Error {
  constructor(code, message) {
    super(message)
    this.code = code
  }
}

//
// Error for Firebase sign-in exceptions
//
export class SignInError extends Error {
  constructor(code, message) {
    super(message)
    this.code = code
  }
}

//
// Error for Firebase sign-out exceptions
//
export class SignOutError extends Error {
  constructor(code, message) {
    super(message)
    this.code = code
  }
}

//
// Error for updating a user's Firebase auth password
//
export class UpdatePasswordError extends Error {
  constructor(code, message) {
    super(message)
    this.code = code
  }
}

//
// Error for updating a user's Firebase auth password
//
export class ResetPasswordError extends Error {
  constructor(code, message) {
    super(message)
    this.code = code
  }
}
