//
// Get a currency symbol from a currency code
//
export const getCurrencySymbol = (currency) => {
  switch (currency) {
    case "GBP":
      return "£"
    case "USD":
      return "$"
    default:
      return undefined
  }
}
